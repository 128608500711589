.Data-Room {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

/* Submenu Styles */
.review-submenu {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.review-submenu-list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

.review-submenu-list li {
  margin: 0;
  padding: 10px 15px;
  border-right: 1px solid #e0e0e0;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.review-submenu-list li:last-child {
  border-right: none;
}

.review-submenu-list li.active {
  border-bottom: 3px solid #c49733;
  font-weight: bold;
  background-color: #f7f7f7;
}

.review-submenu-list li:hover {
  background-color: #f0f0f0;
}

/* Add Files Button */
.add-files-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-files-button:hover {
  background-color: #357abd;
}

/* Review Content Card */
.review-content-card {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  padding: 0px 0px;
  border-bottom: 1px solid #eee;
}

th {
  text-align: left;
  font-weight: 300;
  color: #555;
}

tr:hover {
  background-color: #f9f9f9;
}

/* Icons in Actions */
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
  transition:
    color 0.3s ease,
    transform 0.2s ease;
}

.icon-button:hover {
  color: #333;
  transform: scale(1.1);
}

/* Deal Info Section */
.deal-info {
  padding: 20px;
  margin-top: 20px;
}

/* Chip Tags */
.exploreTag {
  margin-right: 8px;
  margin-bottom: 8px;
}

.allTags {
  flex-wrap: wrap;
  gap: 10px;
}

.data-room-icon-button {
  margin-left: 2px;
  color: black;
  background: none;
  border: none;
}

.data-room-icon-button:hover {
  color: #c49733;
  background-color: whitesmoke;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .Data-Room {
    padding: 15px;
  }

  .review-content-card {
    padding: 15px;
  }

  .add-files-button {
    padding: 8px 16px;
  }
}

.badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  display: inline-block;
  font-weight: 600;
}

.badge-extract {
  background-color: #ad8b00;
  color: #ad8b00;
}

.badge-anonymize {
  background-color: #096dd9;
  color: #096dd9;
}

.badge-file {
  background-color: #a8071a;
  color: #a8071a;
}

.section-title {
  margin-top: 20px;
  font-size: 1.1rem;
  font-weight: 600;
}

.company-doc-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #e8e8e8;
}
