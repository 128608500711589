.communityBadge {
  position: relative;
    display: block;
    text-align: center;
    line-height: 58px;
    font-size: 72px;
    color: cornflowerblue;
  }
  .communityImage  {
    position: absolute;
    display:grid !important;
    top: 11px;
    left: 11px;
    width: 100%;
    height: 100%;
    font-size: 32px;
    border: 2px solid grey;
    border-radius: unset !important;


}
.ubaidFollowComapny{
    display: flex;
    font-weight: 500;
    width: 100%;
    padding-right: 12px;
    justify-content: space-between;
    align-items: center;
}
.UbaidViewAsPulicPr{
    font-weight: bold;
    color: #fff;
    text-align: center;
}
.ubaidFollowComapny div{
    display: flex;
}
.ubaidFollowComapny span{
    border-right: 1.5px solid rgba(0, 0, 0, 0.3);
    padding: 0 16px;
    color: #9e9e9e;
    text-transform: uppercase;
    width: auto;
}
.ubaidFollowComapny a{
    display: block;
    text-align: center;
}
.UbaidFollowBtn i{
    width: auto !important;
    margin: 0 !important;
    padding: auto 0 !important;
}
.UbaidFollowBtn{
    border: none;
    background: #EEEEEE;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    box-shadow: 1.5px 2px 1px rgba(0, 0, 0, 0.12);
    outline: none;
    font-weight: 500;
    outline: none !important;
    padding-right: 24px;
}
.UbaidFollowCompanyCon{
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}
.UbaidFollowCompanyCon .ExpUbaidDupSocalIconsCon{
    margin: auto !important;
    width: 100% !important;
    justify-content: flex-start;
}
.UbaidFollowCompanyCon .ExpUbaidDupSocalIconsCon i {
    margin: 4px;
}
.UbaidUnfollow, .ubaidFollow{
    font-size: 15px;
    color: #3f51b5 !important;
    margin: 0 !important;
    border: 1.5px solid !important;
    padding: 8px !important;
    outline: none;
    transition: none !important;
}
.ubaidFollow:hover{
    background: rgba(63, 81, 181, 0.1);
}
.UbaidUnfollow{
    color: #fff !important;
    border-color: #3f51b5 !important;
    background: #3f51b5 !important;
}
.UbaidUnfollow:hover{
    background: rgb(202, 32, 85) !important;
    border-color: rgb(202, 32, 85) !important;
}
@media only screen and (max-width: 800px){
    .UbaidFollowCompanyCon{
        flex-wrap: wrap;
        align-items: center;
        justify-content: center !important;
    }
    .UbaidFollowCompanyCon .ExpUbaidDupSocalIconsCon{
        justify-content: center;
        margin-bottom: 12px !important;
    }
    .ubaidFollowComapny{
        width: 248px;
        margin: 10px auto !important;
    }
}
