.__company__profile{
    position: relative;
    display: flex;
    
}
.__profile_completedness{
    min-width: 80%;
    background-color: #eee;

    .__profile_page_container{
        display: flex;
        margin: 0 2vh 0vh 9vh;

        .profile_content{
            width: 70%;
        }

        .pc-section{
            width: 30%;
        }
    }
}
.__profile_section{
    width:102.5vh !important;
}

.UbaidLeft70{

    width:165vh;
    margin: auto;
}
.ubaidCircle{
    font-Size: 16px !important;
    padding: 10px !important;
}
.company_conver_con{
    padding: 0 !important;
}
.UbaidCompanyProfilePic{
    display: flex;
    flex-direction: column;
}
.UbaidCompanyProfilePic button{
    margin-top: auto;
    width: 219px;
}
.animate-reveal {
    opacity: 0;
    animation: reveal 1s cubic-bezier(0, 1, 0.5, 1) 1 normal forwards;
    display: inline-block;
    animation-delay: 200ms;
}
.bigBTN{
    float: right;
}
@keyframes reveal {
    0% {
      opacity: 0;
              transform: translateY(100%);
    }
    100% {
      opacity: 1;
              transform: translateY(0);
    }
}
.inputPadding3{
    padding: 5px 0 3px 0 !important;
}
.marginLR3{
    margin-left: 3px !important;
    margin-right: 3px !important;
}
.UbaidFlex{
    display: flex;
}
.ubaidInput{
    width: 476px;
    padding: 3px 6px 2px 6px;
}
.ubaidInput:focus{
    box-shadow: none !important;
    border: 1px solid #CC2C21;
}
.ubaidInputIcon{
    width: 30px !important;
    border-radius: 30px;
    padding: 0;
    margin-right: 10px;
    height: 30px !important;
}
.ubaidTextArea{
    height: 100px;
}
.ubaidopp-card-content, .ubaidSubRatings{
    padding: 2px !important;
}
.ubaidSubRatings{
    max-width: 700px;
    margin: 12px auto 0 auto !important;
}
.ReviewCardTitleUbaid{
    width: 20% !important;
}
.ReviewCardRatingUbaid{
    width: 24% !important;
}
.ReviewCardStarUBaid{   
    width: 56% !important;
}
.ReviewCardRatingUbaid button{
    margin-left: 10px !important;
}
.ubaidCompanyBasicDetails{
    align-items: center;
}
.UbaidComSubTitle{
    margin-left: 18px;
}

.ubaidCompanyBasicDetails i, .__company-share i{
    width: auto !important;
    margin: 0 10px 0 0 !important;
    padding: 0 !important;
}
.__company-share{
    display: none;
}
.ubaidCompanyBasicDetails button, .__company-share{
    margin: 0 0 0 auto !important;
    padding: 0 !important;
    cursor: pointer;
    outline: none !important;
    border: none;
    width: 44px !important;
    height: 44px;
    text-align: center !important;
    border-radius: 44px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
    background: #2bbbad;
    font-size: 16px;
}
.ubaidCompanyBasicDetails button .fas, .__company-share .fas{
    display: inline;
    margin: 0 !important;
}
.ubaidPencilIcon{
    position: absolute;
    top: 0;
    right: 0;
    width: 36px !important;
    height: 36px;
    cursor: pointer;
}

.UBaidCircleButtonCom{
    background: #2bbbad;
    height: 44px;
    width: 44px !important;
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    margin: 6px;
}
.UBaidCircleButtonCom i{
    margin: 0 !important;
}

.ubaidCompanyBasicDetails span{
    font-size: 25px;
}
.ubaidMainButton{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}
.ubaidMainButton>a{
    padding: 8px 14px;
}
@media only screen and (max-width: 1090px){
    .ubaidInput{
        width: 95%;
        margin-left: auto;
    }

    .__profile_completedness{
        min-width: 100%;
        background-color: #eee;
    
        .__profile_page_container{
            display: block;
            margin: 0 2vh 0vh 9vh;
    
            .profile_content{
                width: 100%;
                padding: 0;
            }
    
            .pc-section{
                text-align: center;
                width: 97%;
                margin: auto;
            }
        }
    }

}
@media only screen and (max-width: 845px){
    .ubaidInput{
        width: 92%;
    }
    .UbaidCompanyProfilePic button{
        display: none;
    }    
    .ubaidSubRatings button{
        padding: 10px 8px !important;
    }

    
}
@media only screen and (max-width: 600px){
    .UbaidLeft70{
        padding-left: 0;
    }
    .ubaidMainButton>a{
        flex: 1;
    }
    .ubaidButtons{
        display: block;
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
    .ubaidCircle{
        padding: 0 !important;
    }
    .ubaidInput{
        width: 86%;
    }
    .UbaidBtnsParent{
        text-align: center;
    }

    .__profile_page_container{
        display: block;
        margin: 0 0vh 0vh 0vh !important;
    }
}
@media only screen and (max-width: 430px){
    .UbaidTopInput{
        margin-top: 0;
    }
    .company_short_bio_Ubaid{
        margin-bottom: 0;
    }
    .ubaidTextArea{
        height: 80px;
    }
    .ubaidInput{
        width: 84%;
    }
    .UbaidBtnsParent{
        display: flex;
        margin-bottom: 12px;
        justify-content: space-between;
    }
    .ubaidBtns{
        width: 48%;
        margin: 0 !important;
    }
    .bigBTN{
        width: 100%;
    }
    .ReviewCardRatingUbaid{
        width: 30% !important;
    }
    .ubaidSubRatings .ReviewCardStarUBaid{
        width: 50% !important;
    }
    .ubaidCompanyBasicDetails{
        display: block;
        margin: -70px 0 0 0 !important;
        text-align: center;
    }
    .UbaidComSubTitle{
        margin: 4px 0 10px 0;
    }
    .ubaidCompanyBasicDetails button{
        display: none;
    }
    .ubaidPencilIcon{
        top: -84px;
    }
    .company_short_bio_Ubaid{
        text-align: center;
    }
    .ubaidCompanyBasicDetails span{
        font-size: 21px;
    }
    .ubaidCompanyBasicDetails .UbaidComSubTitle{
        font-size: 19px;
    }
    .company_conver_con{
        margin-top: 24px !important;
    }
    .__company-share{
        display: block !important;
        position: absolute;
        right: 0;
        top: -20px;
    }
}
@media only screen and (max-width: 400px){
    .MuiBottomNavigation-root-238 *{
        min-width: auto !important;
        padding: 0 !important;    
    }
}
@media only screen and (max-width: 330px){
    .ubaidCircle{
        padding: 0 !important;
        margin: 0 !important;
    }
    .UbaidTopInput{
        margin-top: 0px;
    }
    .ubaidBtns {
        font-size: 12px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
    .ubaidTextArea{
        height: 70px;
    }
    .ReviewCardRatingUbaid button{
        padding: 4px !important;
    }
}