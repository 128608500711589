.ubaidWelcomePage{
  margin-top: 20px !important;
  margin-bottom: 16px !important;
  border-radius: 15px;
}
.ubaidWelcomePage h3{
  margin-top: 1.5em;
  font-size: 1.5em !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 350 !important;
}
.ubaidWelcomePage h1{
  font-weight: 550 !important;
  font-size: 2em;
  font-family: Arial, Helvetica, sans-serif !important;
}
.StartupAimUBaid{
  font-size: 17px;
  padding-bottom: 1em;
}

.panel-heading{
  border-radius: 25px;
  padding: 1em 1em;
}

.pb_dash{
  background-image: url(../img/pb_dash.png);
  margin-bottom: 2em;
  background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 45%;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
}

.welcome-container{
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  padding:1em 3em;
}

.icon_circle{
  display: flex;
  width: 6.5em;
  height: 6.5em;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  border: 1px solid white;
  border-radius: 50%;
  background-color: white;
}

.WelcomePageActions{
  margin: 10px;
  font-size: 19px;
  width: 100%;
  border: 1px solid #F5F5F5;
  border-radius: 10px;
  padding: 1.5em;
  background-color: #F5F5F5;
}

.WelcomePageDescr{
  display: flex;
  text-align: left;
  margin: 14px;
  font-size: 19px;
  max-width: 10em;
}
.WelcomeUBaidTdSr{
  min-width: 36px;
}
@media only screen and (max-width: 1050px){
    .ubaidWelcomePage{
      width: 90%;
      margin-left: auto;
    }

    .panel-heading{
      padding: 0.5em 0em;
    }
    .ubaidWelcomePage h1{
      font-size: 33px;
    }


    .welcome-container{
      display:block;
      padding:1em 0em;
      width: 95%;
    }


}
@media only screen and (max-width: 800px){
  .ubaidWelcomePage{
    margin-top: 42px !important;
  }
}
@media only screen and (max-width: 760px) {
  .ubaidWelcomePage {
    margin: 24px auto !important;
  }
  .StartupAimUBaid{
    line-height: 17px !important;
    font-size: 14.9px;
  }
  .WelcomeMiddlePart{
    margin-top: 24px;
  }
  .ubaidWelcomePage h1 {
    font-size: 24px;
  }
  .ubaidWelcomePage h3{
    font-size: 21px;
    margin-top: 28px;
  }
  .ubaidWelcomePageParent{
    padding: 0 !important;
  }
  .WelcomePageDescr{
    font-size: 16px;
    margin: 10px 0;
  }
  .WelcomeUBaidTdSr{
    min-width: 24px;
  }
}
@media only screen and (max-width: 350px){
  .ubaidWelcomePage h1{
    font-size: 22px;
  }
  .ubaidWelcomePage h3{
    font-size: 20px;
    margin-top: 24px;
  }
}