.LoginPartOlympus{
    background-image: url('../assets/landing-bg.jpg');
    animation: animateBackground 5s infinite linear;   
    background-size: 55%;
    padding-left: 70px;
    justify-content: space-between;
}
@keyframes animateBackground {
    from { background-position: -400px 0; }
    to { background-position: 0 0; }
}

.LoginPartOlympusChild{
    display: flex;
    width: 92.6%;
    margin: auto;
    max-width: 1600px;
    height: 100vh;
    padding-top: 140px;
    overflow: hidden;
}
.LoginPartOlympus .olympusLogin{
    width: 48.5%;
    margin-top: auto;
    margin-bottom: auto;
}
.Olympus h1{
    font-size: 40px;
    margin-bottom: 34px;
    line-height: 52px;
}
.leftPart{
    color: #fff;
    width: 40%;
    margin: auto auto auto 0;
}
.leftPart p{
    font-size: 14px;
    color: #fff;
    margin: 0;
    line-height: 21px;
    font-weight: 400;
}
@media only screen and (max-width:1400px){
    .LoginPartOlympusChild{
        width: 84.5%;
    }
    .LoginPartOlympus .olympusLogin{
        margin-top: 6vh;
    }
}

@media only screen and (max-width: 1150px){
    .Olympus h1{
        font-size: 35px;
        line-height: 44px;
    }
    .leftPart p{
        font-size: 12.5px;
        line-height: 18px;
    }
}
@media only screen and (max-width: 1024px){
    .LoginPartOlympusChild{
        width: 90%;
    }
    .LoginPartOlympus .olympusLogin{
        margin-top: auto;
        padding-bottom: 0;
        margin-bottom: 6px;
    }
}
@media only screen and (max-width: 768px){
    .LoginPartOlympus{
        margin-top: 0;
        padding-left: 0;
    }
    .leftPart{
        width: 48%;
    }
    .Olympus h1{
        font-size: 30px;
    }
    .LoginPartOlympusChild{
        padding-top: 0;
        height: 438px;
    }
}
@media only screen and (max-width: 760px){
    .LoginPartOlympusChild{
        display: block;
        padding: 1px 0 16px 0;
        height: auto;
    }
    .Olympus h1{
        margin: 34px 0;
    }
    .leftPart{
        width: auto;
    }
    .LoginPartOlympus .olympusLogin{
        width: auto;
        margin-top: 36px;
    }
    .LoginPartOlympusChild{
        width: 93%;
    }
}