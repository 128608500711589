/* Scoped uploadPopup.css */

/* Modal overlay */
.add-files-modal-wrapper .add-files-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content */
.add-files-modal-wrapper .modal-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow-y: auto;
    max-height: 80vh;
}

/* Close button */
.add-files-modal-wrapper .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #444;
}

/* Upload container */
.add-files-modal-wrapper .upload-container {
    margin-bottom: 20px;
}

/* Upload section */
.add-files-modal-wrapper .upload-section {
    display: flex;
    flex-direction: column;
}

.add-files-modal-wrapper .upload-select,
.add-files-modal-wrapper .custom-file-upload {
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
}

.add-files-modal-wrapper .custom-file-upload {
    display: inline-block;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    text-align: center;
    border-radius: 4px;
}

.add-files-modal-wrapper .custom-file-upload.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Input styling */
.add-files-modal-wrapper input[type="file"],
.add-files-modal-wrapper input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 10px;
    width: 100%;
}

/* Radio button styling */
.add-files-modal-wrapper input[type="radio"] {
    margin-right: 10px;
    width: auto; /* Prevents it from taking up full width */
}

/* Error messages */
.add-files-modal-wrapper .error {
    color: red;
    margin-top: 5px;
}

/* Button styling */
.add-files-modal-wrapper button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    margin-top: 20px;
}

.add-files-modal-wrapper button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Uploaded list */
.add-files-modal-wrapper .upload-list {
    margin-top: 20px;
}

.add-files-modal-wrapper .upload-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.add-files-modal-wrapper .file-name {
    flex-grow: 1;
    margin-left: 10px;
}

.add-files-modal-wrapper .upload-tick {
    color: green;
    margin-left: 10px;
}

.add-files-modal-wrapper .delete-icon {
    color: red;
    cursor: pointer;
    margin-left: 10px;
}

.add-files-modal-wrapper .chip {
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.add-files-modal-wrapper .data-room-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 5px;
    color: #444;
}