
.privacyPolicyPage {


    padding: 2rem 8rem;


    
    &__title {

        display: flex;
        justify-content: center;
        margin-bottom: 2rem;


    }




}