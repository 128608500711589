.AragonFooterTables{
    margin-top: 24px;
}
.AragonFooterTables button{
    background: #5E74E4;
    color: #fff;
    padding: 4px 8px;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    border-radius: 4px;
    margin-left: auto;
}
.PageVisitsTable{
    width: 65%;
    margin-right: 12px
}
.PageVisitsTable .AragonWorkCards{
    width: 100%;
}
.SocialTraffic{
    width: 35%;
    margin-left: 12px;
    overflow-x: scroll;
}
.PageVisitsTable table{
    width: 100%;
    min-width: 505px;
}
.SocialTraffic table{
    width: 100%;
    min-width: 300px;
}
@media only screen and (max-width: 1150px){
    .AragonFooterTables{
        display: block;
    }
    .PageVisitsTable, .SocialTraffic{
        width: 100%;
        margin: 0 0 24px 0;
    }
    .SocialTraffic{
        width: 100%;
    }
}
@media only screen and (max-width:575px){
    .PageVisitsTable{
        overflow-x: scroll;
    }
}