.__ubaid__stripe__popup{
    display: flex !important;
    padding: 0 ;
    .__stripeModel{
        background: #fff;
        width: 300px;
        border-radius: 8px;
        box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
        margin: auto;
        .__card__header{
            background: #000;
            color: #fff;
            padding: 12px;
            text-align: center;
            border-radius: 8px 8px 0 0;
            .__small{
                color: #757575;
                display: block;
                font-weight: bold;
                &:last-child{
                    margin-bottom: 10px;
                }
            }
            h2{
                margin: 0;
                font-weight: 500;
            }
        }
        form{
            padding: 16px;
        }
        .__small{
            font-size: 13px;
        }
        .__flex{
            display: flex;
            align-items: center;
        }
        .__author__name{
            margin: auto;
            font-size: 15px;
            font-weight: bold;
        }
        .fa-times{
            background: #263238;
            border-radius: 16px;
            height: 16px;
            width: 16px;
            text-align: center;
            line-height: 16px;
            font-size: 12px;
            color: #616161;
        }
        button{
            display: block;
            width: 100%;
            outline: none;
            background: #2196f3;
            color: #fff;
        }
        label{
            &:nth-child(1){
                margin-right: auto;
                min-width: 100px;
                transition: linear 0.2s min-width;
            }
            &:nth-child(2){
                padding-right: 12px;
                min-width: 84px;
            }
            &:nth-child(3){
                min-width: 32px;
            }
        }
        .__stripe__form__wrapper{
            border-radius: 4px;
            margin: 4px 0 24px 0;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
        }
        .__focus{
            min-width: 224px !important;
        }
        .__form__container{
            padding: 7px 0 3px 0;
            overflow: scroll;
            scroll-behavior: smooth;
            margin-left: 12px;
            &::-webkit-scrollbar {
                height: 0;
            }
        }
        .__country__select{
            margin: 4px 0 16px 0;
            width: 100%;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
            border-radius: 4px;
            .MuiOutlinedInput-input{
                padding: 9.5px 13px;
            }
            *{
                border: none !important;
            }
        }
        .__footer{
            line-height: normal;
            text-align: center;
            font-size: 12px;
            margin-top: 12px;
            a{
                padding-bottom: 1px;
                border-bottom: 0.5px solid;
                color: #757575;
                &:hover{
                    color: #616161 !important;
                }
            }
        }
    }

}
