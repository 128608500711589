.PitchBoxHeader{
    display: block;
    background: #ff6c00;
    background-image: url('../assets/pattern.png');
    background-repeat: repeat-x;
    background-position: center center;
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1px;
    border-radius: 0px;
    width: 100%;
    position: relative;
    color: #fff;
}
.PitchBoxHeader *{
    font-family: "Trebuchet MS", sans-serif;
}
.PitchBoxHeaderBackground{
    width: 939px;
    height: 546px;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.PitchBoxHeaderBackgroundMain{
    padding: 30px 90px;
    width: 933px;
    margin: auto;
    position: relative;
}
.PitchBoxHeader h2{
    font-size: 36px;
    margin: -2px 0 0 0;
}
.PitchBoxHeader h4{
    line-height: 38px;
    margin: auto;
    font-weight: 400;
    letter-spacing: 0;
    font-size: 24px;
    color: #fff;
}
.startupLogo{
    width: 80px;
    margin: 10px 0 16px 0;
}
.PitchBoxHeader h5{
    font-size: 22px;
    margin: 1px auto auto auto;
    max-width: 560px;
}
.PitchboxStartup button{
    border: none;
    color: #fff;
    cursor: pointer;
}
.PitchBoxHeader button{
    background: rgba(107,106,106,1);
    font-size: 21px;
    margin: 23px 0 12px 0;
    width: 181px;
    height: 50px;
    box-shadow: inset 0px 1px 0px #848484, inset 0 -1px 2px #383737;
    line-height: 25px;
    font-weight: 600;
    border-radius: 3px;
}
.animatedArrow{
    width: 48px;
    position: absolute;
    margin: 34px 8px;
}
.hideOnLarge{
    display: none;
}
@media only screen and (max-width: 949px){
    .PitchBoxHeaderBackground{
        width:100%;
        height: auto;
    }
    .PitchBoxHeaderBackgroundMain{
        width: 80%;
    }
}
@media only screen and (max-width: 800px){
    .PitchBoxHeaderBackgroundMain{
        padding: 0;
        padding-bottom: 24px;
    }
    .mainPitchLogo{
        width: 280px;
        margin-top: 24px;
    }
    .PitchBoxHeader h2{
        font-size: 29px;
        margin: -1px 0 0 0;
    }
    .PitchBoxHeader h4{
        font-size: 20px;
        line-height: 31px;
        letter-spacing: 0px
    }
    .PitchBoxHeader .startupLogo{
        width: 65px;
        margin: 8px 0 16px 0
    }
    .PitchBoxHeader h5{
        font-size: 18px;
        max-width: 440px;
    }
    .PitchBoxHeader button{
        font-size: 17px;
        margin: 18px 0 34px 0;
        width: 150px;
        border-radius: 4px;
        height: 41px;
    }
    .animatedArrow{
        width: 38px;
        margin: 26px 6px;
    }
}
@media only screen and (max-width: 600px){
    .PitchBoxHeaderBackground{
        display: none;
    }
    .hideOnLarge{
        display: block;
    }
    .hideOnSmall{
        display: none;
    }
    .mainPitchLogo{
        margin: 26px 0 9px 0;
        width: 260px
    }
    .PitchBoxHeader .startupLogo{
        width: 80px;
    }
    .PitchBoxHeader h4{
        font-size: 17px;
        line-height: 26.5px
    }
    .PitchBoxHeader button{
        width: 225px;
        margin: 38px 0 29px 0;
        height: 44px;
        font-size: 17px;
        border-radius: 5px;
        box-shadow: inset 0px 1px 0px #adadad, inset 0 -1px 2px #616161;
        background: #949494;
        font-family: Ubuntu;
    }
    .PitchBoxHeader h5{
        font-size: 15px;
        max-width: 300px;
    }
    .PitchBoxHeaderBackgroundMain{
        padding-bottom: 24px;
    }
}
@media only screen and (max-width: 360px){
    .PitchBoxHeaderBackgroundMain{
        width: 320px
    }
}
