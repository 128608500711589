*{
    scroll-behavior: smooth;
}
.__M-landingPage{
    vertical-align: middle;
    height: 90vh;
    padding: 0.1px;
    font-family: 'Oxygen', sans-serif;
    color: #212730;
    line-height: 1.8em;
    
    .landingpage_bgimg{
        min-height: 100vh;
        overflow: auto;
        background-image: url("../../img/lion.jpg");
        background-color: rgba(0, 0, 0, 0.7); /* Tint color */
        background-blend-mode: multiply;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        padding: 2em 10em 3em 10em;

        .__landing_header{
            width: 90%;
            padding-bottom: 10vh;

            .__landing_logo{
                float: left;
    
                img{
                    max-width: 250px;
                    width: 100%;
                    position: initial;
                    filter: drop-shadow(2px 2px 1px #000000);
                    top: 4em;
                }
    
                .__logo-subtitle{
                    color: #ffffff;
                    font-size: 18px !important;
                    letter-spacing: 0.075em !important;
                    text-transform: uppercase !important;
                    font-weight: 300 !important;
                    font-family: 'Helvetica Neue' !important;
                    transform: scale(1, .7);
                    font-style: italic;
                }
            }
    
            .__landing_menu{
                float: right;
                margin-top: 1em;
                
                a{
                    padding: 15px;
                    color: #fff;
                    font-size: large;
                    font-weight: bold;
                }

                
            }
    

        }
        
        .__landing_hero{
            display:contents;
            width: 80%;

            .__hero_left{
                max-width: 100%;
                text-align: center;
                margin: auto;

                .__viewport-title{
                    color: #C49733;
                    margin: 0.6em 0 0.6em 0;
                    font-family: 'Georgia'!important;
                    font-size: 5vh !important;
                    letter-spacing: 0.07em !important;
                    line-height: 50px;
                    margin-bottom: 24px !important;
                    margin-top: 40vh;
                    font-weight: bold;
                }
        
                .__viewport-body{
                    color: #ffffff;
                    font-size: 20px ;
                    letter-spacing: 0.05em !important;
                    margin-bottom: 10vh;
                    line-height: 35px;
                    font-weight: 400 !important;
                    font-family: 'Helvetica Neue', sans-serif !important;
                    text-align: center;
                    margin: auto;
                }
        
                .goldbutton{
                    background-color: #C49733;
                    margin-top: 5vh;
                }
        
                .whitebutton{
                    background-color: #999;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-left: 10px;
                }

            }

            .__hero_right{
                display: inline-block;
                float: right;
                right: 5vh;
                text-align: center;
                max-width: 40% !important;
                vertical-align: middle;

                img{
                    padding: 10vh 10vh 0 0vh;
                    max-width: 100%;
                }

            }
        }

        .__container{
            padding: 5vh;
        }


        
        .loggedin{
            margin-right: 3em;
        }

        @media (max-width: 767px) {

            display: block;

            .landingpage_bgimg{
                padding: 2em 0em 2em 2em;
                img{
                    width: 90%;
                }

                .__left{
                    margin-left: 2em;
                    
                    
                }
            }

            .__landing_logo{
                margin: auto;
                padding-left: 0vh;
            }

        .__hero_left{
                max-width: 100% !important;


            .__viewport-title{
                margin-top: 15vh !important;
                line-height: 50px !important;
                max-width: 100% !important;
    
            }

            .__viewport-body{
                color: #ffffff;
                font-size: 20px !important;
                letter-spacing: 0.05em !important;
                margin-bottom: 10vh;
                line-height: 35px;
                font-weight: 400 !important;
                font-family: 'Helvetica Neue', sans-serif !important;
            }
    
        }

            padding: 1em 0;
            .__container{
                display: block;
                >div{
                    margin: 2em 0;
                }
            }

            .__landing_menu{
                
            }


            .__client-logos{
                width: 90% !important;

                img{
                    margin: auto;
                    width: 80%;
                    margin-top: 0em;
                    max-height: 10em;
                }
            }

            padding: 3em 1em 1em 2em;
            .landingpage_bgimg{
                max-height: 100vh;
            }
            .__viewport-title {
                margin-top: 5vh !important;
                font-size: 30px;
            }
            

            .__viewport-body{
                color: #ffffff;
                font-size: 20px !important;
                letter-spacing: 0.05em !important;
                margin-bottom: 20px !important;
                line-height: 35px;
                font-weight: 400 !important;
                font-family: 'Helvetica Neue', sans-serif !important;
                margin-right: 2em;

                
            }
            .whitebutton{
                margin-bottom: 20px;
                margin-top: 10px;
                margin-left: 0px;
            }

            .goldbutton{
                background-color: #C49733;
                margin: 5vh 0 !important;
            }

            .__hero_right{
                display: none !important;
                float: right;
                right: 0vh !important; 
                text-align: center;

                img{
                    padding: 10vh 10vh 0 0vh;
                    max-width: 70%;
                }

            }


        }

        @media (max-width: 1300px){
            .__hero_right{
                float: right;
                right: 0vh !important; 
                text-align: center;

                img{
                    padding: 10vh 10vh 0 0vh;
                    max-width: 20%;
                }

            }
            
        }

        @media (max-width: 1100px) {
                // padding: 3em 0em 0em 2em;

                .__landing_header{
                    margin: auto;
                    text-align: center;
                }

                .__viewport-title{
                    font-family: 'Helvetica Neue', sans-serif !important;
                    letter-spacing: 0.07em !important;
                    line-height: 5vh !important; 
                    margin-bottom: 24px;
                    font-weight: 700 !important;
                    max-width: 600px !important;
        
                }

                .__hero_right{
                    display: none;
                }
                .__landing_menu{
                    
                }

                .groupers{
                display: block !important; 
                padding: 0;
            }

   
        }
    }
    }

    .__landing-page-btn{
        border: 1px solid;
        padding: 0.3em 2.4em;
        font-weight: 400;
        border-radius: 1px;
        font-size: 14px;
        display: inline-block;
        cursor: pointer;
    }
    .__landing-page-btn-2{
        background: #0b3866;
        padding: 6px 35px;
        color: #fff !important;
        display: inline-block;
        font-size: 14px;
        border-radius: 2px;
    }
    .__container{
        margin: auto;
    }
    .__yellow{
        color: #e29a1d;
        cursor: pointer;
    }

    .__landing-signup{
        //position: fixed;
        max-width: 55vh;
        margin: auto;
        top:10vh;
        margin-bottom: 5vh;
        border-radius: 25px;
        }

    .__viewport{
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 30vh;
        padding: 3em;
        justify-content: center;

        .groupers{
            max-width: 130vh;
            min-width: 20vh;
            padding: 3vh 0vh 3vh 10vh;
            display: flex;

            img{
                max-width: 17.5vh;
                margin-top: 5vh;
                // border-radius: 50%;
            }

            .roundimg{
                max-width: 17.5vh;
                border-radius: 50%;
                margin-top: 5vh;
            }

            .__infoport-title{
                color: #181818;
                margin: 1em;
                font-family: 'Helvetica Neue', sans-serif !important;
                font-size: 27.5px !important;
            }

        }

        .__landing_left{

            float: left;
            .__image-wrapper{
                display: flex;
                flex-wrap: wrap;
                width: 90%;

                .groupers{
                max-width: 70vh;
                min-width: 50vh;
                padding-bottom: 3vh;
                }

                
            }
            .__viewport-subtitle{
                word-spacing: -2px;
                letter-spacing: 0px;
                color: #ffffff;
            }
        }
        .__landing_right{
            float: right;
            position: fixed;
            right: 0;
        }
        @media (max-width: 1100px) {
            .landingpage_bgimg{
                padding: 3em 0em 0em 0em;

                .__left{
                        margin-left: 2em;
                        img{
                        margin: initial;
                    }

                    .__image-wrapper{
                        flex-direction: column;
                        flex-wrap: wrap ;
                        

                    }

                    

            

            }
        }

        padding: 2em 0em 2em 0em;

        .groupers{
            max-width: 130vh;
            min-width: 20vh;
            padding: 0vh 0vh 0vh 0vh;
            display: block;
            text-align: center;

            img{
                max-width: 17.5vh;
                // border-radius: 50%;
            }

            .roundimg{
                max-width: 17.5vh;
                border-radius: 50%;
            }

        }

            .__viewport-title{
                font-size: 32px;
            }
            .__viewport-subtitle{
                font-size: 20px;
            }
        }

    }


.__M-landingPage{
    .__startupfuel-network{
        padding: 4em 0;
        background: #202131;
        text-align: center;
        color: #fff;
        .__content{
            display: flex;
            justify-content: space-between;
            >div:not(:first-child, :last-child){
                margin: 0 1em;
            }
            .__title{
                display: block;
                margin: 0.8em 0 1em 0;
                font-size: 18px;
                font-weight: 500;
            }
            i{
                font-size: 2.4em;
                margin-top: 1em;
            }
        }
        .__main-title{
            font-weight: 500;
            margin: 0 0 1em 0;
            font-size: 34px;
            line-height: 1.3em;
        }
    }
}
.__M-landingPage{
    .__landing-page-content{
        font-family: 'Raleway', sans-serif;
        margin: 4.4em auto;
        font-weight: 500;
        border-bottom: 1px solid rgba($color: #0b3866, $alpha: 0.3);
        &.__last{
            border: none;
        }
        .__main{
            display: flex;
            align-items: center;
        }
        .__title{
            font-weight: 500;
            color: #0b3866;
            font-family: 'Lato', sans-serif;
            margin: 0 0 1.4em 0;
            font-size: 32px;
        }
        ul{
            font-weight: 500;
            margin: 1.8em 0;
            padding: 0 0 0 1em;
        }
        img{
            max-width: 50%;
            margin: auto;
            display: block;
        }
        li{
            margin: 0.7em 0;
        }
        .__support-and-service-wrapper{
            margin: 4em 0;
        }
        .__support{
            display: flex;
            align-items: center;
            .__images{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }
        .__content{
            text-align: center;
            max-width: 27%;
            padding: 1.2em 0.9em;
            border-right: 1px solid rgba($color: #0b3866, $alpha: 0.1);
            .__title-2{
                font-weight: 500;
                color: #0b3866;
                margin: 0 0 0.8em 0;
                font-family: 'Lato', sans-serif;
                font-size: 30px;
            }
            .__landing-page-btn-2{
                margin-top: 1.3em;
            }
        }
        &:nth-child(even){
            .__main{
                flex-direction: row-reverse;
                img{
                    margin: 0 2em 0 0;
                }
                .__details{
                    margin-right: auto;
                }
            }
            .__content{
                border: none;
            }
            .__images{
                border-right: 1px solid rgba($color: #0b3866, $alpha: 0.1);
            }
            .__support{
                flex-direction: row-reverse;
            }
        }
        @media (max-width: 1023px) {
            .__landing-page-content{
                margin: 3em auto 0 auto;
            }
            .__main{
                display: block;
                img{
                    max-width: 100%;
                    margin: 1em 0 !important;
                }
            }
            .__support-and-service-wrapper{
                margin: 1em 0 0 0;
            }
            .__content{
                max-width: initial;
                padding: 0;
                border: none;
            }
            .__support{
                display: block;
                .__images{
                    justify-content: space-between;
                    margin: 2em 0;
                    border: none;
                }
            }
        }
    }
}

.__M-landingPage{
    .__client-logos{
        display: none !important;
        justify-content: center;
        width: 100%;
        margin: auto;
        background-color: #EDF2F4;
        padding: 1% 0;

        img{
            width: 12.5%;
            margin-left: 6%;
            object-fit: cover;
            opacity: .4;
        }

        }
        @media (max-width: 1100px) {
            .__client-logos{
                display: none;
                height: auto;

                img{
                    margin: auto;
                    margin-top: 0em;
                    width: 30vh;
                }
            }
        }
}
                

.__M-landingPage{
    h4{
        color: inherit;
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 24px;
    }
    .__landing-page-footer{
        padding: 2em 1em;
        background: #000;
        text-align: center;
        color: #fff;
        font-size: 12px;
        // display: flex;
        justify-content: space-around;
        height: 27.5vh;
        bottom: 0;


        .__footerlogo{
            width: 70%;
            max-width: 300px;
            object-fit: contain;
        }

        a{
            margin: 0 0.7em;
            color: #fff;
            font-weight: 500;
            display: inline-block;
            line-height: normal;
            font-family: 'Raleway', sans-serif;
            &:last-child{
                border-right: none;
            }
        }
        .img {
            max-width: "2px";
        }
        

        .__icons{
            padding-top: 2em;
            i{

                font-size: 2em ;
                padding: 0.5em;
            }
        }
        .__links{
            padding: 1em;
            font-size: 15px;
        }


        @media (max-width: 1100px) {
            display: block;
            .__links{
                display: flex;
                align-items: center;
                justify-content: space-around;
                max-width: 420px;
                margin: auto;
            }
            .__icons{
                display: flex;
                align-items: center;
                justify-content: space-around;
                max-width: 420px;
                margin: auto;
            }
            a{
                margin: 0;
            }
        }
    }
}

