.olympusLoginPage svg{
    fill: #fff !important;
    display: block;
    margin: 0 0 0 auto;
    cursor: pointer;
    width: 18px;
    height: 18px;
}
.olympusBlur{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 300;
}
.olympusLoginPage{
    position: fixed;
    width: 540px;
    left: 50%;
    margin-left: -270px;
    margin-top: 10px;
    animation: ease 0.3s forwards olympusLoginPage;
    z-index: 300;
}
.olympusLoginPage .olympusLogin{
    width: 518px;
}
.olympusnavToggle{
    background: #ff5e3a;
    padding: 22.4px;
    cursor: pointer;
    position: absolute;
    right: 0;
    z-index: 2;
    top: 0;
}
.olympusnavToggle svg{
    fill: #fff !important;
    width: 25px;
    height: 24px;
}
@keyframes olympusLoginPage{
    0%{
        opacity: 0;
        top: -200px;
    }
    100%{
        opacity: 1;
        top: 0;
    }
}
.olympusLoginPageHide{
    animation: ease 0.5s forwards olympusLoginPageHide;
}
@keyframes olympusLoginPageHide{
    0%{
        opacity: 1;
        top: 0;
    }
    100%{
        opacity: 0;
        top: -500px;
    }
}
@media only screen and (max-width:768px){
    .olympusnavToggle{
        padding: 12.5px;
        position: fixed;
    }
}
@media only screen and (max-width: 600px){
    .olympusLoginPage{
        width: 90%;
        left: 0;
        margin-left: 5%;
    }
    .olympusLoginPage .olympusLogin{
        width: 100%;
    }
    .olympusLoginPage svg{
        margin: auto;
    }
}
@media only screen and (max-width: 350px){
    .olympusLoginPage{
        width: 94%;
        left: 0;
        margin-left: 3%;
    }
}