.errorpage{
  margin: auto;
  left: 0;
  right: 0;
  z-index: 99999;
  text-align: center;
}

#container-error {
  text-align: center;
  color: #000;
  padding: 5vh 50vh;
  margin: 10vh;
  justify-content: center;
  text-align: center
;
}

.error-title {
  text-align: center;
  width: 100%;
  z-index: 99999;
  font-size: 5rem;

}


.error-redirect{
  display: flex;
  margin-top: 5vh;
}

#buttonerror {
  clear: both;
  opacity: 0.6;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 250px;
  padding: 20px;
  z-index: 99999;
  border-radius: 10px;
  transition: all .5s;
}

#buttonerror a {
  color: #000;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: underline;
  text-underline-offset: 8px;
}

#buttonerror:hover {
  border: 1px solid grey;
  color: red;
}

#buttonerror:hover a,
#buttonerror a:hover {
  color: red;
}

