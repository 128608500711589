.AragonBody{
    padding: 12px 24px;
    color: #fff;
}
.AragonCardTitle{
    font-size: 15px;
    font-weight: bold;
}
.AragonPaddiing{
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.AragonMemberIcon{
    width: 48px;
    height: 48px;
    border-radius: 24px;
    min-width: 48px;
}
.AragonMR-6{
    margin-right: 10px;
}
.Aragon p{
    font-size: 16px;
    color: rgba(82, 95, 127, 0.8);
    line-height: 28px;
}
.AragonPostPersonActions img{
    width: 32px;
    border-radius: 20px;
    border: 2px solid #fff;
    margin: -1px 0 0 -12px;
}
.AragonFooterCreative{
    color: #8898AA;
    margin: 26px 0 16px 0
}
.AragonFooterCreative h6{
    margin: 4px 0 0 0;
}
.AragonFooterCreative a{
    font-size: 14px;
    margin: 0 0 0 24px
}
.AragonFooterCreative a:hover{
    color: #516174
}
@media only screen and (max-width: 1150px){
    .ActivityLightFields, .AragonFooterCreative{
        display: block;
        text-align: center;
    }
    .AragonFooterCreative h4{
        margin-bottom: 10px;
    }
}
@media only screen and (max-width: 380px){
    .AragonFooterCreative a{
        margin: 0 8px
    }
}
@media only screen and (max-width: 340px){
    .AragonFooterCreative a{
        margin: 0 6px;
        font-size: 13px;
    }
}