.OlympusNewsLetter {
  background: url("../assets/bg-users.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding-top: 44px;
}
.OlympusNewsLetter p {
  color: #fff;
}
.OlympusNewsLetter form {
  display: flex;
}
.olympus-inputField {
  flex-grow: 100;
  margin-right: 16px;
}
.OlympusNewsLetter input {
  padding-bottom: 3px;
}
.OlympusNewsLetter button {
  margin: auto 0 !important;
  height: 54px;
  background: #38a9ff;
  min-width: 114px;
  border: none;
}
.OlympusNewsLetter label {
  color: #9a9fbf !important;
}
.floatingOlympus {
  animation-delay: 200ms;
  animation: 0.8s forwards floatingOlympus ease;
  position: relative;
}
@keyframes floatingOlympus {
  0% {
    right: 0;
  }
  10% {
    right: 70%;
  }
  100% {
    right: 0;
  }
}
@media only screen and (max-width: 1024px){
    .OlympusNewsLetter button{
        height: 50px;
    }
}
@media only screen and (max-width: 768px) {
  .OlympusNewsLetter {
    padding: 24px 0 1px 0;
  }
  .OlympusNewsLetter form{
      margin-top: 24px;
  }
}
@media only screen and (max-width: 760px) {
  .olympus-inputField {
    flex-grow: 100;
    margin-right: 0;
  }
}
