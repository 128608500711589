.SidebarOlympusShow, .SidebarOlympus{
    box-shadow: 0 0 34px 0 rgba(63,66,87, 0.1);
    width: 270px;
    top: 70px;
    background: #fff;
    height: 100%;
    position: fixed;
    width: 70px;
    transition: ease 0.3s all;
    animation: forwards ease 0.6s SidebarOlympus;
    z-index: 2;
}
@keyframes SidebarOlympus{
    0%{
        width: 270px;
        left: 0;
    }
    40%{
        width: 270px;
        left: -270px;
    }
    80%{
        width: 70px;
    }
    100%{
        left: 0;
    }
}
.SidebarOlympusShow{
    left: 0 !important;
    animation: forwards ease 0.6s SidebarOlympusShow;
}
@keyframes SidebarOlympusShow{
    0%{
        width: 70px;
        left: 0;
    }
    40%{
        left: -270px;
    }
    60%{
        width: 270px;
    }
    100%{
        left: 0;
        width: 270px;
    }
}
.sideBarToggle{
    position: fixed;
    top: 0;
    background: #ff5e3a;
    padding: 19px 25px;
    cursor: pointer;
    z-index: 2;
}
.sideBarToggle svg{
    fill: #fff;
    width: 20px;
}
.SidebarOlympusShow svg, .SidebarOlympus svg{
    width: 24px;
    height: 24px;
    margin: 0 24px;
    fill: #9A9FBF;
}
.SidebarOlympusShow a:hover svg, .SidebarOlympus a:hover svg{
    fill: #ff5e3a;
}
.SidebarOlympusShow a, .SidebarOlympus a{
    color: inherit;
    font-size: 14px;
    font-weight: bold;
    color: #9a9fbf;
    display: block;
    margin: 25px 0 30px 0;
    height: 24px;
    overflow: hidden;
    align-items: center;
}
.SidebarOlympusShow a:hover{
    color: #3f4257 !important;
}
@media only screen and (max-width: 768px){
    .SidebarOlympusShow, .SidebarOlympus{
        width: 270px;
        animation: none;
        left: -270px;
        transition: ease-in 0.3s all;
        top: 50px;
        z-index: 2;
    }
    .SidebarOlympusShow{
        left: 0;
    }
    .sideBarToggle{
        padding: 9px 12px;
        width: 50px;
        text-align: center;
    }
    .SidebarOlympusShow a, .SidebarOlympus a{
        font-size: 13px;
        font-weight: normal;
        display: flex;
    }
    .SidebarOlympusShow svg, .SidebarOlympus svg{
        width: 22px;
        height: 22px;
        margin: 0 16px;
    }
    .sideBarToggle{
        z-index: 1;
    }
}