.deal-memo-footer {
  margin-top: auto;
  margin-bottom: 40px;
  margin-left: 80px;
  margin-right: 80px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.deal-memo-footer__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deal-memo-footer__header h2 {
  margin: 0;
}

.deal-memo-footer__divider {
  height: 4px;
  flex: 1;
  margin-left: 20px;
}

.deal-memo-footer__info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.deal-memo-footer__favicon {
  width: 36px;
  height: 36px;
}

.deal-memo-footer__vertical-divider {
  width: 4px;
  height: 36px;
}

.deal-memo-footer__page-number {
  margin: 0;
}

.deal-memo-report-overview {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.deal-memo-report-overview__container {
  width: 100%;
  padding: 40px;
}

.deal-memo-report-overview__header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.deal-memo-report-overview__title-section {
  display: flex;
  flex-direction: column;
}

.deal-memo-report-overview__title {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
}

.deal-memo-report-overview__subtitle {
  font-size: 18px;
}

.deal-memo-report-overview__logo-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.deal-memo-report-overview__logo {
  width: 250px;
}

.deal-memo-report-overview__agency {
  font-size: 18px;
  margin: 0;
}

.deal-memo-report-overview__company-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.deal-memo-report-overview__company-name {
  font-size: 24px;
  margin: 0;
}

.deal-memo-report-overview__date {
  font-size: 18px;
  margin: 0;
  color: #737373;
}

.deal-memo-report-overview__divider {
  border: none;
  border-top: 1px dotted black;
  width: 100%;
}

.deal-memo-report-overview__report-type {
  padding-left: 10px;
  background-color: #f3f5f9;
  width: 25%;
  border-left: 8px solid #c49733;
}

.deal-memo-report-overview__report-type-text {
  font-size: 24px;
  margin: 0;
}

.deal-memo-report {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.deal-memo-report__content {
  padding: 40px;
}

.deal-memo-report__header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.deal-memo-report__title-section {
  display: flex;
  flex-direction: column;
}

.deal-memo-report__title {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
}

.deal-memo-report__subtitle {
  font-size: 18px;
}

.deal-memo-report__logo-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.deal-memo-report__logo {
  width: 250px;
}

.deal-memo-report__agency {
  font-size: 18px;
  margin: 0;
}

.deal-memo-report__company-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.deal-memo-report__company-name {
  font-size: 24px;
  margin: 0;
}

.deal-memo-report__date {
  font-size: 18px;
  margin: 0;
  color: #737373;
}

.deal-memo-report__divider {
  border: none;
  border-top: 1px dotted black;
  width: 100%;
}

.deal-memo-report__type-section {
  padding-left: 10px;
  background-color: #f3f5f9;
  width: 25%;
  border-left: 8px solid #c49733;
}

.deal-memo-report__type-text {
  font-size: 24px;
  margin: 0;
}

.deal-memo-report__main-content {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.deal-memo-report__score-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.deal-memo-report__score-label {
  width: 100px;
  font-weight: bold;
  font-size: 18px;
}

.deal-memo-report__score-bar {
  flex: 1;
  display: flex;
  justify-content: center;
}

.deal-memo-report__score-boxes {
  display: flex;
  height: 20px;
  width: 400px;
}

.deal-memo-report__score-value {
  margin-left: 20px;
  font-size: 18px;
  font-weight: bold;
}

.deal-memo-report__description {
  font-size: 18px;
}

.deal-memo-report__evaluation-title {
  font-size: 24px;
  font-weight: bold;
}

.deal-memo-report__evaluation-text {
  font-size: 18px;
}

.deal-memo-process {
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: #000;
  display: flex;
  flex-direction: column;
}

.deal-memo-process__header {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background-color: #808080;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deal-memo-process__dots {
  display: flex;
  gap: 8px;
}

.deal-memo-process__dot {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
}

.deal-memo-process__title-section {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 0 80px;
  align-items: center;
  justify-content: space-around;
}

.deal-memo-process__title {
  color: #fff;
}

.deal-memo-process__lines {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: auto;
  align-items: flex-end;
}

.deal-memo-process__line {
  background-color: #fff;
}

.deal-memo-process__line--large {
  width: 24px;
  height: 2px;
}

.deal-memo-process__line--medium {
  width: 18px;
  height: 2px;
}

.deal-memo-process__line--small {
  width: 12px;
  height: 2px;
}

.deal-memo-process__divider {
  width: 100%;
  height: 1px;
  background-color: #737373;
  margin-top: 20px;
  padding: 0 80px;
}

.deal-memo-process__content {
  display: flex;
  margin-top: 80px;
  flex-direction: column;
  padding: 0 80px;
  gap: 40px;
}

.deal-memo-process__step {
  display: flex;
  align-items: center;
  gap: 40px;
}

.deal-memo-process__step-number {
  padding: 20px;
  background-color: #100f0d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
}

.deal-memo-process__step-text {
  color: white;
  font-size: 18px;
}

.deal-memo-process__contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.deal-memo-process__contact-text {
  color: #fff;
  font-size: 24px;
  text-align: center;
}

.deal-memo-process__email {
  color: #c49733;
  font-size: 24px;
  text-align: center;
}
