.UbaidPolicyFlex{
    display: flex;
    margin: 16px auto;
    font-size: 20px;
    max-width: 850px;
    width: 98%;
}
.UbaidPolicyFlex b, .UbaidPolicyFlex span{
    min-width: 169px;
    max-width: 169px;
    margin-right: 24px;
}
.ubaidPolicy{
    margin: 32px 0;
}
@media only screen and (max-width:760px){
    .privacyPolicyPage{
        padding: 24px;
    }
    .UbaidPolicyFlex{
        font-size: 16px;
    }
    .UbaidPolicyFlex b, .UbaidPolicyFlex span{
        min-width: 140px;
        margin-right: 11px;
    }
}