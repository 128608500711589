.AragonActiveField{
    width: 40%;
}
.AragonBackground{
    display: block;
    width: 100%;
    border-radius: 6px;
}
.AragonPost{
    padding: 1px 24px 0 24px;
}
.AragonPost p{
    margin: 24px 0;
}
.AragonPostStatus{
    padding: 20px 0 9px 0;
    border-bottom: 1px solid rgb(136, 152, 170, 0.3);
}
.AragonPostStatus *{
    font-size: 14px;
    font-weight: bold;
    color: #8898AA;
}
.AragonPostStatus a,
.AragonPostComments a{
    margin-right: 16px;
}
.AragonPostStatus i,
.AragonPostComments i{
    color: #32325D;
    margin-right: 4px;
    font-size: 14px;
}
.AragonPostStatus .AragonPostPersonActions img{
    width: 20px;
}
.AragonCommentsMain{
    border-bottom: 1px solid rgb(136, 152, 170, 0.3);
}
.AragonPostComments{
    padding: 0 0 0 24px;
    margin: 16px 0;
}
.AragonPostComments img{
    left: -24px;
    position: relative;
    border: 4px solid #fff;
    border-radius: 30px;
    display: block;
}
.AragonPostComments *{
    text-align: left ;
}
.AragonPostComments img:hover{
    width: 50px;
    height: 50px;
}
.AragonComments{
    margin-top: -42px;
    background: #F6F9FC;
    padding: 16px 16px 16px 36px;
    border-radius: 6px;
}
.AragonSmallTitle{
    font-size: 13px;
    font-weight: bold;
}
.AragonFont-14{
    margin: 10px 0 18px 0 !important;
    line-height: 22px !important;
    font-size: 14px !important;
}
.AragonCommentBox{
    padding: 16px 0;
}
.AragonCommentBox textarea{
    margin-left: 16px;
    border: 1px solid rgb(136, 152, 170, 0.3);
    border-radius: 4px;
    outline: none;
    padding: 10px 12px;
    height: 19px;
    color: rgb(136, 152, 170);
    font-size: 14px;
    font-family: "Open Sans";
    margin-top: 5px;
}
.AragonCommentBox textarea:focus{
    border-color: #5e72e4;
}
.AragonCommentBox textarea::placeholder{
    font-weight: bold;
}
@media only screen and (max-width: 1150px){
    .AragonActiveField{
        width: auto;
    }
}
@media only screen and (max-width:575px){
    .ArgonHideOnSmall{
        display: none;
    }
    .ArgonDisplayBlockOnSmall{
        display: block;
        line-height: 24px
    }
}