.SubmissionProfileCard {
  display: flex;
  flex-direction: column;
}
.SubmissionProfileCard .__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.SubmissionProfileCard .__footer .__info-btn {
  background: #26a69a;
  color: #fff;
}
.SubmissionProfileCard .__footer button {
  margin: 10px;
  font-weight: bold;
}

.UBaidSubCardHeader {
  padding: 12px 12px;
  display: flex;
  align-items: center;
}
.UbaidSubCardActionArea {
  padding: 0 0 0 0 !important;
}
.UbaidSubCardActionArea .allTags {
  min-height: 62px;
}
.UbaidSubCardAvtar {
  min-width: 40px;
  max-width: 45px;
  max-height: 45px;
  min-height: 40px;
  margin-right: 5px;
  display: flex;
  overflow: hidden;
}
.UbaidSubCardAvtar img {
  width: 100%;
  display: block;
  margin: auto;
}
.UbaidSubCardCompanyName {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
  flex-grow: 100;
  overflow: hidden;
  margin: 6px 0 0 6px;
  word-break: break-all;
  line-height: normal;
}
.UbaidSubCardCompanyName div {
  height: 20px;
  overflow: hidden;
}
.UBaidSubCardSubtitle {
  font-weight: normal;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.54);
  height: 20px;
}
.UbaidCardRight {
  width: 2.5em; /* Equal width and height to form a perfect circle */
  height: 2.5em;
  background: linear-gradient(135deg, #5e17eb 0%, #3e0f9e 100%);
  color: #fff;
  padding: 0.5em;
  border-radius: 50%; /* Makes it a circle */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.UbaidCardRight:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
  background: linear-gradient(135deg, #c49733 0%, #3e0f9e 100%);
}
.CardFitScore{
  font-size: 14px;
  font-weight: bold;
}
.UbaidCardActions {
  display: flex;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  line-height: normal;
  font-size: 12px;
}
.UbaidCardActions .material-icons {
  font-size: 30px;
  background: inherit !important;
  margin: 0 3px 0 0em;
  cursor: pointer;
  color: #F4C430;
}
.UbaidCardActions .material-icons:hover {
  color: #eee !important;
}
.UbaidCardActions span {
  font-size: 24px;
}

