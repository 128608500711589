@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@800&family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Century Gothic", sans-serif !important;
  font-weight: 700;
}
