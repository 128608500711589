
.post-item, .feed-item {
  display: block;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.16), 0 1px 4px 0 rgba(0,0,0,.12) !important;
}
.post-item {
  box-shadow: none !important;
  background: #f7f7f7 !important;
}

.post-item-input {
  margin-left: 60px;
}

.post-item-input textarea {
  border: 1px solid #ddd;
  width: 100%;
  border-radius: 5px;
  height: 100px;
  padding: 10px;
}

.post-item-content {
  position: relative;
}

.post-item-actions {
  text-align: right;
}

.post-item-actions button {
  float: right;
  border-radius: 5px;
}

.feed-item-header {
  position: relative;
  padding-bottom: 15px;
}
.post-item-avatar, .feed-item-avatar {
  position: absolute;
  left: 0;
  border-radius: 50px;
}
.feed-item-user, .feed-item-timestamp {
  margin-left: 60px;
}
.feed-item-body {
  padding: 15px 0;
}
.feed-item-footer {
  border-top: 1px solid #eee;
  padding-top: 15px;
}
.post-item-actions .fa, .feed-item-actions .fa {
  font-size: 18px;
  color: #999;
  cursor: pointer;
  border-radius: 40px;
  margin: 0 7px;
  padding: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background: #f0f2ff;

}
.feed-item-actions {
  color: #999;
}
.feed-item-meta {
  font-size: 10px;
  color: #999;
}
.feed-item-like-summary img {
  border-radius: 25px;
  margin-right: -5px;
  border: 2px solid white;
  margin-bottom: 2px;
}
