.ubaidPopupActions a{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    background: #fff;
    font-size: 20px;
    padding: 36px 0;
    flex-grow: 100;
    margin: 12px;
    color: #6495ED;
}
.ubaidPopupActions{
    display: flex;
    margin: 16px 0;
}
.ubaidPopUpModel form{
    padding: 16px;
}
@media only screen and (max-width: 1150px){
    .ubaidPopUpModel form{
        padding: 6px;
    }
    .ubaidPopUpModel h1{
        font-size: 32px;
        margin: 12px 0 14px 0;
    }
    .ubaidPopUpModel h3{
        font-size: 26px;
    }
    .ubaidPopupActions a{
        padding: 24px;
    }
}
@media only screen and (max-width: 800px){
    .ubaidPopUpModel h1{
        font-size: 30px;
        font-weight: 400;
    }
    .ubaidPopUpModel h3{
        font-size: 24px;
    }
    .ubaidPopupActions a{
        padding: 15px;
        font-size: 18px;
    }
}
@media only screen and (max-width: 600px){
    .ubaidPopUpModel h1{
        font-size: 24px;
        margin: 10px 0;
    }
    .ubaidPopUpModel h3{
        font-size: 20px;
    }
    .ubaidPopupActions a{
        padding: 15px;
        font-size: 16px;
    }
    .ubaidPopUpModel form{
        padding: 0px;
    }
}
@media only screen and (max-width: 350px){
    .ubaidPopUpModel h1{
        font-size: 20px;
        margin: 8px 0 10px 0;
    }
    .ubaidPopUpModel h3{
        font-size: 18px;
    }
    .ubaidPopupActions a{
        padding: 12px;
        font-size: 15px;
        margin: 8px;
    }
    .ubaidPopUpModel form{
        padding: 0px;
    }
}