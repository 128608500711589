.__review-card {
  background-color: #fff;
  margin: 10px;

  .tearsheet {
    display: none;
  }

  .__start-ratings {
    background-color: #eeeeee;
    padding: 2vh;
  }

  .__model {
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    position: absolute;
    left: 0;
    top: 0;

    z-index: 888;
  }

  .__pb_deal-card {
    width: 95%;
  }

  .__pb_deal-profile {
    width: 70%;
  }

  .no-scroll {
    overflow: hidden;
    height: 100%;
  }

  .__main {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1021;
    max-width: 90% !important;
    max-width: 1180px;
    overflow: hidden;
    background: #fff;
    border-radius: 6px;
    padding: 25px 40px;
    animation: 0.6s forwards ReviewCard;

    .__deal_page_header {
      display: flex;
      justify-content: flex-start;
      min-height: 86px;
      padding: 0 1em 0 0px;
      border-bottom: 1px solid #eee;
    }

    .__deal_page_header .__header-left,
    .__deal_page_header .__header-middle,
    .__deal_page_header {
      display: flex;
      align-items: start;
    }

    .__header-left {
      width: 25%;
      overflow: hidden;
    }

    .__header-left .__company-image {
      margin-left: 15px;
      width: 65px;
      border-radius: 50%;
      background: #fff;
    }

    .__header-left .__company-image > div {
      height: 65px;
      background-size: cover;
      border-radius: 50%;
    }

    .__header-left .__company-info {
      font-weight: 400;
      margin: 0;
      padding: 0 1em;
    }

    .__header-left .__company-info a {
      color: #32325d;
    }

    .__header-left .__company-info a:hover {
      color: #46466d;
    }

    .__header-middle {
      flex-grow: 1;
      margin: 0;
      border-radius: 10px;
      padding: 0 1em;
    }

    .progress-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .progress-steps {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: 800px;
      border: 1px solid #e5e7eb;
      border-radius: 0.5rem;
      overflow: hidden;
      background-color: #fff;

      .progress-step {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 2rem 0.5rem 2rem;
        position: relative;
        text-align: center;
        border-right: 1px solid #e5e7eb;
        background-color: #f9fafb;
        transition: background-color 0.3s;

        &:last-child {
          border-right: none;

          &:after {
            display: none;
          }
        }

        &:first-child {
          &:before {
            display: none;
          }
        }

        &.active {
          background-color: #c49733;
          font-weight: bold;

          &:before {
            content: "▶";
            position: absolute;
            left: -0.3rem;
            top: 30%;
            z-index: 2;
            color: #f9fafb;
            font-size: 1.5rem;
            line-height: 1;
          }

          &:after {
            content: "▶";
            position: absolute;
            right: -1.1rem;
            top: 30%;
            z-index: 2;
            color: #f9fafb;
            font-size: 1.5rem;
            line-height: 1;
          }

          .step-label {
            color: #fff;
          }

          &:after {
            color: #c49733;
          }

          &:before {
            color: #f9fafb;
          }
        }

        .step-label {
          display: block;
          text-align: center;
          color: #6b7280;
          white-space: nowrap;

          strong {
            display: block;
            font-weight: 600;
          }
        }
      }
    }

    > div {
      max-height: 80vh;
      overflow: auto;

      &::-webkit-scrollbar-thumb {
        background: #9e9e9e;
      }

      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 6px;
      }
    }
    img,
    iframe {
      max-width: 100%;
    }
    .__company-image-wrapper {
      width: 160px;
      border-radius: 50%;
      margin: auto;
      overflow: hidden;
      padding: 0.8em;
      box-shadow:
        0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
      margin-top: -80px;
      position: relative;
      background: #fff;
      > div {
        height: calc(160px - 1.6em);
        background-size: cover;
        border-radius: 50%;
      }
    }
    @media (max-width: 350px) {
      .__company-image-wrapper {
        padding: 0.6em;
      }
      padding: 0.6em;
    }
  }

  .__company-name {
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
    padding-top: 1em;
    padding-bottom: 0.5em;
    //border-bottom: 1px solid rgba(0,0,0,.12);
    a {
      color: #32325d;
      &:hover {
        color: #46466d;
      }
    }
  }
  .__rating {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45vh;
    .__text-reviews {
      margin: 0.1em 0 0 4px;
    }
    .__title {
      font-size: 20px;
    }

    .__graphical-reviews {
      display: flex;
      align-items: center;

      > span {
        width: 4em;
        text-align: right;
        font-size: 15px;
      }
    }
    .overallscore {
      display: flex !important;
      justify-content: center;
      font-size: 30px;
    }
  }
  .__total-ratings {
    margin: 2em 0;
  }
  .__submit-review-btn {
    margin-top: 10px;
    margin-bottom: 30px;
    display: block;
  }
  .__write-review-button {
    border: 1px solid;
    display: flex;
    align-items: center;
    margin: 2em auto 0.4em auto;
    padding: 0.6em 0.6em 0.7em 0.6em;
    width: 220px;
    justify-content: center;
    &:hover {
      cursor: pointer;
      color: #5e72e4;
    }
    > i {
      margin-right: 0.6em;
    }
  }
}

.rating_comment {
  text-align: center;
}

@media (max-width: 991px) {
  .__review-card {
    .__main {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .__review-card .__main > div {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .__review-card .__main {
    max-width: 95% !important;
  }
  .__review-card .__main > div {
    padding-left: 0;
    padding-right: 0;
  }
}

@keyframes ReviewCard {
  0% {
    opacity: 0;
    top: calc(50% + 32px);
  }
  100% {
    opacity: 1;
    top: 50%;
  }
}

.info_review_modal_inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}
.info_review_modal_inner .__content {
  flex-wrap: wrap;
  text-align: center;
}
.__review-card .__main {
  max-width: 900px !important;
  width: 100%;
}
.info_review_modal_inner .__content > div {
  width: 100%;
}
.info_review_modal_inner .__content > div > div:first-child {
  font-weight: bold;
}
.__review-card .info_review_modal_inner .__rating {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.gradient-text {
  /* Define the gradient colors */
  background: linear-gradient(to right, #0047ab, #ff334b);

  /* Makes the text transparent so the background is visible */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  /* Optional: Additional styling to enhance the appearance */
  font-weight: bold;
  justify-content: center;
}

@media (max-width: 767px) {
  .info_review_modal_inner {
    display: block;
  }
  .info_review_modal_inner .__content {
    flex-wrap: wrap;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .info_review_modal_inner .container .row.justify-content-md-center {
    justify-content: center;
    flex-direction: column;
  }
  .info_review_modal_inner .container {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px 30px !important;
    width: 100%;
  }
}

.__review-card .__main {
  padding-left: 0;
  max-width: 100% !important;
  overflow-y: hidden;
  height: 100%;
}
.info_review_card_left {
  flex: 0 0 25%;
  max-width: 25%;
}
.info_review_modal_inner .info_review_card_middle {
  flex: 0 0 50%;
  margin: 0;
}
.info_review_modal_inner .info_review_card_right {
  flex: 0 0 25%;
  max-width: 25%;

  .documents {
    border: 1px dashed blue;
    border-radius: 10px;
    margin-bottom: 5px;
  }
}
.info_review_modal_inner {
  flex-direction: row;
}
ul.r_tab_nav {
  width: max-content;
  list-style: none;
  padding: 0;
}
ul.r_tab_nav li button {
  color: black;
  display: block;
  padding: 10px 50px;
  margin-bottom: 10px;
  text-align: left;
  outline: none;
  background: transparent;
  border: 0;
  font-weight: 400;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}
ul.r_tab_nav li button.active {
  width: 100%;
  background-color: rgba(196, 151, 51, 0.15);
  font-weight: 500;
  border-left: #c49733 5px solid;
}
.r_tab_content {
  display: none;
  text-align: left;
  height: 900px;
}
.r_tab_content.active {
  .download-tear-sheet {
    width: 100%;
  }
}
.faq_item h4 {
  font-size: 16px;
  margin: 0 0 5px;
}
.faq_item {
  margin: 0 0 25px;
}
// form.r_faq_form {
//     background: #eee;
//     padding: 15px;
//     border-radius: 10px;
//     margin: 0 0 20px;
// }
.r_tab_content h4 {
  font-size: 20px;
  margin: 0 0 0px;
}
form.r_faq_form input,
form.r_faq_form .faq_answer {
  width: 100%;
  // border: 1px solid #ddd;
  // margin: 0 0 15px;
  box-shadow: none !important;
  // padding: 9px 15px;
  outline: none !important;
  // border-radius: 4px!important;
  overflow: hidden;
  display: block;
  color: #000;
}
// form.r_faq_form .faq_answer {
//     background: #fff;
//     height: 70vh;
//     display: flex;
//     flex-direction: column;

// }
/* DEMO-SPECIFIC STYLES */
.typewriter span {
  color: #000;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  animation: typing 30s steps(30, end);
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

form.r_faq_form button {
  display: block;
  padding: 5px 30px;
  height: 40px;
  border: 0;
  background: #c49733;
  border-radius: 4px;
  color: #fff;
}
.r_faq_item {
  padding: 20px 0;
}
.r_faq_item:first-child {
  padding-top: 0;
}
.r_faq_item h5 {
  font-size: 16px;
}
.f_faq_item_action {
  display: flex;
}
.f_faq_item_action button {
  border: 0;
  background: #eee;
  margin: 0 10px 0 0;
  display: block;
  padding: 2px 10px;
  border-radius: 4px;
  transition: all ease 0.4s;
}
.f_faq_item_action button:hover {
  background: #000;
  color: #fff;
}

.edit-btn {
  display: block;
  padding: 5px 30px;
  height: 10px;
  border: 0;
  background: #c49733;
  border-radius: 4px;
  color: #fff;
  margin: 0 0 0 10px;
}
.r_faq_question {
  display: flex;
  align-items: flex-start;
}
.r_faq_question a.btn {
  padding: 10px 15px;
  background: #c49733;
  height: 40px;
  display: block;
  margin: 0 0 0 10px;
  border-radius: 4px;
  box-shadow: none;
  transform: none;
}

@media (max-width: 767px) {
  .info_review_card_left {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
  }
  .info_review_card_left .row {
    margin: 0 !important;
    flex-direction: column;
  }
  .info_review_modal_inner .info_review_card_middle {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .info_review_modal_inner .info_review_card_right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
textarea#sum {
  width: 100%;
  appearance: none;
  border-color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  height: max-content;
  height: 100% !important;
}
.__status-dropdown.stag-popup-deail h6 {
  border-bottom: 1px solid #333;
  /* border-radius: 50px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  padding: 0px;
  font-weight: 600;
  cursor: pointer;
  color: #c49733;
  /* text-decoration: underline; */
  padding-bottom: 6px;
}
.__status-dropdown.stag-popup-deail {
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.Pitchdeck-wraper p a {
  background: red;
  color: #fff;
  padding: 10px 25px;
  border-radius: 25px;
  font-weight: 700;
}
.Pitchdeck-wraper h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 12px;
}
.Pitchdeck-wraper {
  text-align: center;
}
.Pitchdeck-wraper p {
  margin-bottom: 0px;
  margin-top: 20px;
  margin-bottom: 15px;
}
.update-tear-sheet button {
  border: 0px;
  outline: none;
  background: red;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}
.update-tear-sheet {
  text-align: end;
  margin-right: 51px;
  margin-bottom: 10px;
}
textarea {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 100%;
}
.human-capital input {
  width: auto !important;
  margin-left: 10px;
}
.update-tearsheet-text {
  margin-left: 80px;
  margin-top: 10px;
}
.update-tearsheet-text p {
  font-weight: 600;
  font-size: 16px;
  color: red;
}
.r_faq_question {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 30px;
  padding-left: 10px;
  min-height: 75px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.r_faq_question .input-field {
  flex: 1 1;
  padding: 10px;
  // border: 1px solid #000;

  border-radius: 5px;
  margin-right: 10px;
  height: 65px;
  border-radius: 24px !important;
  padding-left: 23px;
}

.r_faq_question .input-field::placeholder {
  color: #000000;
  font-size: 18px;
}

.r_faq_question .btn {
  width: 43px;
  height: 43px;
  color: #fff !important;
  border: none;
  border-radius: 5px;
  padding: 6px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.r_faq_question .btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  position: absolute;
  right: 24px;
  z-index: 3;
}
.r_faq_question .btn {
  position: absolute;
  right: 24px;
  top: 11px;
  z-index: 3;
}

.r_faq_question .fa-arrow-right {
  font-size: 20px;
}
.tabchatgpt-main {
  height: 74vh;
  padding-bottom: 27px;
}
.r_faq_form {
  height: 100%;
}
.form-chatgpt-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  overflow-y: auto;
}
.review_tabs_wrap {
  height: 100%;
}
.question-main-wraper-m-u {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #c49733;
  color: #fff;
  margin-right: 12px;
}
.question-main-wraper-m-u p {
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 700;
}
.question-main-wraper-m {
  display: flex;
  align-items: flex-start;
}
.question-main-wraper-m-t p {
  margin-bottom: 0px;
}
.answer-main-wraper-u {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #c49733;
  color: #333;
  padding: 7px;
  margin-right: 12px;
}
.answer-main-wraper-a {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
}
.answer-main-wraper-t input {
  padding: 0px !important;
}
.answer-main-wraper-t {
  width: 100%;
}
.delete-question-m p {
  color: #9b9b9b;
  margin-top: -9px;
  cursor: pointer;
  width: fit-content;
}
form.r_faq_form input,
form.r_faq_form .faq_answer {
  overflow-y: auto;
  height: 100%;
}
/* Define the scrollbar styles */
/* For Webkit browsers (Chrome, Safari) */
form.r_faq_form .faq_answer::-webkit-scrollbar,
form.r_faq_form .faq_answer::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

form.r_faq_form .faq_answer::-webkit-scrollbar-track,
form.r_faq_form .faq_answer::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

form.r_faq_form .faq_answer::-webkit-scrollbar-thumb,
form.r_faq_form .faq_answer::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
}

form.r_faq_form .faq_answer::-webkit-scrollbar-thumb:hover,
form.r_faq_form .faq_answer::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}
