.UBaidEditOppMain {
  position: absolute;
  width: 60px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background: #43a047;
  height: 60px;
  text-align: center;
  display: flex;
  border-radius: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .12);
  margin-top: 10px;
  right: 15%;
  font-size: 25px;

}

.opp_header {
  padding: 0px 15% 0px 15%;
}

.opp-section {
  padding-top: 0vh;
}

.opportunity-profile-pic {
  width: 100%;
  top: 0px;
  height: 75px;
}

.opportunity-avatar {
  top: 0px;
  position: relative;
}

#buttons-grid-opp-button {
  color: #ffffff;
}

#buttons-grid-opp-button:hover {
  color: #ffffff;
}

.UBaidEditOpp {
  color: #fff;
  cursor: pointer;
  width: auto !important;
  margin: auto !important;
  padding: 0 !important;
}

.OppNameUBaidMain {}

@media only screen and (max-width: 800px) {
  .UBaidEditOppMain {
    top: 5px;
    right: -19px;
  }
}

@media only screen and (max-width: 450px) {
  .UBaidEditOppMain {
    margin-top: 75px;
    right: 5px;
    height: 48px;
    width: 48px;
    font-size: 20px;
  }

  #opportunity-profile-pic {
    width: 100%;
    top: -100px;
    height: 75px;
  }

  #opportunity-avatar {
    margin: auto;
    position: relative;
  }

  .cardmain {
    margin-left: unset;
    width: unset;
    text-align: center;
  }

  #buttons-grid {
    float: unset !important;
    display: unset !important;
  }

  #buttons-grid-opp-button {
    float: unset !important;
  }

  #opp-buttons {
    width: 60vw;
    margin: auto;
    text-align: center;
  }
}

#buttons-grid {
  float: right;
  display: contents;
}

#buttons-grid-opp-button {
  float: right;
}

#opp-buttons {
  width: 100%
}

#linkedIn-social-oppheader {}

#linkedIn-box-oppheader {}

@media only screen and (min-width: 1000px) {
  #linkedIn-social-oppheader {
    width: 30px !important;
    height: 30px !important;
  }

  #linkedIn-box-oppheader {}
}

@media only screen and (max-width: 800px) {
  #linkedIn-social-oppheader {
    width: 15px !important;
    height: 15px !important;
  }

  #linkedIn-box-oppheader {}
}

.main-oppo-new-design-wraper {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
}

.left-side-content-oppo {
  width: 50%;
  background-color: #96afc2;
  height: auto;
  align-items: center;
  display: flex;
}

.right-side-content-oppo {
  width: 100%;
}

.right-side-content-oppo .Header-media-25 {
  height: 500px;
  object-fit: cover;
  max-height: 500px;
}

.left-side-content-oppo .Header-avatar-26 {
  margin-top: 0px !important;
  position: relative;
  top: 0;
}

.profile-img-oppo {
  display: flex;
  align-items: center;
}

.profile-img-oppo img {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  margin-right: 26px;
}

p.type-oppo-new {
  border-radius: 10px;
  border: 1px solid #33333361;
  padding: 10px 25px;
  font-weight: 500;
  font-size: 17px;
  width: fit-content;
}

.oppo-location-wraper a {
  color: #000336;
  font-size: 17px;
  font-weight: 600;
}

.oppo-location-wraper p {
  margin-bottom: 0px;
}

.oppo-location-wraper {
  display: flex;
  justify-content: space-between;
}

.oppo-location-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.oppo-location-wraper #buttons-grid-opp-button {
  float: right;
  background: #DA1C24;
  border: 0px;
  padding: 10px 25px;
  margin-left: 30px;
  font-size: 14px;
  width: fit-content!important;
}
/* .button-apply-oppo{
  width: 100%;
} */

.oppo-text-left h4 {
  font-size: 56px;
  font-weight: 400 !important;
  margin-bottom: 20px;
}

.main-oppo-new-design-wraper .opp_header {
  padding: 0px 4% 0px 10%;
}

.Ubaid_Opp_Page {
  background-color: rgb(238, 238, 238);
}


.main-right-side-oppo-new {
  background: #fff;
  border-radius: 10px;
  padding-bottom: 10px;
}

.OppBodyUBaid .MuiTable-root {
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  background: #fff;
  padding: 25px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.Ubaid_Opp_Page .MuiTableCell-root {
  border-bottom: 0px;
  font-size: 32px;
}

@media (max-width:500px) {
  .OppBodyUBaid .MuiTable-root {
    margin-bottom: 15px;
  }
  .UBaidEditOppMain {
    margin-top: 0px!important;
}
  .main-oppo-new-design-wraper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column-reverse;
  }
  .right-side-content-oppo .Header-media-25 {
    height: 300px;
    max-height: 300px;
  }
  .right-side-content-oppo {
    width: 100%;
  }
  .left-side-content-oppo {
    width: 100%;
}
.oppo-text-left h4 {
  font-size: 27px;
  margin-bottom: 0px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.main-oppo-new-design-wraper .opp_header {
  padding: 10px 15px;
}
.company-oppo-tabs-design {
  width: 93%!important;
  padding: 5px!important;
  margin-top: 15px!important;
}
.MuiBottomNavigationAction-root {
  padding: 6px 10px 8px!important;
}
.profile-img-oppo img {
  margin-right: 15px;
  width: 135px;
  height: 142px;
  object-fit: cover;
}
.profile-img-oppo {
  display: flex;
  align-items: flex-start;
}
.oppo-location-wraper #buttons-grid-opp-button {
  margin-left: -12px;
  width: fit-content!important;
  padding: 10px!important;
}
.oppo-location-wraper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.company-oppo-tabs-design span.MuiBottomNavigationAction-label {
  padding: 2px 10px!important;
  font-size: 16px;
  text-align: left;
}
.company-oppo-tabs-design  button.MuiButtonBase-root.MuiBottomNavigationAction-root.invest-profile-page {
  height: 47px!important;
}
.oppo-border-row {
  padding-top: 0px!important;
  padding-bottom: 0px!important;
  margin-bottom: -25px!important;
}
.company-start-close {
  padding: 20px 15px!important;
  margin-top: 12px!important;
  width: 100%!important;
}
.OppBodyUBaid {
  padding: 15px 15px!important;
}
.Description-border hr {
  border-top: 5px solid #000339!important;
  width: 65px!important;
  margin-bottom: 16px!important;
}
.Description-border.oppo-border-row h4 {
  font-size: 20px;
  margin-bottom: 0px;
}

#specialColumn {
  padding-left: inherit!important;
  padding: 25px 15px!important;
  padding-bottom: 0px!important;
}
.right-side-opp-top p {
  font-size: 22px!important;
  padding:25x 15x !important;
  margin-bottom: 2px!important;
}
.OppBodyUBaid .MuiGrid-direction-xs-column {
  margin-right: 0px!important;
}
p.type-oppo-new {
  padding: 10px 13px;
  font-size: 14px;
}

}
.btn-close-popup-portel .close > span:not(.sr-only) {
  color: rgb(0 0 0);
  font-size: 2.25rem;
}
.btn-close-popup-portel-01 .close > span:not(.sr-only) {
  margin: -4px;
  color: #fff;
  line-height: 0;
  width: 1.25rem;
  font-size: 2.25rem;
  display: block;
  box-shadow: none;
  opacity: 1;
  text-shadow: none;
}
.btn-close-popup-portel-01  button{
  opacity: 1;
}