.CommunityLinks{
    background: #fff;
    box-shadow: 0 0 34px 0 rgba(63,66,87,.1);
    border: 1px solid #e6ecf5;
    border-radius: 4px;
    display: flex;
    padding: 0 16px;
}
.OlypicsCardTitle{
    font-weight: bold;
    display: inline-block;
    font-size: 14px;
}
.OlypicsBorder{
    margin: 14px 0;
    max-width: 14px;
    border-bottom: 2px solid #ff5e3a;
    transition: linear 0.2s all;
}
.OlypicsCardTitle:hover .OlypicsBorder{
    max-width: 100px;
}
.CommunityLinksCards{
    padding: 20px;
    width: 150px;
}
.lastOlympusLink{
    padding-bottom: 0 !important;
}
@media only screen and (max-width: 1150px){
    .CommunityLinks{
        padding: 0;
    }
    .CommunityLinksCards{
        padding: 16px;
        width: 142px;
    }
}
@media only screen and (max-width: 1000px){
    .CommunityLinks{
        display: block;
        box-shadow: none;
        border: none;
    }
    .CommunityLinksCards{
        padding: 20px 32px;
        width: auto;
    }
}