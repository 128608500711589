
/* *,
*:before,
*:after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background: #f9f9f9;
  font-size: 16px;
} */



 /* *************************profile-picture-overlay*************************** */
a{
  color: inherit;
  text-decoration: none;
}


.profilePic-container{
/* background: black;  */
 background-size: cover;
  background-position: center center;
  position: relative;
  margin: auto;
  width: 180px;
  height: 180px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;

}
  .profilePic-container:hover .profilePic-overlay{
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  .profilePic-container:hover .profilePic-span{
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: transform .5s ease, opacity 1.3s ease;
    transition: transform .5s ease, opacity 1.3s ease;
  }

  .profilePic-overlay{
    background: rgba(0,0,0,0.4);
    position: absolute;
    margin: auto;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    opacity: 0;
    -webkit-transition: .2s ease;
    transition: .2s ease;
  }
  .profilePic-span{
    color: #fff;
    text-align: center;
    position: absolute;
    margin: auto;
    height: 30px;
    line-height: 30px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: transform .5s ease, opacity .7s ease;
    transition: transform .5s ease, opacity .7s ease;
  }



.fileInput {
  border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
}
.imgPreview {
  text-align: center;
  margin: 5px 15px;
  height: 200px;
  width: 500px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray;
}
  .imPreview img {
    width: 100%;
    height: 100%;
  }
.previewText{
  width: 100%;
  margin-top: 20px;
}
.submitButton{
  padding: 12px;
  margin-left: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
}
.centerText {
  text-align: center;
  width: 500px;
}
   /* *************************profile-picture-overlay-end*************************** */
/* .email-rejection-btn button{
  background-color:blue ;
} */