.SocialProfile{
    background-color: #238ced;
    color: #fff;
    padding: 24px;
    border-radius: 3px;
    background-size: cover;
    text-align: center;
    margin-bottom: 16px;
}
.SocialProfile .profileImage {
  border-radius: 60px;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  margin-bottom: 15px;
  border: 5px solid rgb(56, 169, 255, 0.8);
}
.SocialProfile h5{
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 2px;
}
.SocialProfile span{
    font-size: 11px;
    font-weight: normal
}
.FollowerCounts{
  display: flex;
  font-size: 10px;
  margin-top: 10px;
}
.FollowerCounts > a {
  display: block;
  background: rgb(56, 169, 255, 0.4);
  flex: 1 1 150px;
  padding: 5px;
  margin: 10px;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  color: white;
}
.FollowerCounts > a:hover {
  background: rgb(56, 169, 255, 0.8);
}
.FollowerCounts > a > span {
  display: block;
  font-size: 10px;
  opacity: 0.7;
  color: white;
}
