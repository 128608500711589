.LatesOlympusNews{
    background: none;
}
.latestNewsHeader{
    height: 37vh;
    width: 100%;
    background-size: 100%;
}
.OlympusNewsCard{
    text-align: left;
}
.OlympusReviewCard{
    border-radius: 4px;
    overflow: hidden;
}
.labelNewsOlympus{
    font-size: 9px !important;
    background: #ff5e3a;
    padding: 4px 9px;
    margin: 16px 0;
    color: #fff !important;
    display: inline-block !important;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 3px;
}
.LatesOlympusNews a{
    font-size: 24px;
    display: block;
    color: #515365;
}
.LatesOlympusNews a:hover{
    cursor: pointer;
    color: #ff5e3a !important;
}
.LatesOlympusNews .OlympusReviewCard p{
    margin: 14px 0;
    line-height: 20px;
    font-size: 14.8px;
    min-height: 260px;
}
.LatesOlympusNews .OlympusReviewCard span{
    color: #889da8;
    font-weight: normal;
}
.LatesOlympusNews .OlympusReviewCard span b{
    color: #515365;
}
.NewsLatesFooter{
    margin-top: auto;
    padding: 0 24px;
}
.NewsLatesFooterMain{
    border-top: 1px solid #e6ecf5;
    margin-top: 16px;
    padding: 16px 0;
    display: flex;
}
.NewsLatesFooterMain svg{
    width: 20px;
    margin: auto 6px auto auto;
    fill: #889da8;
}
.NewsLatesFooterMain img{
    margin: auto 0;
}
.commentslikes{
    display: flex;
    flex-grow: 100;
    margin: auto 0 auto 6px;
    font-size: 14px;
}
@media only screen and (max-width: 1150px){
    .commentslikes{
        font-size: 12.25px;
    }
    .LatesOlympusNews .OlympusReviewCard p{
        font-size: 12.25px;
        min-height: 160px;
    }
}