.__signup-form {
  background: #fff;
  border-radius: 8px;
  border-color: grey;
  border-width: 1px;
  width: 600px;
  padding: 20px 40px 40px 40px;
  .__error {
    color: #f44336;
    font-weight: bold;
    margin: 0.4em 0;
    font-size: 15px !important;
  }

  .__sf_company_registration {
    max-width: 80% !important;
    padding: 40px 40px 40px 40px;
  }
  .__signup-form-title {
    margin-bottom: 1.4em;
  }
  * {
    font-family: "Open sans";
  }
  input {
    box-shadow: none !important;
  }
  .__form-group {
    height: 73px;
  }
  .__term-and-conditions-field {
    position: relative;
    margin: -8px 0 -2px -5px;
    font-size: 14px;
    .__check-box {
      position: relative;
    }
  }
  button {
    outline: none !important;
  }
  .__validate-text {
    font-size: 12px;
    color: #f44336;
    margin-bottom: 15px;
    max-width: calc(100% - 32px);
    line-height: 1em;
  }

  .__login {
  }

  @media (max-width: 1800px) {
    width: 100%;

    .__signup-form-title {
      margin: 1.6em 0 0.4em 0;
      font-size: 20px;
    }

    .__signup-form {
      padding: 0px 20px 20px 20px;
    }
  }

  .buttons-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: stretch;

      button {
        margin-bottom: 1rem;
      }
    }
  }
}
