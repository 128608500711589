.signin_container {
  height: 100%;
  display: flex;
  background-color: white;
  overflow-y: auto; /* Allow scrolling when content overflows */
}

.signin_cover {
  width: 50%;
  height: 100%;
  position: sticky; /* Keep image fixed while scrolling */
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signin_cover img {
  width: 100%;
  height: 100%;
  object-fit: fit;
  position: relative;
  right: 0;
}

.main_signin {
  max-width: 90%;
  margin: auto;
}

.side_signin_form {
  max-width: 100%;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
}

.description {
  text-align: center;
}

.signin_form {
  max-width: 100% !important;
}

.error {
  color: red;
  font-size: 0.8em;
  display: block;
}

.form-control {
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.form-control:focus {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}

.otp-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.otp-content {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.otp-input-container {
  margin-bottom: 25px;
}

.otp-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.resend-btn {
  padding: 10px 20px;
  border: 1px solid #28a745;
  border-radius: 6px;
  background-color: white;
  color: #28a745;
  cursor: pointer;
  transition: all 0.3s ease;
}

.verify-buttons {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.resend-btn:hover:not(:disabled) {
  background-color: #28a745;
  color: white;
}

.resend-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.cancel-btn {
  padding: 10px 20px;
  border: 1px solid #6c757d;
  border-radius: 6px;
  background-color: #6c757d;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-btn:hover {
  background-color: #5a6268;
}

.verify-btn {
  padding: 10px 20px;
  border: 1px solid #0d6efd;
  border-radius: 6px;
  background-color: #0d6efd;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.verify-btn:hover {
  background-color: #0b5ed7;
}

@media (max-width: 1100px) {
  .signin_cover {
    display: none;
  }

  .main_signin {
    padding: 20px;
  }

  .side_signin_form {
    padding: 20px;
  }
}
