.ubaidForm {
  margin: 0 !important;
  padding: 0 !important;
}
.ubaidOppCards {
  margin-top: 12px;
  padding-right: 16px;
}
.ubaidExploreCards {
  padding-right: 0 !important;
}
.ubaidExploreCards .follow-loading {
  opacity: 0.5;
}

.filter_row {
  display: flex;
}

.UbaidLeftOppExp {
  width: 85% !important;
  padding: 0px !important;
  margin:16px 0px 0px 0px !important;
}

.UbaidRightFilter {
  width: 15%;
  top:8vh; 
}
.right_column {
  background-color: #ffffff;
  width: 15%;
  display: block !important;
  position: sticky;
  border-radius: 2px;
  top: 8vh;
  height: 10vh;
  padding: 16px !important;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
  margin: 16px 16px 12px 0px;
}

.ubaidFilterBoxMain {
  display: block !important;
  padding: 16px !important;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
  margin: 16px 0px 12px 16px;
  background-color: #ffffff;
}
.FilterBoxSearchForm {
  width: 210px;
}
.ubaidInputExPlore {
  width: auto !important;
  margin: 0 !important;
}
.FilterUbaidForm {
  width: 200px;
  margin: 0 20px;
  border-radius: 6px;
  flex-grow: 100;
}
.ubaidFooter {
  display: none;
}
.FilterUbaidBTN {
  width: auto;
  max-width: 120px;
}
.FilterUbaidBTNIn {
  margin: auto !important;
}
.UbaidFormMarginLr {
  margin-left: 8px;
  margin-right: 8px;
}
.UbaidFormMarginR {
  margin-right: 8px;
  margin-left: 8px;
}
.FilterBoxFormSearch {
  max-width: 230px !important;
}
@media only screen and (max-width: 1100px) {
  .ubaidFilterBoxMain {
    display: block !important;
    margin: 16px 16px 12px 16px;
    border-radius: 4px;
    padding: 20px 16px 12px 16px !important;
  }
  .FilterUbaidForm,
  .FilterUbaidBTN,
  .FilterBoxFormSearch {
    max-width: auto !important;
    margin: 0 !important;
    box-shadow: none;
    max-width: 100% !important;
    width: 100% !important;
    margin-bottom: 8px !important;
  }
  .FilterUbaidBTNIn {
    width: 100%;
  }
  .UbaidFormMarginLr,
  .UbaidFormMarginR {
    margin: 0;
  }
  .filter_row {
    display: block;
  }
  
  .UbaidLeftOppExp {
    width: 100% !important;
    padding: 0px !important;
    margin:16px 0px 0px 0px !important;
  }
  
  .UbaidRightFilter {
    width: 100%;
    top:8vh; 
  }
}
