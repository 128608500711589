.newsfeed .leftNavExpOppToo a{
    color: inherit !important;
}
.newsfeed .leftNavExpOppToo a:hover{
    color: #515363 !important;
}
.newsfeed .leftNavExpOppToo a:hover svg{
    fill: #FF5E3A;
}
.newsfeed .leftNavExpOppToo svg{
    display: inline-block;
    padding-bottom: 3px;
}
.newsfeed .leftNavExpOppToo .smallsvg svg{
    width: 20px;
}
.newsfeed .leftNavExpOppToo .topLinkNewsfeed {
    margin-top: -4px !important;
}
.NewsBigIcon{
    width: 26px !important;
}
.toggleHandlerNewsFeed{
    display: none;
}

@media only screen and (max-width:760px){
    .newsfeed .leftNavExpOppToo{
        display: block;
    }
}
@media only screen and (max-width:768px){
    .newsfeed .leftNavExpOppToo{
        z-index: 2;
        width: 270px;
        left: -270px;
    }
    .newsfeed .leftNavExpOppToo .secLogo{
        display: inline;
    }
    .toggleHandlerNewsFeed{
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        padding: 17px;
        background: #fff;
        animation: 0.6s alternate toggleHandlerNewsFeed;
        animation-delay: 300ms;
        z-index: 1;
    }
    .toggleHandlerNewsFeed img{
        width: 36px !important;
    }
    .navfullleft0{
        left: 0 !important;
    }
    .navfullleft0 .toggleHandlerNewsFeed{
        display: none;
        opacity: 0;
    }
    @keyframes toggleHandlerNewsFeed {
        5%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
}