.UbaidOppDesc{
     word-break: break-word;
    -webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}
.UBaidOppShouldApply{
    margin-bottom: auto !important;
}

.OppBodyUBaid{
    padding: 20px 15% 0px 15%;
}

.OppApplyButton{
    background-color: #c49733 !important;
    color: black !important;
    width: 20vh !important;
}

@media only screen and (max-width: 800px){
    .OppNameUBaidMain{
        padding-left: 12px;
    }
    .OppNameUBaid{
        font-size: 27px !important;
    }
}
@media only screen and (max-width: 700px){
    .OppNameUBaidMain{
        padding: 0;
        width: 100%;
        margin: auto;
    }
    .OppNameUBaid{
        font-size: 24px !important;
        margin: 6px 0 4px 0 !important;
    }
    .OppHeaderButtonsUbaid{
        margin-top: 12px !important;
    }
    .OppHeaderButtonsUbaid button, .UBaidOppWebsitButton{
        display: block !important;
        margin: 6px auto 0 auto !important;
        width: 220px;
    }
}
.oppo-border-row {
    text-align: left;
    padding-top: 35px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 0px;
}
@media (max-width: 600px) {
    .oppo-border-row {
        padding-top: 35px !important;
    }
}

@media (max-width: 600px) {
    .mobile-padding {
        padding: 40px 40px 20px 40px !important;
    }
}

.OppBodyUBaid .CustomizedTable-root-33 {
    padding: 30px 40px;
}
.OppBodyUBaid .MuiGrid-direction-xs-column {
    margin-right: 20px;
}
.right-side-oppo-body p {
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
}

#industry-subtitle {
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    padding-left: 24px;
}

.industry-padding-wrpaer{
    padding-left: 14px;
    padding-right: 20px;
    display: block;
}

@media (max-width: 600px) {
    .industry-padding-wrpaer{
        padding-left: 30px;
        padding-right: 20px;
        display: block;
    }
}

@media (max-width: 600px) {
    #industry-subtitle {
        margin-bottom: 7px;
        font-weight: 600;
        font-size: 18px;
        text-transform: capitalize;
        padding-left: 40px;
        padding-top: 28px;
    }
}

@media (max-width: 600px) {
   .opp-chips {
        padding-left: 16px;
        padding-top: 28px;
    }
}




.right-side-opp-top {
    text-align: left;
    width: 100%;
}
.right-side-opp-top p {
    font-size: 30px;
    font-weight: 600;
}
.main-right-side-oppo-new .MuiChip-sizeSmall {
    background: transparent;
    border: 1px solid #33333380;
    padding: 13px 3px;
}
.company-start-close {
    padding: 10px 20px 20px 40px;
    border-radius: 10px;

}

@media (max-width: 600px) {
    .company-start-close {
        padding: 10px 20px 20px 40px!important;
    }
}
.key-dates{
    color: #7B8087;
    font-size: 20px;
}
.key-dates {
    color: #7B8087;
    font-size: 22px;
    font-weight: 400;
}
.Applications-open {
    color: black;
    display: inline-block;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 0px;
    margin-top: 10px;
    margin-bottom: 5px;
}
.Applications-open-close {
    color: black;
    display: inline-block;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 0px;
    margin-top: 10px;
    margin-bottom: 5px;
}
p.Applications-open-date {
    border: 1px solid #5A6473E5;
    width: fit-content;
    text-align: center;
    border-radius: 10px;
    padding: 2px 10px;
    color: #5A6473E5;
}
p.Applications-open-close-date{
    border: 1px solid #5A6473E5;
    width: fit-content;
    text-align: center;
    border-radius: 10px;
    padding: 2px 10px;
    color: #5A6473E5;
}
.Ubaid_Opp_Page {
    background-color: rgb(238, 238, 238);
  }
   /* .MuiPaper-root{
    background-color: transparent;
  } */
   .CustomizedTable-columnOne-40 {
    background: #fff;
    padding: 25px 20px;
    border-radius: 10px;
  }
   .MuiTable-root {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    background: #fff;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
.body-padding-wrpaer {
    padding-left: 20px;
    padding-right: 20px;
    display: block;
}

@media (max-width: 600px) {
    .body-padding-wrpaer {
        padding-left: 30px;
        padding-right: 20px;
        display: block;
    }
}

.chip-oppo-body{
    padding: 20px 20px !important;

}
.company-oppo-tabs-design {
    width: 70%;
    margin: 0 auto;
    background: #fff;
    padding: 6px;
    border-radius: 10px;
    margin-top: -35px;
    /* z-index: 1; */
    position: relative;
}
.company-oppo-tabs-design span.MuiBottomNavigationAction-label {
    padding: 10px;
    /* height: 47px; */
    font-size: 17px;
}
.company-oppo-tabs-design  button.MuiButtonBase-root.MuiBottomNavigationAction-root.invest-profile-page {
    background: linear-gradient(254.17deg, #000000 -7.34%, #C49733 41.96%, #C49733 63.34%, #000000 111.45%);
    color: #FFFFFFE5;
    text-transform: uppercase;
    border-radius: 9px;
    padding: 0px 13px;
    height: 55px;
}
.company-oppo-tabs-design .companyNavBarOverflow{
    height: 66px!important;
}
.Description-border hr {
    border-top: 7px solid #000339;
    width: 84px;
    text-align: left;
    margin: 0;
    margin-bottom: 22px;
    border-radius: 14px;
  }
  tbody.MuiTableBody-root.body-padding-wrpaer {
    padding-left: 20px!important;
    padding-right: 20px!important;
    padding-bottom: 10px;
    display: block;
}
.Description-border.oppo-border-row h4 {
    font-size: 25px;
    font-weight: 600;
}
.right-side-opp-top p {
    font-size: 23px;
    margin-bottom: 6px;
}
@media (max-width:500px) {
    tbody.MuiTableBody-root.body-padding-wrpaer {
        padding-left: 0px!important; 
         padding-right: 0px!important; 
    }
}