.OppServiceBox{
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}
.OppServiceBox h5{
    margin: 0 auto 0 10px;
}
.UbaidSortOpp{
    margin-left: auto;
}
.BottomNavigationActionUBaid{
    position: absolute;
    width: 500px;
    margin: auto;
}
.OppServiceBoxCard{
    margin: 0 16px;
}
.UBaidOppType{
    border: 1px solid salmon;
    max-width: 20vh;
    background-color: salmon;
    border-radius: 25px;
    color: #000; 
    text-transform: uppercase;
    font-weight:600 ;
    margin-left: 22.5vh;
}
.UbaidOppRoutCon{
    width: 80% !important;
    margin: auto;
    border: 2px solid;
}
@media only screen and (max-width: 1200px){
    .BottomNavigationActionUBaid{
        width: auto;
        flex-grow: 100;
        position: static;
    }
}
@media only screen and (max-width: 600px){
    .UBaidOppType{
        text-align: center;
        margin-left: 0px !important;
        max-width: 100%;
    }

    .OppServiceBox{
        display: block;
    }
    .OppServiceBox h5{
        margin: 20px 0 12px 0;
    }
    .UbaidSortByOpp{
        padding: 0 !important;
    }
    .UbaidSortOpp{
        width: 100%;
        padding: 10px 12px 14px 12px;
    }
    .UbaidSortOpp .UbaidSortFormControl{
        width: 100%;
        margin: 0 !important;
    }
    .ComOppExpStar{
        text-align: center;
    }
    .UBaidOppExpStar{
        text-align: center;
    }
}
@media only screen and (max-width: 380px){
    .UBaidOppType{
        text-align: center;
        margin: auto;
    }
}