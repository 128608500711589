.side-drawer {
    height: 100%;
    background: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.904);
    position: fixed;
    top : 0;
    left : 0;
    width: 70%;
    max-width: 400px;
    z-index : 200;
    transform: translateX(-100%);
    transition: transform 0.4s ease-out;

    @media only screen and (min-width: 768px) {
        .side-drawer {
            display : none;
        }
    }



    ul {
        height : 100%;
        list-style: none;
        display : flex;
        flex-direction: column ;
        justify-content: center;
        background :rgb(33, 25, 58);

    li {
        margin : .5rem 0;
  

    a {
        color : white;
        text-decoration: none;
        font-size : 1.2rem;
         }

        }

    }

    & a:hover,
    & a:active {

        color: red;
    }


    &.open {
        transform: translateX(0);

    }

}