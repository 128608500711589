.FAQsPitch .pitchBoxContainer{
    font-size: 16px;
    padding: 0 48px !important
}
.FAQsPitch h5{
    color: #FD762F;
    margin: 20px 0 4px 0 !important;
    font-family: "Trebuchet MS", sans-serif;
    font-size: 20px;
}
@media only screen and (max-width: 800px){
    .FAQsPitch h3{
        font-size: 22px;
    }
    .FAQsPitch .pitchBoxContainer{
        padding: 0 !important;
        width: 89%;
        font-size: 13px;
        line-height: 18px;
    }
    .FAQsPitch h5{
        font-size: 16px !important;
    }
}
@media only screen and (max-width:600px){
    .FAQsPitch .pitchBoxContainer{
        width: 310px;
    }
    .FAQsPitch h2{
        font-size: 20px;
    }
    .FAQsPitch h5{
        font-size: 18px !important;
    }
}