body {
  /* TODO: important is used because css order is messed up.
   * reboot.css is being loaded in middle of page */
  background-color: #F4F2EE !important;
}

.newsfeedPost{
    background: #fff;
    border-radius: 4px;
    margin-bottom: 16px;
}
.newsfeedPostHeader{
    background: #f7f9fc;
    display: flex;
    font-size: 12.5px;
}
.newsfeedPostHeader div{
    padding: 22px 20px;
    border-bottom: 1px solid rgb(154, 159, 191, 0.1);
    cursor: pointer;
}
.newsfeedPostHeader svg{
    width: 18px;
    margin-right: 10px;
    height: 20px;
}
.newsfeedPostHeader .centerPostNews{
    border: 1px solid rgb(154, 159, 191, 0.1);
    border-top: none;
}
.rigthPostNews{
    border-right: 1px solid rgb(154, 159, 191, 0.1);
}
.newsfeedPostHeader .activeBox{
    background: #fff;
    border-bottom: 1px solid #fff;
    color: #515363;
}
.newsfeedPostHeader .activeBox svg{
    fill: #FF5E3A;
}
.addPostNews{
    display: flex;
    padding: 4px 24px 16px 24px;
}
.addPostNews .profileImage{
    margin-top: 16px;
}
.addPostNews textarea{
    border: none;
    outline: none;
    box-shadow: none !important;
    display: block;
    width: 100%;
    cursor: auto;
    transition: linear 0.2s all;
    font-size: 15px;
}
.shareyouthinkNews{
    flex-grow: 100;
}
.newsFeedPostFooter{
    padding: 0 18px 24px;
    display: flex;
    justify-content: space-between;
}
.newsFeedPostFooter svg{
    margin-right: 36px;
}
.newsfeedIconsPost{
    margin: auto 0
}
.newsfeedPostBtn {
  margin: 0 0 0 auto;
}
.smallIconNews{
    width: 18px !important;
}
.SocialFeedTitleBox {
  background: #f6fbff;
  padding: 9px 21px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 16px;
  color: #9A9FBF;
}

.SocialFeedTitleBox .countBox {
  padding: 0px 12px;
  color: white;
  background: #b7d4ea;
  border-radius: 30px;
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
}
.SocialOnboarding {
  flex-grow: 1;
  margin-bottom: 100px;
}
.SocialOnboarding .simpleExploreCard {
  margin-bottom: 15px;
}
.SocialOnboarding .ctaButton {
  margin-left: 64px;
}

.fadeOverflow {
  overflow: hidden;
  line-height: 18px;
  position: relative;
  height: 54px; /* exactly 3 * 18px lines */
}
.fadeOverflow:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 18px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

@media only screen and (max-width: 768px){
  .SocialOnboarding {
    margin-top: 25px;
    margin-bottom: 100px;
  }
  .SocialOnboarding .ctaButton {
    margin-left: 0;
  }

  .fadeOverflow {
    height: 54px;
  }
}

.CenterBodyNewsFeed button{
    border: 1px solid rgb(154, 159, 191, 0.3);
    font-weight: bold;
    margin-left: 12px;
    padding: 12px 16px;
    border-radius: 4px;
    min-width: 100px;
    background: #fff;
    font-size: 11px;
}
.FollowingListFeed, .PostCardsNewsFeed{
    padding: 24px;
    background: #fff;
    margin-bottom: 16px;
    position: relative;
    border-radius: 4px;
    font-size: 14px;
}
.PostCardsNewsFeed a {
    color: #515363 !important;
    text-decoration: none;
    font-weight: bold;
}
.FollowingListFeed {
  display: flex;
  cursor: pointer;
}
.FollowingListFeed a, .FollowingListFeed a:hover {
  color: #9A9FBF !important;
}
.FollowingListFeed span {
  display: block;
}
.FollowingListFeed .mainContent {
  color: #69839a;
  font-weight: bold;
  font-size: 12px;
  flex-grow: 1;
  display: block;
}
.FollowingListFeed .small {
  color: #9A9FBF;
  font-size: 10px;
  display: block;
}
.FollowingListFeed .ctaBtn {
  text-decoration: none;
  padding: 3px 10px;
  background: #b7d4ea;
  color: white !important;
  border-radius: 5px;
  font-size: 11px;
  font-weight: normal;
  margin-top: 4px;
  display: block;
}
.FollowingListFeed .ctaBtn:hover {
  color: white !important;
  background: crimson;
}
.PostCardsNewsFeed .postFullImage{
    display: block;
    width: 100%;
}
.PostActionsNewsFeed{
    position: absolute;
    right: -16px;
}
.PostActionsNewsFeed a{
    display: block;
    height: 34px;
    width: 34px;
    border-radius: 32px;
    background: #9A9FBF;
    display: flex;
    margin-bottom: 6px;
}
.PostActionsNewsFeed svg{
    fill: #fff;
    width: 18px;
    margin: auto
}
.PostActionsNewsFeed a:hover{
    background: #FF5E3A;
}
.NewsFeedPost1{
    border: 1px solid rgb(154, 159, 191, 0.3);
}
.NewsFeedPost1 div{
    padding: 16px;
}
.NewsFeedPost1 a{
    display: block;
    font-size: 23px;
    font-weight: 300;
    margin: 0 0 8px 0;
}
.PostCardsNewsFeedFooterlast{
    padding: 16px;
    text-align: center;
    display: block;
    margin-top: 24px;
    border-top: 1px solid rgb(154, 159, 191, 0.3);
    border-bottom: 1px solid rgb(154, 159, 191, 0.3);
    margin: 24px -24px -24px -24px;
    font-size: 12px;
}
.AddCommentsNewsFeed{
    margin-top: 40px;
    display: flex;
}
.AddCommentsNewsFeed .mainContent {
  flex-grow: 1;
}
.AddCommentsNewsFeed textarea {
    border: none;
    display: block;
    transition: linear 0.2s all;
    box-shadow: none;
    width: 100%;
        padding: 10px;
            background: #f8f9fc;
}
.AddCommentsNewsFeed .ctaBtn {
  flex-shrink: 1;
}
.AddCommentsNewsFeed svg{
    margin-top: auto;
}
.CommentsButtonNews{
    text-align: right;
}
@media only screen and (max-width: 1150px){
    .newsfeedPostHeader{
        display: block;
    }
}
@media only screen and (max-width: 510px){
    .newsFeedPostFooter svg{
        margin-right: 16px;
        width: 20px
    }
    .smallIconNews{
        width: 17px !important
    }
    .newsfeedPost button{
        font-size: 11px;
        width: 85px;
        padding: 10px
    }
    .NewsFeedPost1{
        display: block !important
    }
    .NewsFeedPost1 img{
        width: 100%;
    }
    .PostActionsNewsFeed{
        right: 0;
        display: inline;
        top: -12px;
        margin-right: 16px;
    }
    .PostActionsNewsFeed a{
        display: inline-block;
        text-align: center;
        margin: 0 1px;
    }
    .PostActionsNewsFeed svg{
        margin: 2px 0;
        width: 16px
    }
    .PostCardsNewsFeed{
        font-size: 12px;
        margin-top: 18px;
    }
}
@media only screen and (max-width: 440px){
    .newsFeedPostFooter{
        display: block;
    }
    .newsFeedPostFooter div{
        display: flex;
        justify-content: space-between;
    }
    .newsfeedIconsPost svg{
        margin: 0 auto 10px auto
    }
    .CenterBodyNewsFeed button{
        margin: auto;
        padding: 8px
    }
    .CommentsButtonNews{
        display: flex;
    }
}
@media only screen and (max-width: 350px){
    .PostCardsNewsFeed{
        font-size: 10px;
    }
}
