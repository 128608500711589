.MainBodyOlympus {
  width: 92.6%;
  margin: auto;
  display: flex;
  padding: 96px 0px 96px 70px;
  justify-content: space-between;
}
.MainBodyOlympus img {
  max-width: 48.5%;
}
.MainBodyOlympus .detailsOlympus {
  width: 48.5%;
  margin-top: auto;
  margin-bottom: auto;
}
.OlympusOr {
  color: #ff5e3a;
}
.MainBodyOlympus p {
  font-size: 14px;
  margin: 0;
  line-height: 23px;
  font-weight: 400;
  color: #889da8;
}
.MainBodyOlympus h2 {
  margin-bottom: 30px;
}
.olypicsClients{
  background: url('../assets/bg-section2.png');
  padding-top: 36px;
}
.detailsOlympus .list-item-loympus{
  display: flex;
  font-size: 14px;
  margin-left: 24px;
  font-weight: 400;
  align-items: center;
}
.MainBodyOlympus i{
  padding: 0;
  display: inline;
  margin: 0;
}
.MainBodyOlympus .detailsOlympus i{
  margin: 12px 4px 12px 20px;
  color: #ff5e3a;
  font-size: 25px;
}
.olypicsClients .MainBodyOlympus{
  justify-content: space-around;
}
.MainBodyOlympus button{
  border: 2px solid #ff5e3a;
  margin-right: 6px;
}
.Olympus .OlympusWhiteBtn{
  background: #fff;
  color: #ff5e3a;
}
.MainBodyOlympus button i{
  margin: 0 !important;
  margin-right: 6px !important;
  font-size: 14px !important;
}
.MainBodyOlympus button{
  margin: 26px 6px 26px 0;
}
.OlympicsHello{
  background: url('../assets/bg-section2.png');
  background-size: cover;
}
.OlympicsHello .MainBodyOlympus{
  padding-top: 0;
  padding-bottom: 0;
}
.RocketOlympus{
  margin: 44px 0 0 auto;
}


.RocketOlympus img{
  position: relative;
  margin-right: 84px;
  top: 80px
}
.RocketFloating{
  animation: RocketFloating 0.5s forwards ease;
}
@keyframes RocketFloating{
  0%{
    top: 80px;
  }
  100%{
    top: 0;
  }
}
.lastFooterOlympus{
  text-align: center;
  margin-left: 70px;
  font-size: 12px;
}
.lastFooterOlympus span{
  color: #ff5e3a;
}
@media only screen and (max-width: 1400px){
  .MainBodyOlympus{
    width: 84.5%;
  }
}
@media only screen and (max-width: 1150px) {
  .MainBodyOlympus p {
    font-size: 12.5px;
    line-height: 18px;
  }
  .olypicsClients img{
    width: 13.277%;
  }
}
@media only screen and (max-width: 1024px) {
  .MainBodyOlympus {
    width: 90%;
  }
  .RocketOlympus button{
    width: 200px;
  }
  .RocketOlympus img{
    margin-right: 48px;
  }
}
@media only screen and (max-width: 768px) {
  .MainBodyOlympus {
    padding: 0;
    margin: 48px auto;
  }
  .MainBodyOlympus h2 {
    font-size: 26px;
  }
  .olypicsClients{
    padding-top: 0;
  }
  .olypicsClients img{
    width: 20%;
    display: inline-block;
    margin: 12px 10%;
  }
  .olypicsClients .MainBodyOlympus{
    display: block;
    text-align: center;
    padding: 46px 0;
  }
}
@media only screen and (max-width: 760px) {
  .olypicsClients img{
    margin: 24px auto !important;
    min-height: 30px;
    width: auto;
  }
  .olypicsClients .MainBodyOlympus{
      margin-top: 24px;
  }
  .MainBodyOlympus {
    width: 88%;
    display: block;
  }
  .MainBodyOlympus img{
    display: block;
    max-width: 100%;
    margin: auto;
  }
  .MainBodyOlympus .detailsOlympus{
    width: auto;
  }
  .MainBodyOlympus h2{
    font-size: 24px;
  }
  .RocketOlympus img{
    margin: 24px 0 96px auto;
  }
  .RocketOlympus button{
    width: 160px;
    font-size: 10px;
  }
}
