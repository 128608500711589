.OlympusLogoFooter img{
    width: 60px;
}
.OlympusLogoFooter{
    display: flex;
}
.OlympusLogoFooter p{
    margin: auto 12px;
}
.OlympusLogoFooter b{
    display: block;
}
.OlympusLogoFooterFoot{
    display: flex;
}
.OlympusLogoFooterFoot svg{
    width: 15px;
    margin: 0 24px 0 0;
    fill: #889da8;
}
.footerLinksOlympus{
    font-size: 12.5px;
    flex-grow: 100;
}
.footerLinksOlympus b{
    font-weight: bold;
    margin-bottom: 36px;
    display: block;
    font-size: 14px;
}
.footerLinksOlympus a{
    display: block;
    font-weight: 400;
    color: #889da8 !important;
    margin: 16px 0;
}
.footerLinksOlympus a:hover{
    color: #ff5e3a !important;
}
.OlympusLogoFooterMain{
    max-width: 360px;
    margin-right: 26px;
}
@media only screen and (max-width: 768px){
    .OlympusFooter{
        display: block;
    }
    .OlympusLogoFooterMain{
        max-width: 100% !important;
        width: 100% !important;
        margin-bottom: 36px;
    }
    .footerLinksOlympus b{
        margin: 32px 0 18px 0;
    }
}