.PitchboxStartup{
    font-size: 17px;
}
.PitchboxStartup *{
    text-align: center;
}
.belowHeaderPitch{
    padding-top: 24px;
    font-size: 18px;
    color: #30393A;
    background: #fff;
    margin: 0 8px;
}
.belowHeaderPitch img{
    margin: 0 12px;
    max-width: 110px;
}
.pitchBoxContainer{
    max-width: 940px;
    margin: auto;
    width: 96%;
}

@media only screen and (max-width: 600px){
    .belowHeaderPitch{
        padding: 24px 0;
        font-size: 14px;
    }
}
@media only screen and (max-width: 370px){
    .belowHeaderPitch img{
        margin: 0 6px;
        max-width: 90px;
    }
}
