.__pb-page{
    flex-grow: 1;
    .__title{
        font-weight: 400;
        display: flex;
        align-items: center;
        margin: 0;
        .material-icons{
            margin-left: 0.6em;
        }
    }
    .__csv-download{
        margin-right: 0.6em;
        color: #fff;
        font-size: 25px;
    }
    .__complanies-listed{
        display: flex;
        align-items: center;
        h5{
            min-width: 186px;
            margin: 0;
        }
    }
    .__application-room-profile-card-grid {
        
    }
}