

.serviceCard {
    width: 30%;
    // box-shadow: 0 1.5rem 4rem rgba(black, .15);
    background: #efeded ;

    margin-bottom: 2rem;

    @media only screen and (max-width: 56.25em){
        width: 45%;

    }

    @media only screen and (max-width: 45em){
        width: 75%;

    }

    h5 {
        font-size: 1rem;
    }

&__pic    {
    //FUNCTIONALITY
    perspective: 150rem;
    -moz-perspective: 150rem;
    position:relative;
    height: 20rem;


    
   

    &__side {
        font-size:1rem;
        position:absolute;
        left:0;
        top:0;
        width:100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: 3px;
    
        overflow: hidden;
        border-radius: 1%;
        height: 20rem;
        transition: all .9s ease;
        text-align: center;

        &--front {
  
        }

        &--back {
        
            transform : rotateY(180deg);

            &-1 {
                background-image: linear-gradient( to right bottom, rgba(243, 243, 243, 0.863), rgb(236, 236, 236));
            }

            &-2 {
                background-image: linear-gradient( to right bottom, black, white);
        }
            &-3 {
                background-image: linear-gradient( to right bottom, black, white);
        }
        }
    }
    // the front side is the only side that rotates, the back side stays the same
    &:hover &__side--front {
        transform : rotateY(-180deg);
    }
    &:hover &__side--back {
        transform : rotateY(0);
    }

    // FRONT SIDE STYLING
    &__picture {
        background-size: cover;
        height: 100%;
        background-blend-mode:color-dodge; 
        
        // -webkit-clip-path: polygon(0 0,100% 0,100% 85%,0 100%);
        // clip-path: polygon(0 0,100% 0,100% 85%,0 100%);

        @media only screen and (max-width: 56.25em),
        only screen and (hover : none ) {

            height: 3.5rem;

        }
       

        &--1 {
            background-image:linear-gradient(rgba(17, 7, 43, 0.925)), url('../../../../img/smart-watch-821557_640-1024x1024.jpg');
            
        }
        &--2 {
            background-image:linear-gradient(rgba(17, 7, 43, 0.925)), url('../../../../img/law-1991004_1920-1024x669.jpg');
        }
        &--3 {
  
            background-image:linear-gradient(rgba(17, 7, 43, 0.925)), url('../../../../img/pexels-photo-872957-1024x739.jpeg');
        }

        &--4 {

            background-image:linear-gradient(rgba(17, 7, 43, 0.925)), url('../../../../img/people-3370833_1920-1024x641.jpg');
        }
        &--5 {
            
            background-image:linear-gradient(rgba(17, 7, 43, 0.925)), url('../../../../img/pexels-photo-541523-1024x683.jpeg');
        }

        &--6 {

            background-image:linear-gradient(rgba(17, 7, 43, 0.925)), url('../../../../img/night-692261_1920-1024x675.jpg');
        }
    }

    &__heading {
        font-size: 2rem;
        font-weight: 300;
        text-transform: uppercase;
        color: white;
        text-align: center;
        // margin: 0 auto;
        top: 50%;
       left: 50%;

        position: absolute;
        transform : translate(-50%, -50%);

        // left: 0;
        width: 100%;
        // height: 100%;
    
        // justify-content: center;

        @media only screen and (max-width: 56.25em){
            top: 25%;
            left: 25%;
        transform : translate(-25%, -25%);
          
            font-size: 1rem;
    
        }
      


     
    }
    // new element it is not a child or a modifier of heading, so we need a separate selector
    &__heading-span {
        
        padding : 1rem 1.5rem;
        box-decoration-break: none;
        border-radius : 2px;
        background-image: 
        linear-gradient( to right bottom, rgba(17, 7, 43, 0.925),
        rgb(48, 47, 47));
    

        &--1 {
            // background-image: 
            // linear-gradient( to right bottom, black,
            // white);
        
        }

        &--2 {
            // background-image:
            // linear-gradient(to right bottom, black, white);
        }
        &--3 {
        //     background-image: 
        //     linear-gradient( to right bottom, black, white);
        }
    }

    &__details {
        padding: 3rem;
        ul {
            list-style: none;
            width: 80%;
            margin: 0 auto;
            li {
                text-align: center;
                font-size: 1.5rem;
                padding: 1rem;

                &:not(:last-child){
                    border-bottom: 1px solid grey;
                }
            }

        }
    }

   


    // @include responsive(tab-port){
  @media only screen and (max-width: 46.25em),
        only screen and (hover : none ) {
  
    border-radius: 3px;
   background-color: white;
    box-shadow: 0 1.5rem 4rem rgba(black, .15);

    &__side {
        height: auto;
        position: relative;
        border-radius:3px;
        box-shadow:none;
      

        &--back {
        
            transform : rotateY(0deg);
            clip-path:polygon(0 0,100% 0, 100% 100%, 0 100%);
        }
    }
    // the front side is the only side that rotates, the back side stays the same
    &:hover &__side--front {
        transform : rotateY(0deg);
    }

    &-details{
        padding: 1rem 3rem;
    }
   
    // Front Side Styling



    }

}

&__backcard-info {

    display:flex;
    height:100%;
    padding:1rem;
    color : black;
     justify-content :center; 
     align-items: center;
     flex-direction:column ;

     @media only screen and (max-width: 56.25em){

        h3 {
            font-size: 1.2rem;
        }

        p {
            font-size : 0.8rem;
        }
     }
   
    
     h3 {
        margin-bottom: 2rem;
        font-size : 2rem;
     }

     p {
         color : rgb(41, 41, 41);
         text-align:center
     }

}

}