.RightFriendNews{
    display: flex;
    justify-content: space-between;
    line-height: 14px;
    padding: 6px 24px;
}
.NewsFriendAvtar{
    width: 35px;
    height: 35px;
    border-radius: 32px;
    line-height: 10px;
    background-size: cover;
    margin-right: 12px
}
.RightFriendNews svg{
    width: 18px;
    max-height: 16px;
}
.NewsFriendDetais{
    display: flex;
}
.CheckFriendStatus{
    font-size: 8px;
    text-transform: uppercase;
}
.RightFriendNews .NewsFeedSR{
    padding: 3px;
    top: -38px;
    left: 1px;
    border: 1px solid #fff;
}
.newsfeed .RightFriendNews a{
    margin: 2px 0 0 0;
    display: block;
}
@media only screen and (max-width: 800px){
    .RightFriendNews{
        padding: 6px 18px 6px 6px;
    }
    .NewsFriendDetaisIn{
        margin: 0 11px !important;
    }
    .newsfeed .RightFriendNews a{
        margin-top: 3px;
    }
    .NewsFriendAvtar{
        margin-right: 0 !important
    }
}