.__ubaid__inbox{
    .fa-arrow-left.fas{
        display: none;
    }
    .conversation-content__header{
        position: relative;
        max-width: 100%;
        .__hire-btn{
            border: none;
            outline: none;
            color: #fff;
            font-size: 14px;
            padding: 2px 8px;
            position: absolute;
            border-radius: 4px;
            background: #00c441;
            right: 14px;
            // top: 14px;
            left: calc(100vw - 42px);
            cursor: pointer;
            margin-left: auto;
            animation: Pulse infinite 1s;
        }
    }
    @media (max-width: 760px) {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        .__hire-btn{
            left: calc(100vw - 52px) !important;
            right: initial !important;
        }
        .conversation-content__header{
            padding: 10px 10px 6px 10px !important;
            height: 81px;
            overflow: hidden;
            .conversation-content__subject{
                margin-top: 0;
            }
        }
        .conversation-content__subject{
            display: flex;
            align-items: center;
        }
        strong{
            max-height: 32px;
            overflow: hidden;
            border: 1px slateblue red;
        }
        .inbox-container{
            width: 100% !important;
            display: flex;
        }
        .msger{
            flex: 1;
            margin: 0 !important;
            position: absolute;
            width: 100%;
            height: calc(100% - 71px);
            overflow: hidden;
            display: flex;
            flex-direction: column;
            top: 71px;
            left: 0;
            .msger-chat{
                flex: 1;
                flex-grow: 1;
                max-width: 100%;
            }
        }
        .fa-arrow-left.fas{
            display: inline;
            margin-right: 12px;
            color: #009fc4;
        }
        .conversation-list{
            flex: 1;
            max-width: 100%;
        }
        .inbox-container{
            width: 100%;
            margin: 0;
            box-shadow: none;
        }
        .conversation-content__header{
            margin: 0;
            padding-top: 10px;
        }
    }
}
@keyframes Pulse{
    0% {
        box-shadow: 0 0 0 0 rgba($color: #00c441, $alpha: 0.6);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
    }
}
