





.form_file_upload{
  font-size: 1em;
  background-color: #eee;
  display: block;
  max-width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
  padding: 1.6em;
  box-shadow: 0 0 50px #111;
}

.label_file_upload{
  display: block;
  font-size: 1.6em;
  margin-bottom: 0.6em;
}

.button_file_upload{
  @include appearance(none);

  border: none;
  font-size: 1.6em;
  padding: 0.4em 1.6em;
  background-color: transparent;
  border: 3px solid #09c;
  color: #09c;
  transition: all .2s;
}

.file-upload{
  overflow: hidden;
  position: relative;

  >input[type=file]{
    /* position: absolute; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    @include opacity(0);

    &:hover{
      & ~ button{
        background-color: #09c;
        color: #eee;
        outline: none;
      }
    }
  }

  .file-info{
    display: block;
    clear: both;
    margin-top: 0.6em;
    color: #999;
  }
}
