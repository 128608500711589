.ubaidOppCompImage{
    position: absolute;
    height: 100%;
    display: flex;
    width: 160px;
}
.ubaidOppCompImage div{
    position: relative;
    height: 160px;
    margin: auto;
    overflow: hidden;
    border-radius: 120px;
    border: 2px solid #fff;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08), 0 3px 14px 2px rgba(0, 0, 0, 0.06), 0 5px 5px -3px rgba(0, 0, 0, 0.06);
    width: 160px;
}
.ubaidOppCompImage img{
    width: 100%;
}
/*.ubaidContentOpp{*/
/*    margin-left: 155px;*/
/*}*/
.ubaidContentOpp h4{
    margin: 0 0 16px 0;
}
.companyNavBarOverflow{
  height: 6vh;
  display:flex;
}
@media only screen and (max-width: 500px){
    .ubaidOppCompImage{
        position: relative;
        width: 100% !important;
    }
    .ubaidOppCompImage img{
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
    }
    .ubaidOppCompImage div{
        border-radius: 0;
        width: 100% !important;
        padding-top: 54.25%;
        height: auto !important;
        border: 0;
        box-shadow: none;
    }
    .companyNavBarOverflow{
        display:inline-table;
    }
    .ubaidContentOpp{
        margin-left: 0;
        text-align: center;
    }
    .ubaidContentOpp h4{
        margin: 0;
        font-size: 18px;
    }
    .ubaidContentOpp h4+p{
        margin: 0;
        padding: 0;
    }
    .ubaidContentOpp h4+p i{
        padding: 0;
        margin-left: -50px;
    }
    .card__bottom{
        text-align: left;
    }
    .card__bottom span{
        display: flex;
        margin: 10px 0;
        text-align: justify;
    }
    .card__bottom i{
        margin: 3px 16px 3px 11px;
        padding: 0;
        min-width: auto;
        width: auto;
    }
    .__footer{
        display: flex;
    }
    .__footer button{
        flex-grow: 1;
        padding: 10px !important;
    }
    .__footer button:first-child{
        flex: 1;
    }
}