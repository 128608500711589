.NewsFeedRightNav{
    position: fixed;
    width: 270px;
    top: 0;
    box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
}
.NewsRightMenuIcon{
    height: 70px;
}
.positionAbsolute{
    position: fixed;
    right: 0;
    background: #FF5E3A;
    margin: 0;
    padding: 19px 0;
    width: 70px;
    text-align: center;
    cursor: pointer;
}
.NewsRightMenuIcon svg{
    fill: #fff;
    margin: 0
}
.MainMenuNewsFeed{
    background: #fff;
}
.MainMenuNewsFeed .notifyHeader{
    border-top: 1px solid rgb(154, 159, 191, 0.2)
}
.NewsFeedRightNav .notifyHeader a{
    margin-left: auto;
}
.rightAllFriendsNews{
    padding: 22px 0 12px 0;
}
.RightNavToggleIcon{
    padding: 0 24px 12px 24px;
    display: none;
}
.RightNavToggleIcon svg:hover{
    fill: #FF5E3A;
    cursor: pointer;
}
.RightNewsForm{
    padding: 16px 24px;;
    border-top: 1px solid rgb(154, 159, 191, 0.2);
    border-bottom: 1px solid rgb(154, 159, 191, 0.2);
    margin-top: 16px;
    display: flex
}
.RightNewsForm input{
    padding: 6px;
    height: 34px;
    border-radius: 4px;
    margin-right: auto
}
.RightNewsForm svg{
    margin-left: 12px;
    width: 20px;
}
.RightNewsForm svg:hover{
    fill: #FF5E3A;
}
.footerRightNavNews{
    padding: 18px 24px;
    color: #fff;
    background: #7C5AC2;
    display: flex;
    width: 100%
}
.footerRightNavNews *{
    margin: auto 0
}
.footerRightNavNews svg{
    fill: #fff;
    margin-left: auto;
}
.hideOnNewsFeedRight270{
    display: none;
}

.NewsFeedRightNav70{
    animation: linear 0.4s forwards NewsFeedRightNav70;
}
@keyframes NewsFeedRightNav70{
    0%{
        right: 0
    }
    40%{
        right: -270px
    }
    100%{
        right: -200px
    }
}
.NewsFeedRightNav270{
    animation: ease-in 0.4s forwards NewsFeedRightNav270;
}
@keyframes NewsFeedRightNav270{
    0%{
        right: -200px
    }
    20%{
        right: -270px
    }
    100%{
        right: 0
    }
}
.NewsFeedRightNav70 .notifyHeader,
.NewsFeedRightNav70 .RightNewsForm,
.NewsFeedRightNav70 .footerRightNavNews span{
    display: none;
}
.NewsFeedRightNav70 .RightFriendNews{
    padding-left: 0
}
.NewsFeedRightNav70 .RightFriendNews .NewsFriendAvtar,
.NewsFeedRightNav70 .NewsRightMenuIcon,
.NewsFeedRightNav70 .footerRightNavNews svg{
    margin: 0
} 
.NewsFeedRightNav70 .RightFriendNews .NewsFriendDetaisIn{
    margin: 0 18px
}
.NewsFeedRightNav70 .RightNavToggleIcon,
.NewsFeedRightNav70 .hideOnNewsFeedRight270{
    display: block
}
.NewsFeedRightNav70 .rightAllFriendsNews{
    padding-top: 10px;
    padding-bottom: 6px;
}
@media only screen and (max-width: 800px){
    .NewsFeedRightNav70, .NewsFeedRightNav270{
        animation: none;
        transition: ease-in all 0.3s;
        right: -270px
    }
    .NewsFeedRightNav270{
        right: 0;
    }
    .NewsFeedRightNav70 .notifyHeader,
    .NewsFeedRightNav70 .RightNewsForm,
    .NewsFeedRightNav70 .footerRightNavNews span{
        display: block;
    }
    .NewsFeedRightNav70 .RightNavToggleIcon,
    .NewsFeedRightNav70 .newssHideonSmall,
    .NewsFeedRightNav70 .hideOnNewsFeedRight270{
        display: none
    }
    .NewsFeedRightNav70 .rightAllFriendsNews, .rightAllFriendsNews{
        padding: 16px 0 0 0;
    }
    .newssHideonSmall{
        display: none
    }
    .RightNewsForm{
        margin-top: 0
    }
    .NewsFeedRightNav{
        z-index: 1
    }
}