.__application-flag-room{
    flex: 1;
    padding-left:85px;
    .__header{
        background: #FFF;
        color: #202131;
        >h3{
            margin: 0;
            display: flex;
            align-items: center;
            .material-icons{
                margin-left: 0.4em;
            }
        }
    }
}