.applyMedia{
    height:140px;
}
@media only screen and (max-width: 1100px) {
  .applyMedia{
      height:60px;
  }
}

.UBaidpreviewComponent .imgPreview{
    margin: 8px auto;
}
.PopProfileImage{
    overflow: hidden;
    width: calc(100% - 30px) !important;
}
.UbaidsModalCommunity{
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 890px;
    margin: calc(50vh - 182.5px) auto 0 auto;
}
.tableShareCom {
  font-size: 0.8em !important;
}
@media only screen and (max-width: 800px){
    .UBaidpreviewComponent{
        width: auto !important;
    }
    .UBaidpreviewComponent .imgPreview,
    .UBaidpreviewComponent .imgPreview img{
        display: block;
        max-width: 100%;
        margin: 0 auto !important;
    }
    .UBaidpreviewComponent .imgPreview{
        margin: 10px 0 !important;
    }
    .UbaidsModalCommunity{
        margin: 0 auto;
    }
}
