.UbaidNavDropDown{
    position: absolute;
    top: 100%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: linear 0.2s opacity;
    .ubaid_carrot_icon_notification{
        display: block;
        margin: auto 32px auto auto;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 7px solid #000;
        width: 5px;
    }
    > div{
        box-shadow: 0 0 0 0 #000, 0 3px 14px 2px rgba(0,0,0,.12), 0 5px 5px -3px rgba(0,0,0,.2);
        background: #fff;
        font-size: 14px;
        width: 360px;
        > div{
            border-bottom: 1px solid rgba(154, 159, 191, 0.3);
        }
    }
    .notification_icon{
        margin: 12px 0 0 12px;
        cursor: pointer;
    }
    .ubaid-drop-items{
        overflow: auto;
        max-height: 300px;
    }
    .newsfeedOrangeT{
        margin-left: 4px;
        font-weight: normal;
        &:hover{
            text-decoration: underline;
        }
    }
    .notifyHeader{
        justify-content: space-between;
        display: flex;
        font-size: 11px;
        padding: 8px;
        a{
            margin-left: 8px;
        }
    }
    .dropdownfooter{
        padding: 16px;
        color: #fff;
        font-weight: bold;
        background: orange;
        text-align: center;
        display: block
    }
    .NavDropDownItem{
        cursor: default !important;
    }
}