.root: {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	width: 40%;
}
.input: {
	margin-left: 8px;
	flex: 1;
}
.icon: {
	padding: 10px;
	width: 90%
}
.subHeader:{
	margin-left: 800px;
}

.subHeader{
	background: -webkit-linear-gradient(bottom left,#1e73be 0%, #8224e3 100%);
	display:flex;
	justify-content: space-evenly;
}
.leftContent{
	width: 70%;
	margin:auto;
	color:white;
	padding:25px;
}
.leftContent span{
	font-size: 16px
}

.rightContent{
	margin: auto;
}
