.UbaidCommunityMember{
    .CommunityHeader{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 24px 8px 0 10px;
        align-items: center;
        .MembersButton{
            background: #3f51b1;
            font-weight: bold;
            &:hover{
                background: rgb(202, 32, 85);
            }
        }
        @media (max-width:426px) {
            flex-direction: column;
            align-items: center;
            padding-top: 12px;
        }
    }
}
