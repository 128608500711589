.parallax{
    background: rgba(255,255,255,0);
    background-image: url('../assets/grey-background.jpg');
    background-attachment: fixed;
    background-repeat: repeat;
    background-position: left top;
    font-size: 24px;
    color: #8E3700;
}
.parallax .pitchBoxContainer{
    display: flex;
}
.parallax .pitchBoxContainer div{
    margin: auto 72px auto auto;
}
@media only screen and (max-width: 900px){
    .parallax .pitchBoxContainer{
        width: 100%;
    }
}
@media only screen and (max-width: 600px){
    .parallax{
        padding: 7% 0
    }
    .parallax img{
        display: block;
        margin: auto;
        width: 320px;
    }
    .parallax .pitchBoxContainer div{
        position: absolute;
        margin: 0;
        bottom: -10px;
        right: 14.5%;
    }
    .parallax .pitchBoxContainer div img{
        width: 36px;
    }
    .parallax .pitchBoxContainer{
        margin: auto;
        display: block;
        position: relative;
    }
}
@media only screen and (max-width: 400px){
    .parallax .pitchBoxContainer div{
        right: 12%;
    }
}
@media only screen and (max-width: 350px){
    .parallax .pitchBoxContainer div{
        right: 7%;
    }
}
@media only screen and (max-width: 330px){
    .parallax .pitchBoxContainer div{
        right: 4%;
    }
}