.navbar {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-bottom: 1px solid #e5e5e5 !important;
  right: 0px !important;
  height: 10vh;
}

.__application-room-main .__share-modal-content {
  width: 50vw !important;
  min-width: 380px;
}

.close {
  margin-top: 8px !important;
}

.__application-room-main {
  padding-left: 25vh;
  max-width: 100%;
  font-size: 14px;
  color: #000;
  font-family: "Open Sans", sans-serif;
  input,
  button {
    font-family: inherit;
  }
  .__header {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0vh;
    z-index: 1;
    display: none;
  }
  .__content {
    display: flex;
  }
  .__application-room-margin {
    margin: 1.6em;
  }
  .__application-room-padding {
    padding: 1.6em;
  }
  .add-company-button {
    background-color: rgba(255, 255, 255, .7) !important;
    color: #222227 !important;
    font-weight: 500;
    font-size: 10px !important;
    padding: 1em 2em !important;
  }
  .archived-deals-button {
    background-color: rgba(255, 255, 255, 0) !important;
    color: #000 !important;
    font-weight: 900;
    font-size: 12px !important;
  }

  .__application-room-profile-card-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0em 0em 0em 0em;
    align-items: stretch;
    > div {
      > div {
        margin: 0 !important;
      }
      margin: 0.5em 0.5em 0em 0em;
      max-width: calc(25% - 1em);
      min-width: calc(25% - 1em);
      @media (max-width: 1300px) {
        max-width: calc(33.33% - 1em);
        min-width: calc(33.33% - 1em);
      }
      @media (max-width: 1020px) {
        max-width: calc(50% - 1em);
        min-width: calc(50% - 1em);
      }
      @media (max-width: 500px) {
        max-width: calc(100% - 1em);
        min-width: calc(100% - 1em);
      }
    }
  }
  @media (max-width: 350px) {
    .__application-room-margin {
      margin: 1em;
    }
    .__application-room-padding {
      padding: 1em;
    }
    .__application-room-profile-card-grid {
      margin: 1em 0.5em;
    }
    .__title {
      font-size: 1.6em;
    }
  }
}

@media (max-width: 991px) {
  #root {
    .__application-room-main {
      .__header {
        padding: 16px;
        .add-company-button {
          padding: 7px 12px;
        }
      }
      .arg-navbar-secondary {
        padding-top: 8px;
        padding-bottom: 8px;
        .container {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
    .side-filter-main-wraper {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/* Navigation styles */
.__pb_settings_navigation {
  border-radius: 12px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.__pb-setting-nav-item {
  display: flex;
  align-items: center;
  color: #666;
  border-radius: 8px;
  text-decoration: none;
  margin-bottom: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.__pb-setting-nav-item:hover {
  background-color: #ececec;
  color: #333;
}

.__pb-setting-nav-item.active {
  background-color: #c49733;
  color: #fff;
  font-weight: bold;
}

.__pb-setting-nav-item i.material-icons {
  margin-right: 10px;
  font-size: 24px;
}

.__pb-setting-nav-item .__pb_settings_navigation_text {
  font-size: 16px;
}