.__popup{
    display: none;
    z-index: 1050;
    &.__open{
        display: block;
    }
    .__blur{
        width: 100%;
        position: fixed;
        background: rgba($color: #000000, $alpha: 0.7);
        left: 0;
        top: 0;
        height: 100%;
    }
    .__popup-card{
        position: fixed;
        background: #fff;
        z-index: 1001;
        width: 550px;
        padding: 20px;
        left: 50%;
        top: 50%;
        border-radius: 4px;
        animation: PopUp forwards 0.3s;
    }
    .__popup-title{
        font-size: 1.3em;
        font-weight: 600;
        margin-bottom: 1em;
        line-height: normal;
    }
    .__popup-footer{
        display: flex;
        margin: 2em -0.5em -0.5em 0;
        justify-content: flex-end;
        font-weight: 600;
        text-transform: uppercase;
        >div{
            cursor: pointer;
            padding: 0.5em 1em;
            border-radius: 3px;
            &:hover{
                background: rgba($color: #9a9a9a, $alpha: 0.2);
            }
        }
        .__success{
            color: #2e7d32;
        }
        .__danger{
            color: #f44336;
        }
        .__orange{
            color: #ff9800;
        }
        .__grey{
            color: #757575;
        }
    }
    @media (max-width: 400px) {
        .__popup-card{
            max-width: calc(100% - 2em);
        }
        .__popup-title{
            font-size: 1.2em;
        }
    }
}

@keyframes PopUp{
    0%{
        transform: translate(-50%, -50%) scale(0);
    }
    100%{
        transform: translate(-50%, -50%) scale(1);
    }
}