.RightBodyNews {
    margin-bottom: 16px;
}
.newsBirthdayCard {
  background: url("../Images/bg-birthdays.jpg");
  padding: 24px;
  color: #fff;
  margin-bottom: 16px;
}
.NewsBirthdayTop {
  justify-content: space-between;
  display: flex;
}
.NewsBirthdayTop svg {
  fill: #fff;
  height: 20px;
}
.NewsBirthdayTop .threedots {
  width: 14px;
}
.newsBirthdayCard .NewsFeedBackground {
  width: 28px;
  height: 28px;
  background-size: cover;
  background-color: white;
  border-radius: 30px;
  border: 2px solid;
  margin: 40px 0 16px 0;
}
.newsFeedSmall {
  font-size: 10px;
}
.newsBirthdayCard h5 {
  font-weight: 400;
  font-size: 24px;
  margin: 2px 0;
  line-height: 32px;
}
.newsBirthdayCard .leaveMessage {
  margin: 16px 0;
}
.BlueBackGroundSvg .NewsFeedResult svg {
  fill: #fff;
  height: 20px;
}
.BlueBackGroundSvg .NewsFeedResult .newsfeedIcons div {
  padding: 8px;
  border-radius: 3px;
}
.FriendsCommentsNews * {
  font-size: 12px;
}
.FriendsCommentsNews .NewsFeedResult .backgroundImage {
  min-width: 29px;
  height: 29px;
  margin-right: 12px;
}
.rigthBodyFooterNews {
  background: url("../Images/landing-bg.jpg");
  border-radius: 4px;
  padding: 36px 43px;
  text-align: center;
  color: #fff;
  font-size: 10.3px;
}
.rigthBodyFooterNews img {
  margin: 16px 0 21px 0;
}
.rigthBodyFooterNews span {
  margin: 16px 0 24px 0;
  display: block;
}
.newsfeed .rigthBodyFooterNews a {
  color: white !important;
  display: block;
  padding: 16px;
  background: #515363;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px !important;
}
/* Card Area */
.NewsFeedCardActionArea {
  height: 167px;
  background-size: cover;
}
.NewsfeedCardContent.exploreCardContent {
  font-size: 14px;
  max-height: 54px;
  padding: 10px;
}
.CardFooterBio{
    flex-grow: 100;
    margin: auto 64px auto 0;
    text-align: center;
    overflow: hidden;
    font-size: 14px;
}
.footerButtonNews {
  padding: 11px 8px !important;
  border-radius: 32px;
  cursor: pointer;
  margin: 0 10px 10px 10px;
}
.footerButtonNews:hover {
  background: rgb(154, 159, 191, 0.2) !important;
  transform: rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.chip{
    margin: 2px 1px 0 1px;
}