.leftNavExpOppToo{
    &.open{
        left: 0 !important;
    }
    position: fixed;
    top: 0;
    z-index: 100;
    background: #202131;
    height: 100%;
    bottom: 0;
    padding: 80px 6px 30px 6px;
    width: 70px;
    overflow-x: hidden;
    transition: ease-in 0.3s all;
    box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
    overflow-y: scroll;
    .hide-on-large{
        display: none;
    }
    a{
        &.ubaid-logo-container{
            height: auto;
            // margin: -12px -6px 0 -6px;
            // box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
        }
        color: #9A9FBF;
        display: block;
        height: 46px;
        overflow: hidden;
        font-size: 14px;
        font-weight: bold;
        i{
            font-size: 20px;
            vertical-align: middle;
            margin-right: 10px;
            margin-left: 4px;
        }
        &.active, &:hover{
            color: #515363 !important;
            i{
                color: #9A9FBF;
            }
        }
    }
    img{
        max-height: 38.3px;
        margin: 12px 7px 12px 8px;
    }
    &:hover{
        width: 270px;
        .secLogo{
            display: inline;
        }
        &::-webkit-scrollbar{
            display: block;
        }
        .UBaidSubHeader{
            padding-left: 16px;
        }
    }

    .secLogo{
        display: none;
        margin-left: 0 !important;
        position: absolute;
        max-width: 192px;
    }
    @media (min-width: 1024px) {
        &::-webkit-scrollbar {
            width: 7px;
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            background: #9e9e9e;
        }
    }
    @media (max-width: 600px) {
        width: 270px;
        left: -270px;
        .secLogo{
            display: inline !important;
        }
        .hide-on-large{
            display: block;
        }
    }
}
.UBaidSubHeader{
    @media (max-width: 1150px) {
        padding-left: 32px;
    }
    @media (max-width: 1030px) {
        padding-left: 42px;
    }
    @media (max-width: 600px) {
        display:unset;
    }
}

.ExpOppTooMainUbaid{
    padding-left: 70px;
    padding-top: 30px;
    background: white;
    height: 100vh;
}

@media (max-width: 1030px){
    .UBaidSubHeader{
        padding-left: 42px;
    }
}
@media (max-width: 900px){
    .UBaidSubHeader{
        padding-left: 54px;
    }
    .ExpOppTooMainUbaid{
        padding-left: 67px;
    }
}
@media (max-width: 800px){
    .UBaidSubHeader{
        padding-left: 56px;
    }
}
@media (max-width: 760px){
    .__sidebar__wrapper{
        display: none;
        .navbar-toggler{
            margin-right: 9px;
        }
    }
    .ExpOppTooMainUbaid, .UBaidSubHeader{
        padding-left: 0 !important
    }
}
