.SocialSideBarCard {
  background: #fff;
  padding: 13px 0;
}
.SocialSideBarCard .SocialSideItem {
  border: none;
  padding: 11px 24px;
  font-size: 11px;
  display: flex;
  line-height: 18px;
}
.SocialSideBarCard .NewsCardTitle {
  border: none;
  padding: 11px 24px;
}
.SocialSideBarCard .SocialSideItem:hover{
    background-color: white;
}
.SocialLoadingIcon {
  animation: ease-in loadingfadeinout 1.5s infinite;
  width: 48px;
  margin-top: 60px;
  border-radius: 36px;
  height: 48px;
  margin: 36px auto;
  background: #0155a9;
  opacity: 0.4;
  display: flex;
}
.SocialLoadingIcon.darkIcon {
  background: black;
}
.SocialLoadingIcon svg{
    fill: white;
    height: 10px;
    margin: auto;
}
@keyframes loadingfadeinout {
  0% { opacity: 0.03; }
  50% { opacity: 0.17; }
  100% { opacity: 0.03; }
}

.SocialSideBarCard .profileImage {
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 10px;
}
.SocialSideItem .mainContent {
  color: #69839a;
  font-weight: bold;
  font-size: 12px;
  flex-grow: 1;
}
.SocialSideItem .small {
  color: #9A9FBF;
  font-size: 10px;
}
.SocialSideItem .ctaBtn {
  padding: 3px 10px;
  background: #b7d4ea;
  color: white;
  border-radius: 5px;
  font-size: 11px;
  font-weight: normal;
  margin-top: 4px;
  display: block;
}
.SocialSideItem:hover .ctaBtn {
  background: cornflowerblue;
}

.SuggestFriendsNews{
    border-radius: 4px;
    margin-bottom: 16px;
}
.SuggestFriendsNews .NewsCardTitle svg{
    width: 16px;
    height: 16px;
    margin-top: auto
}
.SuggestFriendsNews .NewsFeedResult .backgroundImage{
    min-width: 36px;
    height: 36px;
    margin-right: 8px
}
.SuggestFriendsNews .NewsFeedResult svg{
    fill: black;
}
