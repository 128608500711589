.UBaidApp{
    width: 100%;
}
.ubaidCardCol{
    display: inline-block;
    padding: 0 12px !important;
    vertical-align: top;
}
.ubaidCardCol .video-card-body{
    padding: 0;
    vertical-align: top;
    text-align: left;
}
.ubaidProductCardText .exploreCardContent{
    margin: 0 !important;
    min-height: 194px;
}
@media only screen and (max-width: 500px){
    .ubaidRow{
        display: block !important;
    }
    .ubaidCardCol{
        display: block;
        padding: 0 !important
    }
}
@media only screen and (max-width: 400px){
    .ubaidProductCardText{
        font-size: 0.8rem !important;
    }
}
@media only screen and (max-width: 350px){
    .ubaidProductCardText .ubaidproductCardBtn{
        font-size: 0.7rem !important;
    }
}