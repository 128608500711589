.__application-room-team{
    flex: 1;
    padding-left: 0px;
    width: 100%;
    .__title{
        font-weight: 400;
        display: flex;
        align-items: center;
        margin: 0;
        .material-icons{
            margin-left: 0.6em;
        }
    }
    .__header{
        background: #fff;
        color: #202131;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.2em 2.5em;
        .__add-btn, .__close-btn{
            padding: 0.4em;
            border-radius: 49%;
            cursor: pointer;
            &:hover{
                background: rgba($color: #000000, $alpha: 0.1);
            }
        }
        .__close-btn{
            background: rgba($color: #000000, $alpha: 0.05);
        }
    }
    .__team-member{
        padding-left: 20px;
        text-align: center;
        .__team-member-main{
            background: #fff;
            padding: 75px 20px 20px 20px;
            border-radius: 6px;
        }
        .__team-member-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            bottom: -60px;
            .material-icons{
                font-size: 24px;
                cursor: pointer;
                margin-bottom: auto;
            }
        }
        .__team-member-avatar{
            margin: auto;
            border-radius: 120px;
            overflow: hidden;
            img{
                width: 150px;
                max-height: 150px;
            }
        }
    }
    .form-horizontal{
        margin-top: 4em;
    }
    @media (max-width: 350px) {
        .__header{
            padding: 0.8em 1em;
        }
    }
}
@media (max-width: 991px) {
    .__application-room-team{
        padding-left:0px;
    }

    .__team-member{
        padding-right: 2.5vh;
    }
}