.servicesPage {
    height: 100vh;
    width: 100%;
    // padding : 2rem;
    overflow: auto;



    h1 {
        font-size: 3rem;
    }

    h3 {
        font-size: 2rem;
    }


    &-container  {
        padding : 2rem;

        @media only screen and (max-width: 56.25em){
            padding : 0.5rem;
        }
    }
    &__banner {

        height: 5rem;
        margin-top: 5rem;
        padding: 2rem;
        background-image: linear-gradient( to right bottom, rgba(27, 42, 177, 0.925),  rgba(147, 24, 196, 0.925));
        display: flex;
        justify-content: space-between;
        align-items: center;

            ul {
                color : white;
                font-size : 1.4rem;

                a:hover{
                     color  : red;

                    a:hover {
                        color : black;
                    }
                }

            }


        h3 {

            font-size : 2rem;
            color: #ffffff;
            text-transform: uppercase;
            font-weight: 400;
            letter-spacing: 2px;
        }


    }


    &__header {

    margin-top: 3rem;
    background-color : rgb(247, 247, 247);
     padding: 2rem;

        h1 {
            font-size:2rem;
            color : black;
            font-weight: 450;
        }

        h3 {
            font-size:1.5rem;

            color : black;
            font-weight: 400;
        }

        & > :not(:last-child){
            margin-bottom: 1.5rem;
        }
        & > * :last-child {
            margin-bottom : 10rem;
        }


    }

    &__general-consultation {
        width: 100%;
        margin-top: 3rem;
        display : flex;
        justify-content: space-between;

        @media only screen and (max-width: 56.25em){

            flex-direction: column

        }


        &-card {
            border-radius : 2px;
            width: 60%;
            height: 25rem;
            background : url('../../img/1_q_n6xldc7hsbqnnx8nnjka-1024x576-1024x576.png') no-repeat center center;
            background-size : cover;
            display : flex;
            justify-content: center;
            align-items: center;

            position: relative;

            &::after {
                height: 100%;
                width: 100%;
                padding: 2rem;
                content : "Not sure where to start? Need help determining to go next? Talk to a startup consultant today that can point you in the right direction!";
                color : white;
                position: absolute;
                font-size: 1.5rem;
                // top : 0;
                // left: 0;
                // right : 0;
                background-image :linear-gradient(to bottom right , rgb(17, 7, 43), rgb(66, 33, 150));
                display : flex;
                justify-content: center;
                align-items: center;
                transform: scale(0);
                transition: transform ease-in .2s;
            }


            &:hover::after {

                transform : scale(1);

            }


            @media only screen and (max-width: 56.25em){

                width : 100%;
                margin-bottom : 2rem;

            }




            &-title {
               background-image : linear-gradient( to right bottom, rgba(17, 7, 43, 0.925));
                color: white;
                padding : 2rem;
                text-align : center;
                width: 100%;


                h1 {
                    font-size : 3rem;
                    @media only screen and (max-width: 56.25em){

                        font-size: 2rem;

                    }
                }

            }




        }

        &-info {
            display : flex;
            flex-direction: column;
            width: 35%;

            @media only screen and (max-width: 56.25em){

                width : 100%;
                margin-bottom : 2rem;

            }
            h3 {
                font-size:1rem;

                color: black;
                font-weight: 400;
            }

            h5 {
                color : yellow;
            }
            button {
                justify-content: flex-start;
            }

        }


    }


    &__button {
        justify-content: flex-start;
        width: 100%;
        justify-content: center;
        font-size : 0.8rem;
        color: black;
        font-weight : 500;

        padding: 1rem;
        background-color : rgb(74, 38, 233);
        border-radius: 5px;

        &:hover {
            color : white;
            filter : brightness(70%);

        }

    }

    &__services-cards {
        margin-top : 3rem;
        width: 100%;
        display : flex;
        flex-flow : wrap;
        justify-content: space-evenly;

    }



}
