.exploreIconMain{
    margin: auto 8px;
    font-size: 36px;
    color: #9e9e9e;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none !important;
}
.MapOpen{
    color: #3f51b5 !important;
}
.ExploreMap{
    height: 0;
    overflow: hidden;
    transition: ease-in 0.3s all;
    display: flex;
    flex-direction: column;
    position: relative;
}
.showMap{
    height: calc(80vh - 146px);
}
@media only screen and (max-width: 1370px) and (max-height: 800px){
    .showMap{
        height: calc(90.5vh - 147px);
    }
}
@media only screen and (max-width: 600px){
    .UbaidSort{
        margin-bottom:12px;
        width: 100%;
        box-sizing: border-box;
        display: flex !important;
    }
    .NumberOfPitchCompUBaid{
        line-height: normal !important;
        margin: 12px 8px 8px 8px;
        font-size: 18px !important;
        font-weight: 400;
        text-align: center;
    }
    .UbaidSort .UbaidSortFormControl{
        box-sizing: border-box !important;
        margin-right: 22px !important;
        flex-grow: 100 !important;
    }
    .exploreIconMain{
        margin: -7px 0 14px -7px !important;
        text-align: center;
        width: 100%;
    }
    .SortByWrapperMain{
        flex-direction: column-reverse;
    }
    .showMap{
        height: 60.5vh;
    }
}
@media only screen and (max-width: 380px){
    .showMap{
        height: 60.5vh;
    }
}
@media only screen and (max-width: 350px){
    .showMap{
        height: 50.5vh;
    }
}
