.newsfeed{
    color: #505368;
    font-family: 'Roboto';
    font-size: 12.3px;
    font-weight: 400;
    background: #F4F2EE;
}
.newsfeed input{
    box-shadow: none !important;
    border: 1px solid rgb(154, 159, 191, 0.3);
}
.newsfeed input:focus{
    outline: none;
    border-color: rgb(154, 159, 191, 0.3);
}
.newsfeed11{
    font-size: 11px;
}
.newsfeed12{
    font-size: 12px;
}
.newsfeed13, .newsfeed14{
    color: #515363;
    font-size: 13px;
}
.newsfeed14{
    font-size: 14px;
}
.newsfeedOrange{
    background-color: #FF5E3A !important;
    color: white;
    border-color: #FF5E3A !important;
}
.newsfeedPurple{
    background-color: #7C5AC2;
}
.newsfeedTeal{
    background-color: #38A9FF;
}
.newsfeedGrey{
    background-color: #9A9FBF;
}
.newsfeedOrangeT{
    color: #FF5E3A !important;
    font-weight: bold
}
.newsfeed b{
    font-weight: bold
}
.newsfeed a{
    text-decoration: none;
    font-weight: bold;
}
.newsFeedOnline{
    background: #32E4CD;
}
.newsFeedDisconnected{
    background: #ff4f60;
}
.newsFeedAway{
    background: #FFD52F;
}
.newsFeedInvisible{
    background: #9A9FBF
}
.newsFeedOnline, .newsFeedDisconnected,
.newsFeedAway, .newsFeedInvisible{
    padding: 4px;
    border-radius: 4px;
    display: inline-block !important;
    margin: auto
}
.NewsFeedSR{
    position: relative;
    top: -38px;
}

.newsFeedBody{
    display: flex;
}
.NewsFeedRightNavMain, .NewsFeedLeftNavMain{
    flex-shrink: 0;
    position: relative;
    width: 70px;
}
.NewsFeedRightNavMain{
    margin-left: auto;
}
.NewsFeedBodyMain{
    flex-grow: 1;
    margin: 42px 64px 0 64px;
    display: flex;
}
.NewsFeedBodyLeft, .NewsFeedBodyRight{
    width: 285px;
}
.NewsFeedBodyCenter{
    flex-grow: 100;
}
.NewsCardTitle{
    padding: 24px;
    display: flex;
    font-weight: bold;
    color: #515363;
    font-size: 14px;
    border-bottom: 1px solid rgb(154, 159, 191, 0.3);
    justify-content: space-between
}
@media only screen and (max-width: 1150px){
    .NewsFeedBodyLeft, .NewsFeedBodyRight{
        display: none;
    }
}

@media only screen and (max-width: 768px){
    .NewsFeedRightNavMain, .NewsFeedLeftNavMain{
        width: auto;
    }
    .NewsFeedBodyMain{
        margin: 0;
    }
    .NewsFeedBodyCenter{
        margin: 18px 16px;
    }
}
@media only screen and (max-width: 800px){
    .NewsFeedBodyCenter{
        margin-left: 84px;
    }
}
@media only screen and (max-width: 600px){
    .NewsFeedBodyMain{
        padding: 0
    }
    .NewsFeedBodyCenter{
        margin: 16px;
    }
    .NewsFeedLeftNavMain{
      display: none;
    }
}
