.__info-popup{
    .__blur{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.6);
        z-index: 1050;
    }
    .__content{
        position: fixed;
        text-overflow: ellipsis;
        max-width: calc(100% - 1.6em);
        top: 50%;
        left: 50%;
        z-index: 1050;
        background: #fff;
        text-align: center;
        display: block !important;
        padding: 1em 1em 0 1em;
        max-height: calc(100% - 4em);
        overflow: auto;
        transform: translate(-50%, -50%);
        animation: forwards PopUp 0.3s;
        >div{
            padding: 1em;
            >div:first-child{
                font-weight: bold;
            }
        }
    }
}

.top_rating{
    text-align: center;
}