.__projects-page{
    margin: 2em auto;
    font-size: 14px;
    font-family: 'Open sans';
    max-width: 800px;
    .__project-navbar{
        margin-bottom: 1em;
    }
    button{
        outline: none !important;
    }
    .__card{
        border: 1px solid #e0e0e0;
        background: #fff;
        display: flex;
        text-align: left;
        border-radius: 6px;
        margin: 1em;
        .__flex{
            display: flex;
            align-items: center;
            margin-bottom: 0.4em;
            &.__sb{
                justify-content: space-between;
            }
        }
        .__left, .__right{
            padding: 1.6em;
        }
        .__left{
            flex: 1;
        }
        .__right{
            width: 260px;
            border-left: 1px solid #e0e0e0;
            display: flex;
            flex-direction: column;
        }
        .__client{
            color: #757575;
        }
        .__title{
            color: #000;
            font-size: 1.4em;
            font-weight: bold;
        }
        .__description{
            margin-top: 1em;
        }
        i{
            font-size: 1.2em;
            width: 1.2em;
            margin-right: 0.4em;
            *{
                text-align: center;
            }
            text-align: center;
        }
        .fa-hand-holding-usd{
            color: #8bc34a;
        }
        .fa-file-invoice-dollar{
            color: #fdd835;
        }
        .fa-suitcase{
            color: #bdbdbd;
        }
        .fa-hourglass-start{
            color: #2196f3;
        }
        .fa-hourglass-end{
            color: #3f51b5;
        }
        button{
            border: none;
            background: #8bc34a;
            color: #fff;
            border-radius: 3px;
            cursor: pointer;
            padding: 8px 0;
            &.__red{
                background: #f44336;
            }
            &.__blue{
                background: #42a5f5;
            }
            &.__orange{
                background: #ff9800;
            }
            &.__block{
                width: 100%;
            }
            &.__mb-05{
                margin-bottom: 7px;
            }
            &.__darkgrey{
                background: #455a64;
            }
        }
        .__footer{
            display: flex;
            flex-wrap: wrap;
            margin: auto -0.4em -0.4em -0.4em;
            padding-top: 0.5em;
            button{
                flex: 1;
                margin: 0.4em;
                min-width: 40%;
            }
        }
    }
    @media (max-width:800px) {
        .__card{
            margin: 1em 2em;
            .__right{
                width: 250px;
            }
        }
    }
    @media (max-width: 600px) {
        .__card{
            display: block;
            margin: 16px;
            .__right{
                width: auto;
            }
        }
    }
}