/* reports.css */
.report-container {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.left-panel {
  width: 40%;
}

.right-panel {
  width: 60%;
}

.subreport-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.summarize-button {
  background: linear-gradient(90deg, #6902b9 0%, #1c71a1 100%);
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  color: white;
}

.report-title {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.subreport-title {
  width: fit-content;
  text-align: left;
  color: #333;
  background: #fff;
  padding: 10px;
  border-radius: 12px;
}

.overall-summary {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
}

.overall-summary-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.market-intelligence-title {
  text-align: left;
  font-size: 1.5em;
  font-weight: bold;
}

.score-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overall-summary-title {
  margin-top: 0;
  color: #0047ab;
  flex-grow: 1;
}

.score-details-right {
  text-align: right;
  font-size: 0.9rem;
  color: #666;
}

.credits-used,
.created-on {
  margin: 0;
}

.score {
  font-size: 1.5em;
  background: linear-gradient(to right, #ff334b, #0047ab);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.summary-text {
  line-height: 1.6;
  color: #666;
}

.category-breakdown {
  margin-bottom: 20px;
  color: #333;
}

.category-item {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 15px;
}

.category-title {
  font-size: 1.5em;
  margin-top: 0;
  color: #0047ab;
}

.score-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.score-bar {
  height: 20px;
  background: linear-gradient(to right, #ff334b, #0047ab);
  border-radius: 10px;
}

.score-number {
  margin-left: 10px;
  font-weight: bold;
  background: linear-gradient(to right, #ff334b, #0047ab);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.category-reason {
  margin-top: 10px;
  color: #666;
}

.error {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  margin-top: 20px;
}

.overall-score {
  font-size: 1.5em;
  font-weight: bold;
  background: linear-gradient(to right, #0047ab, #ff334b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.overall-score-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin: 10px 0;
}

.overall-score-bar {
  height: 20px;
  background: linear-gradient(to right, #0047ab, #ff334b);
  border-radius: 10px;
}

.market-intelligence-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.header-section {
  margin-bottom: 20px;
}
.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
.subtitle {
  font-size: 14px;
  color: #666;
}
.action-section {
  margin-bottom: 20px;
}
.generate-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.generate-button:hover {
  background-color: #0056b3;
}
.error-message {
  color: red;
  margin-bottom: 20px;
}
.intelligence-report {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.report-section {
  margin-bottom: 20px;
}
.section-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}
.section-content {
  font-size: 14px;
  color: #555;
}
.growth-rate-table-container {
  overflow-x: auto;
}
.growth-rate-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}
.growth-rate-table th,
.growth-rate-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.growth-rate-table th {
  background-color: #f2f2f2;
  color: #333;
}
