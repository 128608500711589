/* .main {
    margin-left: 160px;
    font-size: 30px;
    padding: 0px 10px;
  } */

.card_main_parent {
  padding: 40px 15px;
  display: flex;
  width: 50%;
  justify-content: space-around;
  margin-left: 20%;
  flex-direction: column;
  height: 600px;
  background-color: #f8f8f8;
}

.card_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.card_parent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 991px) {
  .card_main_parent {
    margin-left: 0;
  }
  .page-wraper {
    width: 100% !important;
  }
}

/* @media screen and (max-height: 450px) {
    .sidenav {padding-top: 0px;}
    .sidenav a {font-size: 15px;}
  } */

.page-wraper {
  top: 0;
  width: 80%;
  float: right;
  font-family: "Lato";
  background-color: #f8f8f8;
}

.page-wraper>div {
  display: flex;
  /* width: 92%; */
  max-width: 1200px;
  margin: 0px auto;
  margin-top: 3.2em;
  /* border:1px solid green */

}

.right-panel {
  flex: 1
}

.right-panel-content {
  width: 80%;
  margin: 0px auto
}

.panel-head img {
  width: 100%
}

.panel-head .logo-text {
  font-size: 24px;
  color: #555555 !important;
  text-align: center;
  font-weight: bold
}

.panel-head .hero-img {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.panel-head .logo>div:first-of-type>div:first-of-type {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  padding: 5px;
  background-color: #fff;
  margin: 0px auto;
}

.panel-head .logo>div:first-of-type {
  position: relative;
  /*top: -37px;*/
}

.hr-line {
  border: 1px solid #eeeeee
}

.ratings {
  font-size: 13px;
  color: #666666;
  padding: 30px 15px 15px
}

.ratings>div:first-child {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  font-size: 16px;
  color: #555555;
  font-weight: bold
}

.ratings span * {
  margin: 0;
}

.ratings>div:nth-child(2) {
  line-height: 2.5;
}

.rating-counts {
  margin: 20px 0
}

.rating-counts>div {
  margin: 0px 0px 15px 0px
}

.rating-counts>div>span {
  display: flex;
  flex: 1;
  display: inline-block;
}

.rating-counts>div>span:nth-child(1) {
  width: 25%;
  text-align: left
}

.rating-counts>div>span:nth-child(2) {
  text-align: center;
  width: 60%;
  height: 20px !important;
  overflow: hidden !important;
  border: 1px solid #fff;
}

.progress {
  border-radius: 0px !important;
  height: 0.8rem !important;
}

.rating-counts>div>span:nth-child(3) {
  text-align: right;
  width: 15%
}

.hr-line-2>div {
  border: 1.5px solid #eeeeee;
  margin: 0px auto;
  width: 90%;
}

.write-review {
  width: 90%;
  display: flex;
  font-size: 15px;
  color: #777777;
  font-weight: 700;
  margin: 20px auto
}

.write-review>div:first-child {
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.write-review>div:last-child {
  border: 1px solid #cccccc;
  border-radius: 3px;
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center
}

.write-review>div:last-child>span {
  margin: 0px 5px
}

.write-review div:first-child img {
  height: 40px
}

.card-row {
  margin: 0px;
  display: flex;
  justify-content: space-between
}

@media (max-width: 1140px) {
  /* .page-wraper {
    width: 100%;
  } */
  .page-wraper>div {
    width: 95%
  }
  .right-panel {
    /* border: 1px solid green; */
  }
  .right-panel-content {
    /* border: 1px solid red; */
    width: 90%;
    /* margin: 0 0 0 38px; */
  }
  .topNav {
    margin-left: 0 !important;
  }
}

@media (max-width: 767px) {
  .page-wraper>div {
    flex-direction: column;
    width: 95%
  }
  .right-panel-content {
    width: 90%
  }
  .panel-head .logo>div:first-of-type>div:first-of-type {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    padding: 5px;
    background-color: #fff;
    margin: 0 0 0 25px;
    display: inline-block;
  }
  .panel-head .logo>div:first-of-type {
    top: -66px;
  }
  .panel-head .logo-text {
    display: inline-block;
    position: relative;
    top: -40px;
    left: 20px;
  }
  .ratings {
    width: 100%
  }
  .write-review>div:first-child {
    flex: .4;
    justify-content: flex-start
  }
  .write-review>div:last-child {
    justify-content: flex-start;
    padding-left: 10px
  }
}

/* @media(max-width:795px) { */

@media screen and (min-width: 767px) and (max-width:795px) {
  .card-row>div {
    flex-wrap: wrap;
    display: block;
  }
  .card_button>div {}
}

@media(max-width:530px) {
  .card-row>div {
    flex-wrap: wrap;
    display: block;
  }
}

@media(max-width:570px) {
  .panel-head .logo>div:first-of-type>div:first-of-type {
    width: 100px;
    height: 100px;
    margin: 0px auto;
    display: block;
  }
  .panel-head .logo>div:first-of-type {
    position: relative;
    top: -37px;
  }
  .logo-text {
    display: block !important;
    position: static !important;
  }
  .transition-animation{
    position: static !important;
    margin-bottom: 30px
  }
}
