.comment {
    display: flex;
    margin:25px 0px
}

.comment img {
    height: 40px;
}
.comment>div {
    /* border: 1px solid red; */
    padding: 5px;
    font-size: 15px;
    color: #666666;
}

.comment-text > div{
    margin: 10px 0px;
    font-size: 14px;
}
.comment-text>div:first-child {
    color: #333333;
    font-weight: 700;
    line-height: 1,
}

.comment-text>div:first-child>span:first-child { 
    padding: 0px 10px
}

.comment-text>div:nth-child(2)>span:nth-child(2) {
    color: #333333;
}

.comment-text>div:nth-child(3)>p {
    font-size:18px;
    font-weight: 200;
}
