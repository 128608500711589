.flame-body {
	background:#000 !important;
	display:flex;
	justify-content:center;
	align-items:center;
	height:100vh;
}

.flame {
	width:150px;
	height:150px;
	background:linear-gradient(-45deg, red, orange);
	border-radius: 150px 150px 0px 150px;
	transform:rotate(-135deg);
	animation:.15s flame infinite;
	filter:blur(10px);
	position:relative;
	box-shadow:17px 20px 90px #700;
	border:45px solid black;
	border-left-width:25px;
	border-top-width:25px;
}

@keyframes flame {
  0% {height:20vh; width:20vh;}
  50% {height:18vh; width:18vh;}
  100% {height:20vh; width:20vh;}
}
