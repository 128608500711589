/* Styles.css */
.opportunity-submission-card {
    background: #fff;
    padding: 25px 20px;
    border-radius: 10px;
    margin-top: 40px;
    width: 80%;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Poppins, Verdana, sans-serif;;
}

@media (max-width: 1024px) {
    .opportunity-submission-card {
        width: 100%;
    }
}

.opportunity-submission-card h1 {
    color: #000000;
    font: bold 24px/1.5 Poppins, Verdana, sans-serif;
}

.opportunity-submission-card li {
    color: #000000;
    font: normal 14px/1.5 Poppins, Verdana, sans-serif;
}

.opportunity-submission-card h2 {
    color: #000000;
    font: bold 20px/1.5 Poppins, Verdana, sans-serif;
}

.opportunity-submission-card select {
    height: 45px;
    padding: 0 15px;
    background-color: #f8f8f8;
    margin: 0 2px 10px 0;
    font-size: 16px;
    width: 60%;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.opportunity-submission-card h3 {
    color: #3a3a3a;
    font: 16px/1.5 Poppins, Verdana, sans-serif;
}

.opportunity-submission-card label {
    margin-top: 10px;
    display: block;
    color: #000000;
    font: normal 16px/1.5 Poppins, Verdana, sans-serif;

}
.opportunity-submission-card input {
    height: 45px;
    padding: 0 15px;
    background-color: #f8f8f8;
    margin: 0 2px 10px 0;
    font-size: 16px;
    width: 100%;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.opportunity-submission-card input[type="file"] {
    display: none;
}

.opportunity-submission-card .custom-file-upload {
    display: inline-block;
    padding: 10px 15px;
    background-color: #f8f8f8;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 16px;
    margin: 0 2px 10px 0;}


.file-name {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    color: #333;
}



.opportunity-submission-card button {
    background-color: #000000;
    color: white;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    align-self: flex-end;
    width: auto;
    padding: 10px 15px;
}

.opportunity-submission-card textarea {
    height: 100px;
    padding: 10px 15px;
    background-color: #f8f8f8;
    margin: 0 2px 10px 0;
    font-size: 16px;
    width: 100%;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    resize: vertical;
}

.opportunity-submission-card button:hover {
    background-color: #a87e1c;
}

.submission-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.question-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

p.type-oppo-new {
    padding: 10px 13px;
    font-size: 14px;
}

.oppo-location-wraper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

/* OpportunitySubmissionHeader.css */

.header-container {
    padding-top: 16px;
    padding-bottom: 16px;
    height: max-content;
    width: 100%;
    background-color: #f9fafb;
}

.header-content {
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.step-container {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

.step {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    font-size: 14px;
    font-weight: 500;
}

.step-label {
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 600px) {
    .step-container {
        flex-wrap: wrap;
        gap: 4px;
    }

    .step-container > div {
        flex-basis: calc(50% - 8px); /* Adjust for the gap */
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

    .step-container > div .step,
    .step-container > div .step-label {
        margin-bottom: 0; /* Remove bottom margin */
    }
}
.progress-bar-submission {
    width: 80%;
    background-color: #e5e7eb;
    height: 10px;
    border-radius: 5px;
    margin-top: 20px;
}

.progress-bar-fill {
    height: 100%;
    background-color: #1f2937;
    border-radius: 5px;
}

.submission-container {
    display: flex;
    justify-content: space-between;
}

.opportunity-submission-card .responsive-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: start;
    justify-content: center;
}

@media (max-width: 768px) {
    .opportunity-submission-card .responsive-grid {
        grid-template-columns: 1fr;
    }
}


/* submit_opportunity.css */

.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    font-family: Poppins, Verdana, sans-serif;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #000000;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #000000;
    cursor: pointer;
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
}

.slider-label {
    text-align: center;
    font-size: 14px;
    color: #333;
}

.slider-container input[type="text"] {
    height: 45px;
    padding: 0 15px;
    background-color: #f8f8f8;
    margin: 0 2px 10px 0;
    font-size: 16px;
    width: 100%;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.opportunity-submission-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width: 1024px) {
    .opportunity-submission-flex {
        flex-direction: column;
    }
    .opportunity-submission-flex > :first-child {
        order: 2;
    }
    .opportunity-submission-flex > :last-child {
        order: 1;
    }
}


.opportunity-submission-card-responsive {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-top: 40px;
    width: 20%;
    height: min-content;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-family: Poppins, Verdana, sans-serif;
}

@media (max-width: 1024px) {
    .opportunity-submission-card-responsive {
        width: 100%;
        padding: 15px;
    }

    .opportunity-submission-card-responsive img {
        width: 100px;
        height: 100px;
    }

    .opportunity-submission-card-responsive p {
        text-align: center;
    }

    .opportunity-submission-card-responsive a {
        word-wrap: break-word;
    }
}


.chips {
    display: flex;
    gap: 2px;
    flex-wrap: wrap;
}

.chip {
    margin: 2px;
}

.form-group {
    position: relative;
    margin-bottom: 1.5rem; /* Add some spacing between fields */
}

.error {
    display: block;
    color: #e74c3c; /* Red color for errors */
    font-size: 0.875rem; /* Slightly smaller text */
    margin-top: 0.25rem; /* Small margin above the error message */
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 1000;
}

.popup-button {
    margin-top: 10px;
}

.upload-container {
    display: flex;
    gap: 40px; /* Add space between the checklist and the upload section */
}

@media (max-width: 1024px) {
    .upload-container{
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}


.mandatory-checklist {
    flex: 0 0 400px; /* Fix the width of the checklist */
    border: 1px solid #ddd; /* Add a border around the checklist */
    border-radius: 5px; /* Round the corners */
    padding: 20px; /* Add padding inside the checklist */
    background-color: #f9f9f9; /* Light background color */
}

.mandatory-checklist h3 {
    margin-bottom: 15px;
    font-size: 1.2em; /* Slightly larger heading */
    text-align: center; /* Center the heading */
}

.mandatory-checklist ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mandatory-checklist li {
    display: flex;
    align-items: center;
    gap: 10px; /* Add space between the checkbox and the label */
    margin-bottom: 10px;
}

.mandatory-checklist input[type="checkbox"] {
    width: 18px;
    height: 18px;
}

.upload-section {
    flex: 1; /* Allow the upload section to take up remaining space */
    display: flex;
    flex-direction: column;
    gap: 15px; /* Add gap between dropdown and button */
}

.upload-select {
    width: 100%; /* Make dropdown take full width */
}

.upload-list {
    margin-top: 20px; /* Add spacing between upload section and document list */
}

.upload-item {
    display: flex;
    align-items: center;
    gap: 10px; /* Add some space between items */
    margin-bottom: 10px;
}

.upload-remove-button {
    background: none;
    border: none;
    color: #e74c3c; /* Red color for the remove button */
    cursor: pointer;
    font-size: 16px;
}

.upload-remove-button:hover {
    color: #c0392b; /* Darker red on hover */
}

.custom-file-upload {
    display: inline-block;
    padding: 10px 15px;
    background-color: #f8f8f8;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 14px;
    width: 60%; /* Adjust button width */
    text-align: center; /* Center the text */
}

.custom-file-upload.disabled {
    background-color: #e0e0e0; /* Grey background for disabled state */
    cursor: not-allowed; /* Not-allowed cursor for disabled state */
}

.error {
    color: #e74c3c; /* Red color for errors */
    font-size: 0.875rem; /* Slightly smaller text */
    margin-top: 0.25rem; /* Small margin above the error message */
}

.dropdown-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 50%;
}