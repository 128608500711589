/* Make the chat container take up 100% of the viewport height */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 80vh; /* 100% of the viewport height */
  padding: 20px;
}

/* Chat box should grow and take the available height */
.chat-box {
  flex-grow: 1;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Rest of your styles */
.chat-message-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.chat-bubble-left {
  align-self: flex-start;
  background-color: #e6e6e6;
  max-width: 85%;
  padding: 10px 25px;
  border-radius: 20px;
  margin: 5px 0;
  text-align: left;
}

.chat-bubble-right {
  align-self: flex-end;
  background-color: #4caf50;
  color: white;
  max-width: 85%;
  padding: 10px 15px;
  border-radius: 20px;
  margin: 5px 0;
  text-align: right;
}

.timestamp {
  font-size: 0.8rem;
  color: #000000;
}

/* Chat input stays at the bottom */
.chat-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50px;
  padding: 10px 15px;
}

/* Input styles */
.standard-input {
  width: 100%;
  padding: 10px 15px;
  border: none;
  border-radius: 50px;
  background-color: #f1f1f1;
  font-size: 16px;
  outline: none;
  resize: none;
}

.standard-input:focus {
  background-color: #e6e6e6;
}

.send-icon {
  background-color: #4caf50;
  color: white;
  border-radius: 50%;
  padding: 10px;
}

.send-icon:hover {
  background-color: #45a049;
}

.error {
  color: red;
  margin-top: 10px;
}
