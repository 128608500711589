
.argonStatus{
    border-radius: 6px;
    padding: 3.3px;
    height: 0;
    margin: 6px 5px 0 0;
}
.AragonSmall{
    font-size: 12.8px;
    color: #525F7F;
}
.AragonML-12{
    margin-left: 16px; 
}
.AragonPaddiing .AragonFlex{
    margin-top: 5px;
}
.AragonPaddiing .AragonML-12{
    margin-top: 4px;
}
.AragonAddButton{
    padding: 4px 8px;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    border: 1px solid #5e72e4;
    color: #fff;
    background: #5e72e4;
    border-radius: 4px;
    margin-top: 12px;
    cursor: pointer;
}
.AragonAddButton:hover{
    position: relative;
    top: -1px
}
.AragonPaddiing .AragonML-12 a:hover{
    color: #5e72e4;
}