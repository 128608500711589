.UbaidSideNavLinks{
    display: block;
    margin: 0 4px;
}
.Argonborder{
    border-top: 1px solid rgba(82, 95, 127, 0.1);
    margin: 12px 24px 8px 24px;
    padding-top: 14px;
    color: #8898aa;
    font-size: 12px;
}