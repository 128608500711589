.inputCheckBox input{
    border: none;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: -1;
  }
  .inputCheckBox{
    display: flex;
    cursor: pointer;
  }
  .inputCheckBox div{
    margin: auto;
  }
  .viewCheckBox{
    border: 1px solid #e6ecf5;
    text-align: center;
    width: 20px;
    border-radius: 2px;
    height: 20px;
    overflow: hidden;
    margin-right: 8px !important;
  }
  .viewCheckBox i{
    margin: 0;
    padding: 0;
    font-size: 16px;
    width: auto;
    height: auto;
    color: #ff5e3a;
    font-weight: normal;
    opacity: 0;
    transition: 0.15s all linear;
  }