.PostCardsNewsFeedHeader{
    display: flex;
    font-size: 13px;
}
.PostCardsNewsFeedHeader .mainContent {
  flex-grow: 1;
}
.PostCardsNewsFeedHeader a{
  font-size: 14px;
  margin-right: 5px;
}
.PostCardsNewsFeedHeader .optionsBtn {
  width: 30px;
  text-align: center;
  cursor: pointer;
  border-radius: 30px;
  height: 30px;
  position: relative;
}
.PostCardsNewsFeedHeader .optionsBtn:hover {
  background: #F4F2EE;
}
.PostCardsNewsFeedHeader svg{
  width: 15px;
  cursor: pointer;
  opacity: 0.7;
}
.PostChildrenNewsFeed{
    margin: 12px 0;
}
.NewsFeedPost1{
    display: flex;
}
.PostCardsNewsFeedHeaderWithBorder{
    margin: 16px -24px -24px -24px;
    padding: 24px;
    border-top: 1px solid rgb(154, 159, 191, 0.3);
    background: #f7f9fc;
}
.PostCardHeaderFooter svg{
    margin-right: 10px;
}
.newsfeed .PostCardHeaderFooter a{
    font-weight: normal;
    margin-right: 16px;
}
.PostCardHeaderFooter a:hover svg{
    fill: #FF5E3A;
}
.socialDropDownMenu {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background: white;
  border-radius: 5px;
  padding: 5px 0;
}
.socialDropDownMenu.active {
  display: block;
}
.socialDropDownMenu > div {
  padding: 5px 17px;
  cursor: pointer;
}
.socialDropDownMenu > div.warning {
  color: crimson;
}
.socialDropDownMenu > div:hover {
  background-color: #b7d4ea;
  color: white;
}
