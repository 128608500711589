/* (2019-06-10) - The Css for the profile page.
*/
.App {
  text-align: center;
}

.body {
  background: #eee
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.stripeButton {
  background-image: url("../../img/light-on-dark.png");
  width: 200px;
  height: 50px;
  background-size: 100%
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tag-pill {
  border-radius: 20px !important;
  font-weight: 500;
  opacity: 0.7;
}

.tag-pill:hover {
  opacity: 1.0
}

.card-image-container {
  background-size: cover;
  height: 400px;
  background-position: center;
}

.main {
  background-color: #fff;
  padding: 20px;
  text-align: left
    /*width: 70%*/
}

.logo {
  border-radius: 10px;
  display: "flex";
  justify-content: "center";
}

.logo>img {
  width: 180px;
  height: 180px;
  padding: 10px;
}

.dark-nav {
  padding: 10px;
  background: #000;
  width: 100%;
}

.menu-item:hover {
  border-top: 2px solid red;
  color: red;
}

.video-card {
  margin: 10px 0px 10px 0px;
  border-width: 0px 0px 5px 0px;
  border-style: solid;
  border-color: #ffdc00;
}

.post-revenue {
  background-color: #81d742 !important;
  margin: 0px 0px 10px 0px !important;
  border-width: 0px 0px 0px 0px !important;
  border-style: solid !important;
  color: #ffffff !important;
  border-radius: 6px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  text-align: center !important;
  padding: 15px 20px 15px 20px !important;
}

.title {
  /*padding-top:30px;*/
  text-align: center;
}

.amount-card {
  /*margin: 10px 0px 10px 0px;*/
  border-width: 0px 0px 5px 0px !important;
  border-style: solid !important;
  border-color: #ff4136 !important;
  /*box-shadow: 0 3px 6px rgba(0,0,0,0.16), */
  border-radius: 6px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;

}

.amount-card-body {
  padding-top: 80px !important;
  margin: 0 !important;
}

.amount-price {
  color: green !important;
  font-size: 1.8rem !important;
  padding: 10px !important;
}

.month-card {
  border-width: 0px 0px 5px 0px !important;
  border-style: solid !important;
  border-color: #ff4136 !important;
  /*box-shadow: 0 3px 6px rgba(0,0,0,0.16), */
  border-radius: 6px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.amount-month {
  color: #ff851b !important;
  font-size: 1.8rem !important;
  padding: 10px !important;
}

.entrepreneurs-card {
  border-width: 0px 0px 5px 0px !important;
  border-style: solid !important;
  border-color: #ff4136 !important;
  /*box-shadow: 0 3px 6px rgba(0,0,0,0.16), */
  border-radius: 6px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.StartupFuel-card {
  border-width: 0px 0px 5px 0px !important;
  border-style: solid !important;
  border-color: #ff4136 !important;
  /*box-shadow: 0 3px 6px rgba(0,0,0,0.16), */
  border-radius: 6px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.gallery {
  padding: 6px;
}

.tap-right {
  float: right;
}

.nav-item {
  margin: 3px;
}

@media only screen and (max-width: 1200px) {
  .logo {
    /*margin-top: -80px;*/
    border-radius: 50%;
    /*position: relative;*/
    left: 36%;
    /*width:100%;*/
  }

  .head {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 575.98px) {
  .logo {
    /*margin-top: -80px;*/
    border-radius: 50%;
    /*position: relative;*/
    left: 25%;
    /*width:100%;*/
  }

  .head {
    margin-top: 100px;
  }
}

.not-clicked {
  color: #007bff;
  position: absolute;
  top: 10px;
  right: -10px;
  font-size: 16px;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer
}

.clicked {
  color: #007bff;
  background: #E0E0E0;
  border: 1px solid #007bff;
  border-radius: 20%;
  position: absolute;
  top: 10px;
  right: -10px;
  font-size: 16px;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}

.img-card-icon {
  color: #007bff;
  position: absolute;
  /*top:1px;*/
  margin-top: -40px;
  right: 10px;
  font-size: 16px;
  margin-left: 10px;
  padding: 10px;
  cursor: pointer
}

.img-card-icon-clicked {
  color: #007bff;
  background: #E0E0E0;
  border: 1px solid #007bff;
  border-radius: 20%;
  position: absolute;
  /*top:1px;*/
  margin-top: -40px;
  right: 10px;
  font-size: 16px;
  margin-left: 10px;
  padding: 10px;
  cursor: pointer
}

.amount-card-icon {
  color: #007bff;
  position: absolute;
  /*top:1px;*/
  /*margin-top:1px;*/
  right: 8px;
  font-size: 16px;
  margin-left: 8px;
  padding: 10px;
  cursor: pointer
}

.amount-card-icon-clicked {
  color: #007bff;
  background: #E0E0E0;
  border: 1px solid #007bff;
  border-radius: 20%;
  position: absolute;

  /*top:1px;*/
  /*margin-top:1px;*/
  right: 8px;
  font-size: 16px;
  margin-left: 8px;
  padding: 10px;
  cursor: pointer
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@-webkit-keyframes check {
  0% {
    height: 0;
    width: 0;
  }

  33.3333% {
    width: 40px;
    height: 0;
  }

}

@-moz-keyframes check {
  0% {
    height: 0;
    width: 0;
  }

  33.3333% {
    width: 40px;
    height: 0;
  }
}

@-ms-keyframes check {
  0% {
    height: 0;
    width: 0;
  }

  33.3333% {
    width: 40px;
    height: 0;
  }
}

@keyframes check {
  0% {
    height: 0;
    width: 0;
  }

  33.3333% {
    width: 40px;
    height: 0;
  }
}


.checkmark {
  display: block;
  height: 200px;
  position: relative;
  width: 80px;
}


.checkmark:after {
  -webkit-animation: check .8s;
  -moz-animation: check .8s;
  -o-animation: check .8s;
  animation: check .8s;
}

.checkmark:after {
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -webkit-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-right: 20px solid #66ff99;
  border-top: 20px solid #66ff99;
  content: '';
  display: block;
  height: 80px;
  left: 14px;
  position: absolute;
  top: 100px;
  width: 40px;
}

.profileImage {
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 10px;
  align-items: center;
  overflow: hidden;
  height: 36px;
  width: 36px;
  border-radius: 60px;
  background: white;
  display: block;
}

.profileImage.large {
  height: 50px;
  width: 50px;
}

.profileImage.tight {
  margin: 0;
}

.profileImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background: none !important;
}

.profileImage img:not([src]) {
  display: none;
  visibility: hidden;
}

.profilePic-container.profile-new-design-logo {
  padding: 15px;
}

.pc-section.bg-white.p-3.mt-4.rounded {
  border-radius: 10px !important;
}

.profile_selection .companyNavBarOverflow.bg-white.py-2.text-black {
  height: 5rem !important;
  border-radius: 10px;
}

.MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label.Mui-selected {
  font-size: 21px;
}

.MuiBottomNavigationAction-root.Mui-selected {
  padding-top: 12px!important;
  color: #5A5858 !important;
  font-weight: 900;
  font-size: 18px !important;
}

.profile_selection .MuiBottomNavigationAction-label {
  opacity: 1;
  font-size: 19px !important;
  font-weight: 500;
}
.company-oppo-tabs-design .MuiBottomNavigationAction-root.Mui-selected {
  padding-top: 6px!important;
}

button.MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label {
  font-weight: 900 !important;
}

.profilePic-container {
  width: 100%;
}
.ubaidCompanyBasicDetails span {
  font-size: 29px!important;
}
.social-icon-profile-page svg {
  color: #5A647380;
  font-size: 28px;
  margin-right: 14px;
}
.share-icon-profile-page {
  padding-left: 9px;
  margin-left: 9px;
}
.share-icon-profile-page p{
  margin-bottom: 0px;
  cursor: pointer;
}
.profile-share-popup{
  max-width: 50rem;
}

.social-icon-profile-page {
  margin-top: 15px;
  margin-bottom: 12px;
}
span.my-3.link-website-profile a {
  color: #DA1C24;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
span.my-3.link-website-profile a svg {
  font-size: 28px;
  transform: rotate(45deg);
}
span.my-3.link-website-profile p {
  font-size: 17px;
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 0px;
  color: #5A6473;
}
.btn-message-founder-wraper-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 14px;
  border-top: 1px solid #5A647326;
  padding-top: 24px;
  border-bottom: 1px solid #5A647326;
  padding-bottom: 17px;
}
.profile-page-new-design-right button.UbaidFollowBtn {
  background: #202131;
  color: #fff;
  border-radius: 10px;
  height: 45px;
  outline: none;
  border: 0px;
  box-shadow: none;
  padding-right: 12px!important;
}
.ubaidFollowComapny span {
  border-right: 0px!important;
  padding: 5px 0px;
  color: #9e9e9e;
  text-transform: uppercase;
  width: auto;
  font-size: 14px;
  padding-right: 12px;
}
.UbaidFollowCompanyCon {
  margin-top: 18px;
}
.ExpUbaidDupSocalIconsCon i {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
  opacity: 0.9;
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 10px 0;
  border-radius: 44px;
  cursor: pointer;
  font-size: 17px;
  background: #202131!important;
  color: #fff;
}
.profile-page-new-design-right a.btn.btn-inherit {
  background: transparent!important;
  border: 1px solid #5A647326;
  color: #5A6473E5 !important;
  box-shadow: none;
  border-radius: 10px;
  padding: 8px 24px;
  font-weight: 600;
}
p.company_short_bio_Ubaid {
  text-align: left;
  margin-top: 24px;
  margin-bottom: 0px;
  font-size: 17px;
  color: #5A6473;
}
p.animate-first {
  border: 1px solid #5A647326;
  width: fit-content;
  padding: 8px 25px;
  border-radius: 10px;
  margin: 0 auto;
  font-size: 14px;
}
.UbaidComSubTitle {
    margin-left: 0px!important;
    margin-top: 15px;
}
.profile_content {
  width: 100%!important;
}
.pc-section {
  width: 30%;
  height: fit-content;
}
button.MuiButtonBase-root.MuiBottomNavigationAction-root.invest-profile-page {
  background: linear-gradient(254.17deg, #000000 -7.34%, #C49733 41.96%, #C49733 63.34%, #000000 111.45%);
  color: #FFFFFFE5;
  text-transform: uppercase;
  border-radius: 9px;
  padding: 0px 28px;
  height: 61px;
}
.card-image-container {
  background-size: cover;
  height: 500px;
  background-position: center;
  border-radius: 10px!important;
}
button.MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label {
  font-weight: bold!important;
}
.bg-white.my-2.p-2.profile-card-description-wraper {
  border-radius: 10px;
}
.video-card-body.profile-card-description.card-body h4 {
  font-size: 32px;
  margin-bottom: 25px;
}
.video-card-body.profile-card-description.card-body p {
  font-size: 17px;
  margin-bottom: 3px;
}
.Description-border{
  text-align: left;
}
.card-title-border hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 7px solid #000339;
  width: 84px;
  text-align: left;
  margin: 0;
  margin-bottom: 28px;
  border-radius: 14px;
}
.calender-icon-profile {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid #5A647326;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.calender-icon-profile svg {
  font-size: 28px;
  color: #DA1C24;
}
.profile-card-text-color {
  color: #7B8087;
  font-size: 25px;
  font-weight: 400;
  margin-top: 21px;
  margin-bottom: 0px;
}
.main-under-des-row-wraper {
  background: #fff;
  border-radius: 10px;
  padding: 40px 1.25rem;
}
.main-under-des-row-wraper .card{
  box-shadow: none!important;
}
.main-under-des-row-wraper .card-body {
  border: 3px solid #5A647326;
  border-radius: 10px;
  flex: none;
  padding: 0px;
}
.main-under-des-row-wraper .month-card{
  border: 0px!important;
}
.amount-month p {
  background: linear-gradient(269.17deg, #712B21 7.76%, #DF801E 51.78%, #E72024 94.89%);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 0px;
}
.main-under-des-row-wraper .amount-card-body {
  padding-top: 25px !important;
  margin: 0 !important;
}
.profile_selection .MuiGrid-spacing-xs-8{
  margin: 0px;
}
.profile_selection .MuiGrid-spacing-xs-8 > .MuiGrid-item {
  padding: 8px;
  width: 100%;
}
.profile_selection .MuiFormControl-root.MuiTextField-root.CustomizedTable-textField-82.MuiFormControl-marginNormal {
  width: 88%!important;
}
.profile_selection .MuiFormControl-marginNormal {
  margin-top: 16px;
  width: 88%!important;
  margin-bottom: 4px;
}
.profile-page-new-design-right .Ubaid_Company_Rank_Input_Con {
  display: block;
  width: 100%;
}

.profile-page-new-design-right .ubaidInputIcon {
  height: 44px !important;
}
.profile-page-new-design-right .ubaidInput {
  height: 44px;
}

@media (max-width:500px) {
  button.__company-share {
     display: none!important;
 }
 .ubaidCompanyBasicDetails {
   margin: -94px 0 0 0 !important;
 }
 p.animate-first {
   margin-top: 15px!important;
 }
 .social-icon-profile-page {
   margin-bottom: -15px;
 }
 .pc-section.bg-white.p-3.mt-3.rounded {
   padding: 30px 15px !important;
 }
 .UbaidFollowCompanyCon {
   flex-wrap:nowrap;
 }
 .card-image-container {
   height: 300px;
 }
 .profile_content {
   padding: 7px!important;
 }
 .ubaidFollowComapny div {
   width: 78%;
 }
 .profile_selection .MuiBottomNavigationAction-label {
   font-size: 17px !important;
 }
 .ubaidFollowComapny span {
   width: 100%;
   padding-right: 0px!important;
 }
 .video-card-body.profile-card-description.card-body h4 {
   font-size: 24px;
 }
 .Description-border hr {
   border-top: 4px solid #000339;
   width: 69px;
 }
 .main-under-des-row-wraper {
   padding: 25px 7px;
   padding-bottom: 0px;
 }
 .profile-card-text-color {
   font-size: 21px;
 }
 .amount-month p {
   font-size: 20px;
 }
 .main-under-des-row-wraper .amount-card-body {
   margin-bottom: 25px!important;
 }
 .amount-month {
   padding-top: 0px!important;
 }
 button.MuiButtonBase-root.MuiBottomNavigationAction-root.invest-profile-page {
   padding: 0px 25px;
   height: 47px;
 }
 .profile-page-new-design-right a.btn.btn-inherit {
   width: max-content;
 }
 .ubaidMainButton>a {
   flex: none!important;
 }
 .btn-follow-profile {
  width: 67%;
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  padding-left: 18px;
  display: flex;
  justify-content: end;
}
.ubaidFollowComapny {
  padding-right: 0px;
  justify-content: center;
  align-items: center;
}
.video-card.contact-information.card {
  width: 108%!important;
  margin-left: -12px;
}
.profile-card-description{
  padding: 25px 8px!important;
}

 }

 .ubaidFollowComapny {
  padding-right: 0px;
  justify-content: center;
  align-items: center;
}

 .btn-follow-profile {
  width: 100%;
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  padding-left: 18px;
}
.main-wraper-user-profile-box {
  padding-left: 25px;
  padding-right: 25px;
}
.row.ubaidRow.main-under-des-row-wraper.mb-2 {
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width:500px) {
  .navbar .navbar-brand img{
    margin-left: 10px;
  }
  .row.ubaidRow.main-under-des-row-wraper.mb-2 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .main-wraper-user-profile-box {
    padding-left: 0px;
    padding-right: 0px;
  }
  .bg-white.rounded-lg.p-4.contact-information {
    padding: 25px 15px!important;
}
p.company_short_bio_Ubaid {
  margin-top: 5px;
}
.contact-information .px-4{
  margin: 0px!important;
  padding: 0px!important;
}
  button.__company-share {
     display: none!important;
 }
 .ubaidCompanyBasicDetails {
   margin: -94px 0 0 0 !important;
 }
 p.animate-first {
   margin-top: 15px!important;
 }
 .social-icon-profile-page {
   margin-bottom: -15px;
 }
 .pc-section.bg-white.p-3.mt-3.rounded {
   padding: 30px 15px !important;
 }
 .UbaidFollowCompanyCon {
   flex-wrap:nowrap;
 }
 .card-image-container {
   height: 300px;
 }
 .profile_content {
   padding: 0px!important;
 }
 .__profile_page_container{
  margin: 0px!important;
 }
 .profile_content.ml-4 {
  margin-left: 0px!important;
 }
 .ubaidFollowComapny div {
   width: 78%;
 }
 .profile_selection .companyNavBarOverflow.bg-white.py-2.text-black {
  height: 3rem !important;
}
.profile-card-description {
  padding: 0px 0px!important;
}
.bg-white.my-2.p-4.profile-card-description-wraper.rounded-lg {
  padding: 25px 15px!important;
}
.profile_selection .mb-2.p-4.text-left.bg-white.rounded-lg {
  padding: 25px 15px!important;
}
.profile_selection .card-title-border.p-4 {
  padding: 0px!important;
}
 .profile_selection .MuiBottomNavigationAction-label {
   font-size: 17px !important;
 }
 .main-wraper-user-profile-box {
  padding-bottom: 0px;
  display: block!important;
}
.main-wraper-user-profile-box .box-date-p-m {
  width: 100%!important;
}.main-wraper-user-profile-box .box-date-p-m:last-child{
  margin-bottom: 0px;
}
.margin-mobile-01{
  margin-bottom: 15px;
  margin-top: 10px;
}
.main-wraper-user-profile-box{
  padding-bottom: 0px!important;
}
.card-title-border hr {
  border-top: 5px solid #000339;
  width: 65px;
  margin-bottom: 17px;
}
.pc-section {
  text-align: center;
  width: 100%!important;
  margin: 0 auto;
  padding: 15px!important;
  margin: 0px!important;
  margin-top: 8px!important;
}
.ubaidBox.main-under-des-box {
  width: 100%!important;
}
.margin-mobile {
  margin-bottom: 15px;
  margin-top: 15px;
}
.main-under-des-box {
  width: 100%!important;
}
.row.ubaidRow.main-under-des-row-wraper.mb-2 {
  padding: 25px 15px;
}
 .ubaidFollowComapny span {
   width: 100%;
   padding-right: 0px!important;
 }
 .video-card-body.profile-card-description.card-body h4 {
   font-size: 24px;
 }
 .Description-border hr {
   border-top: 4px solid #000339;
   width: 69px;
 }
 .main-under-des-row-wraper {
   padding: 25px 7px;
   padding-bottom: 0px;
 }
 .profile-card-text-color {
   font-size: 21px;
 }
 .amount-month p {
   font-size: 20px;
 }
 .main-under-des-row-wraper .amount-card-body {
   margin-bottom: 15px!important;
 }
 .main-under-des-row-wraper .amount-card-body:last-child {
  margin-bottom: 0px!important;
 }
 .products-card.bg-white.rounded-lg.mb-2.p-5 {
  padding: 25px 15px!important;
}
.company-headq-main-wraper {
  padding: 25px 15px!important;
}
.company-headq-main-wraper .card-title-border.px-5.py-5 {
  padding: 0px!important;
}
.profile-card-description.card-body {
  padding: 0px!important;
}

 .amount-month {
   padding-top: 0px!important;
 }
 button.MuiButtonBase-root.MuiBottomNavigationAction-root.invest-profile-page {
   padding: 0px 25px;
   height: 47px;
 }
 .profile-page-new-design-right a.btn.btn-inherit {
   width: max-content;
 }
 .ubaidMainButton>a {
   flex: none!important;
 }
 .btn-follow-profile {
  width: 67%;
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  padding-left: 18px;
  display: flex;
  justify-content: end;
}
.ubaidFollowComapny {
  padding-right: 0px;
  justify-content: center;
  align-items: center;
}
.video-card.contact-information.card {
  width: 108%!important;
  margin-left: -12px;
}
.profile-card-description{
  padding: 25px 8px!important;
}

 }

 .ubaidFollowComapny {
  padding-right: 0px;
  justify-content: center;
  align-items: center;
}

 .btn-follow-profile {
  width: 100%;
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  padding-left: 18px;
}

@media screen and (max-width: 992px) {
  .profile_content{
    margin-left: 0px !important;
  }

  .card-image-container {
    background-size: cover;

    width: 100%;
    height: 200px;
    background-position: center;
    border-radius: 0px!important;
  }
}

@media screen and (max-width: 992px) {
  .profile_content{
    margin-left: 0px !important;
  }

  .card-image-container {
    background-size: cover;

    width: 100%;
    height: 200px;
    background-position: center;
    border-radius: 0px!important;
  }
}
.main-wraper-user-profile-box{
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
.main-wraper-user-profile-box .box-date-p-m {
  border: 3px solid #5A647326;
  border-radius: 10px;
  flex: none;
  padding: 20px;
  width: 49%;
  position: relative;
}
.main-wraper-user-profile-box .card-title{
  margin-bottom: 0px;
  padding: 0px!important;
}
.main-wraper-user-profile-box .card-body {
  padding: 0px;
}
.row.ubaidRow.main-under-des-row-wraper.mb-2 {
  justify-content: space-between;
  display: flex;
}
.ubaidBox.main-under-des-box {
  width: 32%;
}
.main-under-des-box {
  width: 32%;
}
.pc-section.bg-white.rounded-lg.profile-right-section-main-w {
  margin-top: 22px;
  margin-left: 20px;
  padding: 20px;
}
.navbar.navbar-dark .navbar-toggler-icon {
  filter: invert(1);
}