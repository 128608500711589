.exploreCard {
  flex-grow: 1;
  margin-bottom: 15px;
}
.exploreCardAvtar {
  min-width: 40px;
  height: 40px;
  background-size: cover;
  border-radius: 32px;
}
.exploreCardHeader {
  padding: 12px;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
}
.exploreCardTitle {
  margin-left: 12px;
  flex-grow: 100;
  overflow: hidden;
}
.exploreCardTitle a {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87) !important;
  display: block;
  height: 25px;
  overflow: hidden;
  font-size: 16px;
  margin-bottom: 3px;
}
.exploreCardContent {
  height: 150px;
  overflow-y: scroll;
  margin: 16px;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.OopSerToolCardContent {
  margin: 0;
  color: black !important;
  min-height: 184px;
  font-size: 16px;
}
.OopSerToolCardFooterBtn {
  margin: auto !important;
}
.OopSerToolCardFooterBtn a {
  color: #fff;
}
.OopSerToolCardFooter {
  padding: 8px !important;
  margin-right: auto !important;
}
.exploreCardContent::-webkit-scrollbar {
  width: 5px;
}
.exploreCardContent::-webkit-scrollbar-thumb {
  background: rgba(179, 174, 174, 0.7);
}
.exploreCardMedia {
  position: relative;
  height: 0;
  padding-top: 56.25%; /* 16:9 */
}
.exploreCardTags {
  padding: 1em 0.7em 0.7em;
  display: flex;
  background-color: white;
  border-bottom: 1px solid #eee;
}
.exploreCardTags .allTags {
  height: fit-content;
  flex-grow: 1;
  overflow: hidden;
}
.exploreCardTags.expanded .allTags {
  height: auto;
}
.exploreCardTags.expanded .expandTags {
  transform: rotate(180deg);
}
.exploreCardTags .expandTags {
  height: 32px;
  flex-shrink: 1;
}
.exploreCardTags .exploreTag {
  margin: 0 5px 5px 0;
}
.exploreCardTags .exploreTag span {
  font-weight: 500;
}
.exploreCardTags .exploreTag.blueHl {
  color: #3f51b5;
  border: 1px solid #3f51b5;
}
.exploreCardTags .exploreTag.redHl {
  color: #db4437;
  border: 1px solid #db4437;
}
.exploreCardTags .exploreTag.greenHl {
  color: #0f9d58;
  border: 1px solid #0f9d58;
}
.exploreCardTags .exploreTag .greyHl {
  color: #eee;
  border: 1px solid #ccc;
}
.ExpUbaidDupSocalIconsCon {
  display: flex;
}
.exploreCard .UbaidDupSocalIcons {
  display: flex;
  margin-left: auto;
}
.ExpUbaidDupSocalIconsCon {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.ExpUbaidDupSocalIconsCon i {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
  background: #2bbbad;
  opacity: 0.9;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 10px 0;
  border-radius: 44px;
  cursor: pointer;
  font-size: 17px;
}
.ExpUbaidDupSocalIconsCon i:hover {
  opacity: 1;
}
.ExpUbaidDupSocalIconsCon .first {
  margin-left: auto;
}
.MainExploreCardUBaid {
  display: flex;
  flex-direction: column;
  height: calc(100% - 15px);
}
