.profileLikes {
  display:unset;
}
.PostFooterNewsFeed{
    margin-top: 20px;
    display: flex;
    font-size: 12px;
}
.PostSocialIcons a {
  margin-right: 25px;
  cursor: pointer;
  line-height: 28px;
}

.PostSocialIcons a:hover {
  color: crimson;
}
.PostSocialIcons a:hover svg{
  fill: crimson;
}
.PostSocialIcons a.active {
  color: #FF5E3A !important;
}
.PostSocialIcons a.active svg{
  fill: #FF5E3A !important;
}

.PostSocialIcons img{
    height: 28px;
    width: 28px;
    border-radius: 28px;
    border: 2px solid white;
    position: absolute;
}
.PeopleWhoLikedNews{
    width: 140px;
    padding: 0 16px;
}
.PostSocialIcons svg {
    margin-right: 9px;
    width: 22px;
    fill: #9A9FBF;
}
.PeopleWhoLikedNewsImg{
    width: 92px;
    position: relative;
    margin: 0 16px;
}
.PeopleWhoShares{
    margin-left: auto;
}
.PeopleWhoShares a{
    margin-left: 16px;
}

@media only screen and (max-width: 510px){
    .PeopleWhoLikedNewsImg{
        height: 26px;
        margin: 2px 0
    }
}
