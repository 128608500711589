.opp-create-btn {
  text-align: center;
}

.opp-card-horz {
  position: relative;
  margin: 13px 0;
  overflow: hidden;
}
.opp-card-content {
  font-size: 0.9rem;
  /*margin-left: 150px;*/
  padding: 13px;
}
.opp-card-title {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
}
.ubaidopp-card-content{
  margin-left: 0 !important;
}
.opp-card-description {
  font-size: 1.2rem;
  margin: 0;
  margin-top: 0.5rem;
  color: #888;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2rem;
  line-height: 1rem;
}
.card__bottom {
	display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	align-content: center;
	-webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
	border-top: 1px solid #efefef;
  text-align: right;
	padding-top: 0.5rem;
	margin-top: 1rem;
	font-size: 0.8rem;
	color: #888;
}
.card__price {
  color: #E35354;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
}
@media only screen and (max-width: 370px){
  .ubaidopp-card-content{
    padding: 0;
  }
  .MuiBottomNavigation-root-231 *{
    min-width: auto !important;
    padding: 0 !important;
    font-size: 13px !important;
  }
  .MuiBottomNavigation-root-231 svg{
    font-size: 22px !important;
  }
}