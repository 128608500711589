.NewsFeedResult{
    display: flex;
    padding: 24px;
    border-bottom: 1px solid rgb(154, 159, 191, 0.3);
    line-height: 18px;
}
.NewsFeedResult *{
    color: #9A9FBF;
    font-weight: normal;
}
.NewsFeedResult p{
    margin-top: 12px;
    display: block;
    font-size: 11px;
    margin: 0 0 0 0;
}
.backgroundImage{
    min-width: 42px;
    height: 42px;
    background-size: cover;
    border-radius: 42px;
    margin-right: 13px;
}
.newsfeedIcons{
    margin-left: auto;
    line-height: normal;
}
.newsfeedIcons svg{
    margin: 0;
    width: 20px;
    fill: rgb(154, 159, 191, 0.6);
}
.NewsFeedResult:hover{
    background-color: rgba(0, 255, 0, 0.022);
    color: #515363 !important
}
.NewsFeedResult a{
    margin: 2px 2px 0 0;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
}
.NewsFeedResult a:hover{
    color: #FF5E3A !important
}