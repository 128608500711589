.toggle-button {
    display : flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 32px;
    background:transparent;
    border : none;
    padding : 0;


    &:focus {
        outline:none;

    }

    &__line {
        background : white;
        width: 30px;
        height : 2px;

    }


}