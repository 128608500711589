@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

* {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    color: #212730;
    line-height: 1.8em;
}

.Invited_landing {
    overflow: auto;
    padding: 0.1px;
    background-color: #f4f4f4;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 2em;
        background-color: #000;
        color: #fff;

        &-logo img {
            max-width: 200px;
            width: 100%;
            filter: drop-shadow(2px 2px 1px #000000);
        }

        &-menu a {
            padding: 15px;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            margin-left: 10px;
        }
    }

    &__hero {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: url("../../img/bg.png") no-repeat center center;
        background-size: cover;
        padding: 5em 2em;
        color: #fff;
        text-align: center;

        &-text {
            flex: 1;
            padding: 1em;

            &-title {
                font-size: 3em;
                font-weight: 700;
                color: #C49733;
                margin-bottom: 1em;
            }

            &-subtitle {
                font-size: 1.2em;
                margin-bottom: 1em;
                color: #ffffff; // Change to a more contrasting color
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); // Add text shadow for better visibility
            }

            &-body {
                font-size: 1em;
                margin-bottom: 1.5em;
                max-width: 600px;
                margin: auto;
                color: #ffffff; // Change to a more contrasting color
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); // Add text shadow for better visibility
            }

            .btn {
                padding: 10px 20px;
                margin: 10px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                font-size: 1em;
                transition: background-color 0.3s ease;

                &--gold {
                    background-color: #C49733;
                    color: #fff;
                }

                &--white {
                    background-color: #999;
                    color: #000000;
                }
            }
        }
    }

    &__signup {
        padding: 2em 1em;
        text-align: center;
        margin: 2em auto;
        max-width: 600px;
        border-radius: 10px;

        &-form {
            margin-top: 1em;

            input {
                width: 100%;
                padding: 1em;
                margin: 0.5em 0;
                border: 1px solid #ddd;
                border-radius: 5px;
                font-size: 1em;
            }

            button {
                width: 100%;
                padding: 1em;
                background-color: #0b3866;
                color: #fff;
                border: none;
                border-radius: 5px;
                font-size: 1em;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #084080;
                }
            }
        }
    }

    &__footer {
        background: #000;
        color: #fff;
        text-align: center;
        padding: 2em 1em;
        font-size: 0.8em;

        &-logo {
            max-width: 150px;
            margin: auto;
        }

        &-links,
        &-social {
            margin: 1em 0;

            a {
                color: #fff;
                margin: 0 10px;
                text-decoration: none;
                font-size: 1.2em;
                transition: color 0.3s ease;

                &:hover {
                    color: #C49733;
                }
            }
        }
    }
}