.ubaidCenter{
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin: auto;
}
.ubaidStars{
    display: flex;
    margin-left: 10px;
    justify-content: space-between;
    margin: auto;
}
.ubaidMarginTop{
    margin-bottom: 0;
}
.ubaidPara{
    text-align: left;
    margin-top: 10px;
}
.ubaidComment{
    display: block;
    margin-bottom: 0;
    padding: 0 12px;
}
.comment-img, .comment-text{
    display: inline-block
}
.ubaidPara p{
    margin-bottom: 10px;
}

@media only screen and (max-width: 1150px){
    .ubaidComment{
        padding: 0;
    }
    .ubaidFontSizes{
        font-size: 11px;
    }
}
@media only screen and (max-width: 600px){
    .ubaidCenter{
        width: 95%;
    }
}
@media only screen and (max-width: 370px){
    .ubaidFontSizes{
        font-size: 12px;
    }
}
@media only screen and (max-width: 330px){
    .comment-text{
        font-size: 10px !important;
        padding-left: 3px !important;
        padding-right: 3px !important;
    }
    .comment-img{
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
    .comment-img img{
        width: 32px;
        height: 32px;
    }
}