.UbaidCreatePitchOpp{
    display: flex;
    flex-direction: column;
}
.SocialOnboarding{
    padding-left: 70px;
}
.UbaidCreatePitchOpp .simpleExploreCard{
    flex-grow: 100;
    display: flex;
    flex-direction: column;
}
.UbaidCreatePitchOpp .createOppCardAction{
    margin: auto 0 0 auto;
}
.UBaidSelectMonthOpp{
    width: 100%;
    display: flex;
    justify-content: center;
}
.UBaidMonthCard{
    width: 400px;
    margin: 12px 16px;
}
.UBaidSelectMonthOpp .UbaidUnfollow,
.UBaidSelectMonthOpp .ubaidFollow{
    width: 50%;
    margin: 0 7px !important;
    cursor: pointer;
}
.UBaidSelectMonthOpp .UbaidUnfollow *{
    color: #fff !important;
}
.UbaidCreatePitchOpp{
    display: flex;
    flex-direction: column;
}
.UbaidCreatePitchOpp .simpleExploreCard{
    flex-grow: 100;
    display: flex;
    flex-direction: column;
}
.UbaidCreatePitchOpp .createOppCardAction{
    margin: auto 0 0 auto;
}
.UBaidSelectMonthOpp{
    width: 100%;
    display: flex;
    justify-content: center;
}
.UBaidMonthCard{
    width: 400px;
    margin: 12px 16px;
}
.UBaidSelectMonthOpp .UbaidUnfollow,
.UBaidSelectMonthOpp .ubaidFollow{
    width: 50%;
    margin: 0 7px !important;
    cursor: pointer;
}
.UBaidSelectMonthOpp .UbaidUnfollow *{
    color: #fff !important;
}
.UBaidOppCardInOppPage{
    width: 320px;
    margin: 12px auto 0 auto;
}
.Ubaidform_file_upload{
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.16);
    margin: auto;
    background: #fff;
    padding: 16px;
}
.UBaidSelectFollowUn{
    margin: 0 -7px 0 7px;
    justify-content: space-evenly;
}
.UBaidSelectFollowUn.UBaidSelectMonthOpp .ubaidFollow,
.UBaidSelectFollowUn.UBaidSelectMonthOpp .UbaidUnfollow{
        width: 40%;
        padding-bottom: 16px !important;
}
@media only screen and (max-width:800px){
    .UBaidSelectFollowUn.UBaidSelectMonthOpp .ubaidFollow,
    .UBaidSelectFollowUn.UBaidSelectMonthOpp .UbaidUnfollow{
        width: auto;
    }
}
@media only screen and (max-width: 600px){
    .UBaidMonthCard{
        width: auto;
        margin: 0 0 12px 15px;
    }
    .UBaidSelectMonthOpp{
        display: block;
    }
    .SocialOnboarding{
        padding-left: 0;
    }
    .UBaidSelectMonthOpp .UbaidUnfollow,
    .UBaidSelectMonthOpp .ubaidFollow{
        width: auto;
        margin: 12px 7px !important;
    }
    .Ubaidform_file_upload{
        margin: 16px 0 12px 16px;
    }
    .UBaidOppCardInOppPage{
        margin: 0 2px 0 14px;
        width: 100%;
    }
    .UBaidSelectFollowUn{
        margin: 12px !important;
    }
    .UBaidSelectFollowUn.UBaidSelectMonthOpp .ubaidFollow,
    .UBaidSelectFollowUn.UBaidSelectMonthOpp .UbaidUnfollow{
        width: 100%;
        margin-bottom: 20px !important;
    }
}