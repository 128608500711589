.ubaidGoogleTranslator .ubaidSetMargin{
    display: block;
    margin: 8px;
}
.DropMenuDisplay{
    display: none !important;
}
.navbarNotiicationNumber{
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  height: 20px;
    display: flex;
    padding: 0 6px;
    z-index: 1;
    position: absolute;
    flex-wrap: wrap;
    font-size: 0.75rem;
    min-width: 20px;
    box-sizing: border-box;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
    align-content: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    color: #fff;
    background-color: rgb(220, 0, 78)
}
.ubaidDropDownMenu{
    position: absolute;
    background: #fff;
    margin: 0;
    right: 0;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.22);
    padding: 8px 0;
    margin-top: 10px;
}
.ubaidDropDownMenu a{
    font-weight: 300 !important;
    padding: 4px 12px !important;
}
.ubaidProfileIcon{
    cursor: pointer;
    margin-left: 12px !important;
    margin-right: 16px !important;
    min-width: 60px;
}
.ubaidProfileIconIMG{
    width: 36px;
    height: 36px;
    background-size: cover;
    border-radius: 18px;
    display: inline-block;
    margin: 8px 5px 0 0
}
.triangle{
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #000;
}
.ubaidNav{
    margin: auto;
    right: 0;
    min-height: 70px;
    z-index: 777 !important;
    justify-content: flex-end !important;
}

.ubaidNav a.active{
    color: #d90429 !important;
}

.navbar_icon_container{
    position: relative;
    padding-top: 1vh;
}
.navbar_notification_bar_container{
    position: relative;
    margin-top: -0.5vh;
    font-weight: 500;

}
.notif_icon{
    opacity: 1;
    padding-top: -15px !important;
}

 .notif_icon:hover > div{
    opacity: 1;
    visibility: visible;
}

.ubaidNav .leftNavExpOppToo{
    display: none;
}
.UBaidSubHeader .rightContent a{
    display: block;
    text-align: center;
}
.UBaidSubHeader .rightContent a button{
    display: inline !important;

}

.credits-button{
    background: linear-gradient(90deg, #6902B9 0%, #1C71A1 100%);
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    color: white !important;
}

.credits-button:hover{
    background: linear-gradient(90deg, #6902B9 0%, #1C71A1 100%);
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    color: white !important;
}

@media (max-width:1600px) {
    .ubaidNav{
        padding-right: 10px !important;
    }

}

@media (max-width: 767px){
    .ubaidNav .leftNavExpOppToo{
        display: block;
    }
    .ubaidNav .__sidebar__wrapper{
        display: block;
    }
    .UBaidSubHeader, .subHeader{
        display: block;
        text-align: center;
        padding-bottom: 16px;
    }
    .UBaidSubHeader .leftContent{
        width: 100%;
    }

}
