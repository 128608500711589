
.__explore-page{
  padding-left: 0px;
  width: 100%;
  flex-grow: 1;
  .MuiCard-root.added {
    background-color: #cdcdcd;
    .exploreCardTags {
      background-color: #cdcdcd;
    }
  }
}