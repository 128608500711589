.olympusnav {
    background: #3f4257;
    padding-left: 70px;
    display: flex;
    height: 70px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.olympusnav input{
    border: none;
    box-shadow: none !important;
    background: transparent;
    box-sizing: border-box;
    font-size: 12px;
    color: #9a9fbf;
    fill: 100;
    width: 100%;
}
.olymSearch input::placeholder{
    color: #9a9fbf;
}
.olymSearch{
    display: flex;
    background: #494C62;
    width: 500px;
    padding: 0 14px;
    height: 70px;
}
.olympusnav svg{
    width: 24px;
    height: 24px;
    margin: auto;
    fill: #9a9fbf;
}
@media only screen and (max-width:768px){
    .olympusnav, .olymSearch{
        height: 50px;
        width: 100%;
    }
    .olympusnav{
        padding: 0 50px;
        position: fixed;
        top: 0;
    }
}