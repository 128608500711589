.pitchTestimonials{
    padding: 28px 0 28px 0;
    font-family: 'Trebuchet MS', sans-serif;
    background: rgba(255,255,255,1);
    background-image: url(//d9hhrg4mnvzow.cloudfront.net/pitchbox.startupfuel.com/r97b11-pattern-10-_0000000go0ew01k000001.png);
    background-repeat: repeat-x;
    background-position: center top;
}
.pitchTestimonials h1{
    font-size: 36px;
    font-family: 'Ubuntu';
    color: #ff6c00;
    margin: 0;
}
.pitchTestimonials i{
    font-size: 20px;
    line-height: 40px;
    display: block;
    margin: 24px auto;
    width: 99%;
    color: #30393A;
    width: 919px;
}
.pitchTestimonials hr{
    width: 42px;
    border: 3.5px solid #ff6c00;

}
.pitchTestimonials img{
    border-radius: 100px;
    margin: 4px 0 12px 0;
}
.pitchTestimonials h5{
    margin: 0 0 -6px 0 !important;
    line-height: normal;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}
.pitchTestimonials span{
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    margin: 0 !important;
}
.pitchFooter{
    background-color: #ff6c00;
    color: #fff;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    padding: 15px;
}
@media only screen and (max-width: 920px){
    .pitchTestimonials{
        padding: 24px 0;
    }
    .pitchTestimonials h1{
        font-size: 30px;
        margin: 0;
    }
    .pitchTestimonials i{
        font-size: 16px;
        width: 734px;
        line-height: 36px;
        margin-top: 16px;
    }
    .pitchTestimonials .pitchBoxContainer{
        width: 100%;
    }
    .pitchTestimonials img{
        width: 80px;
    }
    .pitchTestimonials h5{
        font-size: 13px;
        font-weight: bold;
        color: #30393A;
    }
    .pitchTestimonials span{
        font-size: 10px;
        color: #30393A;
    }
    .pitchFooter{
        font-size: 9.7px;
        padding: 12px 0;
    }
}
@media only screen and (max-width: 600px){
    .pitchTestimonials i{
        width: 290px;
        font-size: 13px;
        line-height: 27px;
    }
    .pitchTestimonials h1{
        font-size: 27px !important;
    }
    .pitchTestimonials img{
        width: auto;
    }
    .pitchTestimonials h5{
        font-size: 16px;
        margin-top: 6px !important;
    }
    .pitchTestimonials span{
        font-size: 12px;
    }
    .pitchFooter{
        font-size: 12px;
        padding: 15px;
    }
}