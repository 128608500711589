.signin_container {
  height: 100vh;
  display: flex;
  background-color: white;
  overflow: hidden;

  @media (max-width: 1100px) {
    overflow-y: auto;
    height: auto;
    min-height: 100vh;
  }

  .signin_cover {
    width: 50%;
    height: 100vh;
    position: sticky;
    top: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: none;
    }

    @media (max-width: 1100px) {
      display: none;
    }
  }

  .main_signin {
    max-width: 90%;
    padding-left: 0vh;
    padding-top: 0vh;
    margin: auto;
    overflow-y: auto;
    height: 100vh;

    @media (max-width: 1100px) {
      height: auto;
      overflow: visible;
    }

    .side_signin_form {
      max-width: 100%;

      img {
        width: 50vh;
        padding-bottom: 5vh;

        @media (max-width: 1100px) {
          width: 300px;
          margin: 0;
        }
      }
    }
    .signin_form {
      max-width: 100% !important;
    }
    .signup_button {
      width: 50vh;
      background-color: red !important;
      margin: 0 !important;
      font-size: 15px;

      @media (max-width: 1100px) {
        width: 300px;
        margin: 0;
      }
    }
  }
}
