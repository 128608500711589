.olympus-inputField input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: none;
  background: transparent;
  padding: 23px 16px 10px 14px;
  box-shadow: none !important;
  background: #fff;
}
.olympus-inputField {
  margin: 24px 0;
  border-radius: 3px;
  border: 1px solid #e6ecf5;
  display: flex;
}
.olympus-inputField label {
  position: absolute;
  padding: 15px 16px 12px 18px;
  font-size: 14px;
  transition: linear 0.15s all;
  cursor: text;
  color: #888DA8;
  font-weight: 400;
}
.olympus-inputField .olympus-changelabel {
  padding-top: 6px;
  padding-left: 12px;
  font-size: 11px;
}
.olympus-inputField i {
  font-size: 15px;
  margin: auto 14px auto auto;
  cursor: pointer;
  color: #888DA8;
}
@media only screen and (max-width: 1024px){
  .olympus-inputField input{
    padding: 20px 16px 8px 14px;
  }
  .olympusLogin button{
    padding: 12px;
  }
  .olympus-inputField{
    margin: 18px 0;
  }
  .OlympusLoginTitle{
    padding: 20px 24px;
  }
}