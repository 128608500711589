.navigationbar {
        width: 100%;
        height: 4.2rem;
        background-color: rgb(33, 25, 58);
        position: fixed;
        top: 0;
        left: 0;
        z-index : 100;
        margin-bottom : 2%;
        opacity: 0.95;


    &__navigation {
        margin-bottom: 2rem;
        height: 100%;
        display : flex;
        align-items: center;
        padding: 0 1rem;
    }


    &__logo {
   
        @media only screen and (min-width : 48em) {
            margin-left:3rem;
           
        }
        img {
            height: 2.4rem;
        }

        a {
            color : white;
            text-decoration: none;
            font-size: 1rem; 
            
        }
    }

    &__spacer {
        width: 100%;
        display : flex;
        align-items: center;
        justify-content: space-between;

        
        @media only screen and (min-width : 48em) {
     
        }
    }


    &__navigation-items {

        @media only screen and (max-width : 48em) {
            display: none;
        }

       
     

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display : flex;

        }

        li {
            padding : 0 0.5rem;
            margin: 1rem;

            a {
                color : white;
                text-decoration: none;
                font-size : 1.2rem;
                &:hover{
                    filter: brightness(65%);
                }
    
            }
    
          
        }

      

    }


    &__toggle-button {

        @media only screen and (min-width : 48em) {
            display: none;
        }
    }
    
}