.pitchHowItWorks{
    background: #3B4445;
    color: #fff;
    padding: 42px 0;
}
.pitchHowItWorks h3{
    margin-bottom: 32px;
    font-family: "Trebuchet MS", sans-serif;
}
.pitchHowItWorksDetails{
    display: flex;
    margin: 72px auto 10px auto;
    max-width:  920px;
    justify-content: space-between;
    width: 95%;
}
.pitchHowItWorks span{
    min-height: 120px;
    display: block;
}
.pitchHowItWorks h5{
    margin: 16px 0;
    font-size: 18px;
}
.pitchHowItWorksDetails div{
    width: 210px;
    font-family: 'Open Sans', sans-serif;
    line-height: 22px;
    font-size: 14px;
}
@media only screen and (max-width: 980px){
    .pitchHowItWorksDetails div{
        margin: 0 4px 16px 4px;
        font-size: 12px;
        line-height: 20px;
    }
}
@media only screen and (max-width: 600px){
    .pitchHowItWorksDetails{
        display: block;   
    }
    .pitchHowItWorks{
        padding: 24px 0;
    }
    .pitchHowItWorks h3{
        margin: 0 0 24px 0;
    }
    .pitchHowItWorksDetails div{
        display: block;
        width: auto;
        width: 300px;
        margin: auto auto 32px auto
    }
    .pitchHowItWorksDetails{
        margin: 0px auto;
    }
    .pitchHowItWorksDetails img{
        width: 95px;
    }
    .pitchHowItWorks h5{
        margin: 0 0 6px 0;
    }
}