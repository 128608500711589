.report-flagging-popup {
  .__popup .__blur {
    z-index: 1 !important;
  }
}

.mobile-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.mobile-popup-content p {
  margin-bottom: 20px;
}

.mobile-popup-content button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.mobile-popup-content button:hover {
  background-color: #0056b3;
}

.__pb-page {
  padding-left: 0px;
  flex-grow: 1;
  width: 97.5%;
  position: relative;

  .delete-icon {
    font-size: 26px;
    margin: 0px;
    vertical-align: middle;
    color: #ff4136;
    border-radius: 4px;
    padding: 1px;
    margin-left: 4px !important;
    margin-top: -1px !important;
    cursor: pointer;
    background-color: #e0e0e0;
    &:hover {
      box-shadow:
        0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%),
        0px 1px 10px 0px rgb(0 0 0 / 12%);
      background-color: #d5d5d5;
    }
  }
  .__title {
    font-weight: 400;
    display: flex;
    align-items: center;
    .material-icons {
      margin-left: 0.6em;
    }
  }
  .__header {
    background: transparent;
    display: flex;
    width: 100%;
    position: relative;
  }

  .__search-field-wrapper {
    display: flex;
    padding-left: 1vh;
    width: 20em;

    .fas {
      font-size: 30px !important;
    }

    > div {
      margin-right: auto;
    }
    .__csv-download {
      margin-right: 0.6em;
      color: #fff;
      font-size: 25px;
    }
  }

  .__aux_icons {
    font-size: 20px !important;
    padding-left: 20px;
    color: #525f7f;
    align-items: center;
  }
  .__search-field {
    //min-width: 70vh;
    margin: 0 !important;
    align-items: center;
    cursor: text;
    color: #525f7f;
    display: block;
    position: relative;

    input {
      border-radius: 5px;
      font-size: 10px;
      background: #fff;
      font-weight: 300;
      animation: 0.1s SearchBlur forwards;
      padding: 6px 14px;
    }
    .material-icons {
      font-size: 18px;
      position: absolute;
      top: 51%;
      transform: translateY(-50%);
      left: 1em;
    }
  }

  __csv_wrapper {
    float: right;
  }

  __share_wrapper {
    float: right;
  }

  .deals-page-content {
    display: flex;

    .filter-sidebar {
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 0;
    }

    .table_header {
      color: #000;
    }

    .__score-column {
      align-items: center;

      .__score-column-score {
        font-size: 20px;
        font-weight: 600;
      }

      .gradient-text {
        /* Define the gradient colors */
        background: linear-gradient(to right, #0047ab, #ff334b);

        /* Makes the text transparent so the background is visible */
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;

        /* Optional: Additional styling to enhance the appearance */
        font-weight: bold;
        justify-content: center;
      }

      .gradient-score {
        width: 1.5em; /* Equal width and height to form a perfect circle */
        height: 1.5em;
        background: #fff;
        color: #000;
        border: 5px solid #5e17eb;
        padding: 1.3em;
        border-radius: 50%; /* Makes it a circle */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition:
          transform 0.3s ease,
          box-shadow 0.3s ease;
      }

      .startup-score {
        width: 1.5em;
        height: 1.5em;
        padding: 1.3em;
        border-radius: 50%; /* Still keeps the circular shape for now */
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        transition:
          transform 0.3s ease,
          box-shadow 0.3s ease;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      }

      .progress-circle {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: linear-gradient(#e66465, #0f2a83);
        text-align: center;
      }

      .number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2; /* Higher z-index to be above the hole */
        font-size: 15px; /* Adjust as needed */
      }

      .left-half-clipper {
        width: 100%;
        height: 100%;
        position: absolute;
        clip: rect(0, 100px, 100px, 50px);
        border-radius: 50%;
      }

      .first50-bar,
      .value-bar {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        position: absolute;
        clip: rect(0, 50px, 100px, 0);
        background-color: #c49733;
      }

      .value-bar {
        transform: rotate(0deg); /* Dynamic rotation based on value */
        transform-origin: center;
        transition: transform 0.5s linear;
      }

      /* Donut hole styling */
      .progress-circle:before {
        content: "";
        position: absolute;
        top: 25%; /* Adjust for the size of the donut hole */
        left: 25%;
        right: 25%;
        bottom: 25%;
        background: white; /* Color of your page background */
        border-radius: 50%;
        z-index: 1; /* Ensure this is between the number and the progress bars */
      }
    }

    .deals-column {
      width: 100%;

      .table-responsive {
        background: #fff !important;
      }

      ._table_row {
        color: #000;

        .td {
        }
      }
    }

    .__status-column {
      .__score-in-status {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .card-deal-page-main-wraper {
      min-width: 100%;
      width: 100%;
      margin-right: 0px;
      padding-right: 0px;
    }
  }

  .__submissionroom-tabs {
    display: flex;
    height: 100px;
    margin: 0 -0.8em;
    > div {
      max-width: calc(33.33% - 3.2em);
      flex: 1;
      display: flex;
      background: #fff;
      padding: 1.6em;
      margin: 0.8em;
      border-radius: 0.4em;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border: 4px solid transparent;
      overflow: hidden;
      color: #525f7f;
      .material-icons {
        padding: 0.6em;
        border-radius: 50%;
        color: #fff;
      }
      &:first-child {
        &.active {
          border-bottom-color: #f5365c;
        }
        .material-icons {
          background: linear-gradient(87deg, #f5365c, #f56036);
        }
      }
      &:nth-child(3) {
        &.active {
          border-bottom-color: #fbb140;
        }
        .material-icons {
          background: linear-gradient(87deg, #fb6340, #fbb140);
        }
      }
      &:nth-child(2) {
        &.active {
          border-bottom-color: #2dce89;
        }
        .material-icons {
          background: linear-gradient(87deg, #2dce89, #2dcecc);
        }
      }
    }
  }

  .dealstages {
    border: 1px solid #d5d5d5;
    border-radius: 3px;
    background-color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: grey;
    margin: 0 2px 0 0px;
    padding: 7px 12px;
  }

  .kanban-board {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    overflow-x: auto;
  }

  .kanban-column {
    width: 15%;
    padding: 10px;
    background: #f8f9fa;
    border-radius: 5px;
    flex-shrink: 0; /* Prevent columns from shrinking */
    min-width: 300px; /* Set a fixed width for columns */
    min-height: 40vw;
  }

  .profile-card-wrapper {
    margin-bottom: 8px;
    background: white;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }

  .dealstages:hover {
    border: 1px solid #d5d5d5;
    background-color: #c49733;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: white;
  }

  .dealstages:active {
    border: 1px solid #d5d5d5;
    background-color: #c49733;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: white;
  }

  .status_bar {
    width: 95%;
    margin: 0em 2.5%;
    display: block;
  }

  @media (max-width: 500px) {
    .__submissionroom-tabs {
      display: block;
      margin: 0;
      > div {
        margin: 1.6em 0;
        max-width: initial;
      }
    }
    .__search-field-wrapper {
      > div:first-child {
        flex: 1;
      }
      .__search-field {
        margin-right: 0.8em !important;
        input {
          animation: initial;
          width: 100%;
        }
        + .material-icons {
          position: relative;
          border: 1px solid red;
        }
      }
    }

    .deals-page-content {
      padding-top: 3vh;
      display: block;
      width: 100%;

      .filter-sidebar {
        top: 0;
      }

      .deals-column {
        min-width: 100%;
        width: 100%;
        margin-right: 0px;
        padding-right: 0px;

        ._table_row {
          height: 200px !important;
        }
      }

      .card-deal-page-main-wraper {
        min-width: 100%;
        width: 100%;
        margin-right: 0px;
        padding-right: 0px;
      }
    }
    .table-sort-filter {
      margin-bottom: 0;
    }

    .filter-deal-page {
      background: #fff;
      text-align: center;
      border-radius: 6px;
      height: fit-content;
      margin: auto;
      right: 0;
    }
  }

  .__table-header {
    height: 3vh;
    background-color: #fff !important;
  }

  .table-card {
    th {
      color: #020202 !important;
    }
    margin: 0 0px 32px 0px;

    .exploreCardTags {
      padding: 0;
      display: inherit;
      background-color: none;
      border-bottom: none;
      height: fit-content !important;
    }
    .MuiButton-containedPrimary {
      margin-left: 5px;
    }
    .avatar-sm {
      margin-right: 5px;
      height: 50px;
      width: 50px;
    }
    a:hover {
      color: #797c7e !important;
    }
  }

  .btn-table-card {
    .btn {
      border-width: 1px !important;
      box-shadow: none !important;
    }
    .active {
      font-weight: bold !important;
      border-width: 2px !important;
    }
  }
  .table-card {
    .__select-column {
      max-width: 5em;
      justify-content: left;
      padding: 0;
    }

    .company-name-column {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: pretty;
      max-width: 300px;

      .allTags {
        padding-top: 2vh;
        text-align: left;
        margin: auto;
      }

      p {
        font-size: 14px;
      }
    }
    .__company-descrip-column {
      text-wrap: pretty;
      max-width: 400px;

      p {
        font-size: 13px;
      }
    }
    .__tags-column {
      max-width: 30vh;
      white-space: pre-line;

      .allTags {
        max-width: 30vh;
        white-space: pre-line;
      }
    }

    .__model-column {
      max-width: 90px;
      white-space: pre-line;
    }
    .__status-column {
      width: auto;
      .__score-in-status {
        display: none;
      }
      .__status-dropdown {
        text-align: left;
        font-size: small;
        font-weight: 900;
        border-radius: 6px;
        padding: 0.5em 1em;

        .__status-dropdown:hover {
          background-color: rgba(#fff, 0.06);
        }

        h6 {
          font-size: 14px;
          color: #e66465;
        }
      }
    }
  }
}

.__pb-page .add-company-button {
  margin-left: 12px;
  margin-top: 0px;
  margin-right: 0;
  margin-left: auto;
}
.statuses-large,
.statuses-small {
}
.__table-card-select {
  border-color: #000 !important;
}

@media (max-width: 1024px) {
  .__pb-page {
    padding-left: 0px;
    .table-card {
      margin: 0 16px 32px 16px;
      .table td,
      .table th {
        padding: 5px;
      }
      .__tags-column {
        display: none;
      }
    }
    .btn-table-card {
      margin-bottom: 16px;
    }

    .statuses-large {
      display: none;
    }
    .statuses-small {
      padding-left: 16px;
      margin-bottom: 16px;
      .btn {
        padding: 0.4rem 1.14rem;
      }
    }
    .table-sort-filter {
      display: none;
    }
  }

  .__pb-page .MuiTypography-body1 {
    padding: 8px !important;
  }

  .deals-page-content {
    .__company-descrip-column {
      display: none;
    }
    .__score-column {
      display: none;
    }
  }
}

@media (max-width: 700px) {
  .__pb-page {
    .table-card {
      .__actions-column {
        .MuiButton-root,
        .delete-icon {
          display: block;
          margin-bottom: 5px !important;
          margin-right: 0 !important;
          margin-left: auto !important;
          width: 80px;
          text-align: center;
        }
      }
      .__score-column {
        display: none;
      }
      .__status-column {
        text-align: left !important;
        display: none;
        .__score-in-status {
          display: none;
          margin-bottom: 5px;
        }
      }
    }
    .__search-field-wrapper {
      padding-left: 0;
    }
    .add-company-button {
      margin-left: 0;
    }
    .__title {
      display: none;
    }

    .__csv-download {
      display: none;
    }

    .__aux_icons {
      display: none;
    }
  }
}

@media (min-width: 991px) {
  .__pb-page .statuses-small {
    display: none;
  }
}

@keyframes SearchBlur {
  0% {
    width: 360px;
  }
  60% {
    width: 200px;
  }
  100% {
    width: 270px;
  }
}
@keyframes SearchFocus {
  0% {
    width: 270px;
  }
  40% {
    width: 450px;
  }
  100% {
    width: 360px;
  }
}
span.MuiIconButton-label svg {
  font-size: 15px;
}

.react-responsive-modal-modal {
  width: 30%;
  border-radius: 10px;
}
h2.change-status-heading {
  font-size: 20px;
  width: 100%;
  margin-bottom: 25px;
}
.change-status-select {
  width: 100%;
}
.button-change-status {
  display: flex;
  justify-content: end;
  margin-top: 22px;
}
.button-status {
  margin-right: 10px !important;
}
img.rounded.img-tearsheet-logo {
  width: 96px;
  height: 96px;
  object-fit: cover;
}
.__review-card .__main {
  z-index: 888 !important;
}
