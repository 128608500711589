body {
    font-family: 'Poppins', sans-serif;
}

.container {
    padding: 10px;
}

.header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 20px;
}

.tearsheet-title {
    color: white;
}

.button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer; /* Add a pointer cursor for enabled buttons */
}

.button:disabled {
    background-color: #cccccc; /* Change this to the desired disabled background color */
    color: #666666; /* Change this to the desired disabled text color */
    cursor: not-allowed; /* Change the cursor to indicate the button is disabled */
}
.button-secondary {
    background-color: #000;
}

.company-overview {
    background-color: black;
    color: white;
    padding: 20px;
}

.company-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.company-details {
    display: flex;
    flex-direction: column;
}

.company-details p {
    color: white;
    font-weight: bold;
    text-align: start;
    padding: 2px;
}

.tearsheet-section {
    border: solid white 2px;
    padding: 20px;
    color: white;
    border-radius: 15px;
}

.business-overview {
    border: solid #ebebeb 2px;
    border-radius: 15px;
    padding: 10px;
}

.key-highlights {
    margin-top: 10px;
    padding-left: 10px;
}

.business-overview h2,
.key-highlights h2 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;
    margin: 0;
    color: #c49733
}

.key-highlights ul {
padding-left: 20px;
}


.key-highlights li {
    margin-bottom: 10px;
}

.error {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px !important;
    height: 50px !important;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: #000 #0000;
    animation: l1 1s infinite;
}
@keyframes l1 {to{transform: rotate(.5turn)}}