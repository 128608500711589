.Ubaid_Company_Rank_Input_Con{
    display: flex;
    width: 520px;
}
.Ubaid_Company_Rank_Input_Child{
    min-width: 120px !important;
}
.marginLeftRight{
    margin: 0 3px !important;
}
.Ubaid_Company_Rank_Input_Bottom{
    max-width: 520px;
    margin-top: 16px !important;
    margin-bottom: 10px !important;
}
.Ubaid_Company_Rank_Input{
    width: 100%;
}
.Ubaid_Company_Rank_Input .MuiSelect-selectMenu{
    word-wrap:break-word !important;
    overflow: unset !important;
    white-space: unset !important;
    text-overflow: unset !important;
}
.Ubaid_Company_Rank_Input .MuiChip-root{
    display: inline-block !important;
    padding: 6px 0 !important;
    margin-top: 1px;
}
@media only screen and (max-width:1100px){
    .Ubaid_Company_Rank_Input_Con{
        display: block;
        width: 100%;
    }
    .Ubaid_Company_Rank_Input_Child{
        width: 100%;
        margin-top: 16px !important;
    }
    .Ubaid_Company_Rank_Input_Bottom{
        max-width: 100%;
    }
}