// Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600");
$font-base: 'Open Sans', Arial, sans-serif;

$blue-1: #009fc4;
$blue-2: #0c7ead;

*, *:before, *:after {
	box-sizing: border-box;
}

.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
	padding:.84rem 1.5rem ;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #f5f5f5;
	max-width: 100%;
}

body {
	background: #eee;
	font-family: $font-base;
	font-size: 14px;
	line-height: 150%;
}

.truncate {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.disclaimer {
	margin: 0 auto 50px;;
	text-align: center;
	width: 400px;
}

// Buttons
.btn {
	border: none;
	cursor: pointer;
	display: block;
	font-family: $font-base;
	font-size: 16px;
	outline: none;
	padding: 15px;
	transition: all .1s linear;
}

// Inbox wrapper
$app-height: 500px;
$sidebar-width: 200px;

.wrapper {
	box-shadow: 0 4px 20px rgba(51, 51, 51, .2);
	margin: 50px auto;
	overflow: auto;
	width: 1024px;

	.inbox-container {
		float: left;

		width: calc(100% - #{$sidebar-width});
	}
}

#sidebar {
	background: #34393d;
	float: left;
	height: $app-height;
	width: $sidebar-width;

	.sidebar__inboxes {
		margin-top: 50px;

		.item-count {
			background: #34393d;
			border-radius: 5px;
			float: right;
			padding: 2px 8px;
			margin: -2px -8px;
		}

		li a {
			color: #fff;
			cursor: pointer;
			display: block;
			margin-bottom: 10px;
			padding: 15px;
			text-decoration: none;
			transition: background .1s linear;
			width: 100%;

			&:hover {
				background: #404549;
			}
		}

		.fa {
			margin-right: 10px;
		}
	}

	.btn.compose {
		color: #fff;
		padding: 30px 15px;
		text-align: center;
		text-decoration: none;
		transition: all .1s linear;
		width: 100%;
		background-image:(linear-gradient(bottom right, $blue-1, $blue-2));

		&:hover {
			background-size: 150%;

			.fa {
				-webkit-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
			}
		}

		.fa {
			margin-left: 10px;
			transition: all .1s linear;
		}
	}
}

// Conversation list
.conversation-list {
	background: #f5f5f5;
	float: left;
	height: calc(100%);
	overflow-y: auto;
	width: 30%;

	&.empty {
		color: #aaa;
		padding-top: 200px;
		text-align: center;
	}

	.conversation-item {
		background: #fff;
		border-bottom: 1px solid #eee;
		cursor: pointer;
		padding: 10px 15px;
		position: relative;

		&.selected {
			color: $blue-1;
		}

		&__subject {
			margin-bottom: 8px;
			text-align: left;
		}

		&__details {
			font-size: 12px;
			opacity: .5;
			text-transform: uppercase;
		}

		&__unread-dot {
			height: 100%;
			right: 10px;
			position: absolute;
			top: 10px;

			&[data-read="false"]:before {
				background: $blue-1;
				border-radius: 50%;
				content: '';
				display: block;
				height: 6px;
				width: 6px;
			}
		}

		&__time {
			float: right;
			text-align: right;
			width: 40%;
		}

		&__from.truncate {
			width: 60%;
			text-align: left;
		}

		&:hover:not(.selected) {
			background: #fafafa;
		}
	}
}

// Conversation content
.conversation-content {
	background: #fff;
	border-left: 1px solid #ddd;
	float: left;
	height: $app-height;
	position: relative;
	width: 70%;

	&__header {
		background: #f5f5f5;
		border-bottom: 1px solid #eee;
		padding: 0px 15px;

	}

	&__subject {
		font-size: 20px;
		margin: 10px 0;
		text-align: left;
	}

	&__details {
		font-size: 12px;
		opacity: .5;
		text-transform: uppercase;
	}

	&__time {
		color: #878787;
		float: right;
	}

	&__from {
		color: #878787;
		text-align: left;
	}

	&__message {
		padding: 20px 15px 15px;
	}

	.delete-btn {
		cursor: pointer;
		margin: -5px;
		padding: 5px;
		position: absolute;
		right: 20px;
		top: 24px;
		transition: color .1s linear;

		&:hover {
			color: #E23E57;
		}
	}
}

// Footer
.footer {
	background: #f5f5f5;
	border-top: 1px solid #ddd;
	color: #999;
	clear: both;
	font-size: 14px;
	padding: 10px;
	text-align: center;
	width: 100%;
}


.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 65%;
  max-width: 867px;
  margin: 25px 10px;
  height: calc(100%);
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;

}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #fff;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  background: #ddd;
}
.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}
.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}

.msger-chat {
  background-color: #fcfcfe;
}
