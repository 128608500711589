.__application-sidebar{
    width: 25vh;
    bottom:0px;
    left: 0;
    height: 100%;
    z-index: 99;
    z-index: 99;
    position: fixed !important;
    background-color: #222227;
    border-right: 1px solid #e0e0e0;


    .__pitchbox-menu-header{
        position: fixed;
        top: 0vh;
        z-index: 999;
        left: 0;
        height: 70px;
        min-width: 25vh;
        display: flex;
        justify-content: center;
        align-items: center;
        
        

        h2{
            margin-bottom: 0;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
            font-weight: 1000;
            font-size: 2vh;
            background-image: linear-gradient(45deg,#eee, #fff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .sidebar_icon{ 
        color: #fff !important;
        width: .75em;
    }
    
    .__main{
        top:73.5px;
        position: sticky !important;
        text-align: center;
        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: #bdbdbd;
            width: 2em;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #9e9e9e;
        }
        .MuiToolTip-tooltip{
            width: 50em;
        }
        i{
            font-size: 0.5rem !important;
            display: none;
        }
        
    }
    a {
        display: flex;
        padding: .7em 1em;
        margin: 0px 0 !important;
        align-items: center;
        font-size: 12px;
        
        font-weight: 1200;
        color: #000 !important;
        border-left: 3px solid #222227;

        p {
            text-align: left;
            font-size: 12px;
            font-family: 'Work Sans', sans-serif;
            margin: 0;
            background: transparent;
            color: #fff;
        }

        &.active {
            border-left: 3px solid #C49733;
            background: rgba($color: #000, $alpha: 1);

            .sidebar_icon{ 
                
            }
        }

        &:hover{
            background: rgba($color: #000, $alpha: .5);
            color: #fff !important;
            border-left: 3px solid #C49733;
            
            i {color: #fff !important;}

            p {background: transparent}
        }
        
        >i{
            //margin-right: 0.6em;
            height: auto !important;
            padding: 0.5em 0em !important;
            font-size: 10px;
            color: #000;
        }
        &:first-child i {
            color: #000;
        }
        &:nth-child(2) i{
            color: #bdbdbd;
        }
        &:nth-child(3) i{
            color: #bdbdbd;
        }
        &:nth-child(4) i{
            color: #bdbdbd;
        }
        &:nth-child(5) i{
            color: #bdbdbd;
        }
        &:nth-child(6) i{
            color: #bdbdbd;
        }
    }
    .__brand-logo{
        max-width: 100%;
        display: block;
        margin-bottom: 2em;
    }
    .__drop-downlinks{
        //Show all dropdown links on PitchBox sidemenu items
        //max-height: 1px;
        //overflow: hidden;
        //transition: ease-in 0.4s all;
    }
    .__drop-downlinks-handler{
        padding: 0.2em 1.5em;
        border: none;
        outline: none;
        cursor: pointer;
        background: transparent;
        width: 100%;
        &:focus {
            margin-right: 1em !important;
            .dropdownUbaidIcon{
                transform: rotate(-180deg);
            }
            background: rgba($color: #020202, $alpha: 0.1);
            ~ .__drop-downlinks{
                max-height: 1000px;
            }
        }
    }
    .__border{
        border-top: 1px solid #e0e0e0;
    }

    .__icon-tray{
        padding-bottom: 20px;
        position: sticky !important;
    }

    .tooltiptext {
        top: -5px;
        left: 105%;
      }

    .__pitchbox-menu-footer{
        padding-left: 0 !important;
        position: fixed;
        bottom: 0;
        width: 25vh;
        text-align: left;
        font-size: 10px;

        .__drop-downlinks-handler
        {max-width: 20vh !important;}
        
        img{
            width: 25px;
            margin: auto;
        }

        .__border{
            width: 90%;
        }
    }

    @media (max-width: 991px) {
        display: none;
    }
}

@media (max-width: 991px) {
    .__application-room-main {
        padding-left: 0;
        padding-bottom: 100px;
        margin-top: 64px;
        .__pb_navbar {
            height: 7vh;

            .active{
                background: rgba($color: #C49733, $alpha: .7);
                color: #000 !important;;


            }
        }
        .arg-navbar-secondary .arg-navbar-secondary-name-logo .nav-link {
            padding: 12px 0 0 0 !important;
        }
        .__header {
            margin-top: 0px;
        }

    }
}
