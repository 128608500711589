.OlympusComunityReviews{
    text-align: center;
    background-image: url('../assets/bg-section3.png');
    background-size: cover;
}
.OlympusComunityReviews .MainBodyOlympus{
    display: block;
    padding: 126px 0 96px 70px;
}
.OlympusComunityReviews span{
    font-weight: bold;
    font-size: 10px;
}
.OlympusComunityReviews h2{
    font-size: 40px;
    margin: 24px 0;
}
.OlympusComunityReviews p{
    font-size: 14.3px;
    margin: 30px 0;
    color: #888da8;
}
.OlympusReviewCards{
    display: flex;
}
.OlympusReviewCard{
    background: #fff;
    width: 33%;
    display: flex;
    flex-direction: column;
    border: 1px solid #e6ecf5;
}
.OlympusCardHeader{
    border-radius: 5px 5px 0 0;
    background: url('../assets/landing-bg.jpg');
    padding: 16px;
    height: 90px;
    background-size: 200%;
}
.OlympusReviewCard .authorAvtar{
    border-radius: 100px;
    border: 4px solid #fff;
    width: 98px;
    height: 98px;
    background-repeat: no-repeat;
    margin: -45px auto 0 auto;
    background-size: cover;
}
.OlympusReviewCard h3{
    margin: 18px 0 12px 0;
    font-size: 29px;
}
.OlympusReviewCard i{
    margin: 0.5px;
    padding: 0;
    width: auto;
    font-size: 11.5px;
    color: #ffce08;
}
.OlympusReviewCard .ReviewOlympus{
    padding: 0 25px 25px 25px;
}
.OlympusReviewCard b{
    margin-top: auto;
}
.OlympusReviewCard .footerOlypusReview{
    margin-top: auto;
    line-height: 19px;
    padding: 0 0 16px 0;
}
.OlympusReviewCard .footerOlypusReview b{
    display: block;
    color: #2F2C2C;
}
@media only screen and (max-width: 1150px){
    .OlympusReviewCard h3{
        font-size: 25px;
        padding: 0 25px;
    }
    .OlympusComunityReviews p{
        font-size: 12.25px;
    }
    .OlympusReviewCard .ReviewOlympus{
        padding-bottom: 0px;
    }
    .OlympusComunityReviews h2{
        font-size: 35px;
    }
}
@media only screen and (max-width: 768px){
    .OlympusComunityReviews .MainBodyOlympus{
        padding: 48px 0;
    }
    .OlympusComunityReviews h2{
        font-size: 32px;
    }
}
@media only screen and (max-width: 760px){
    .OlympusComunityReviews .MainBodyOlympus{
        padding: 48px 0 2px 0;
    }
    .OlympusReviewCards{
        display: block;
    }
    .OlympusReviewCard{
        width: auto;
        margin: 32px 0 !important;
    }
}