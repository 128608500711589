@media only screen and (max-width: 1150px){
    .ubaidHeadingService{
        font-size: 20px !important;
    }
}
@media only screen and (max-width: 769px){
    .ubaidServiceCard p{
        position: relative;
        transition: linear 0.3s all;
        animation: 4s infinite linear rotateBox;
    }
    .Ubaid_Service_Card_Heading{
        color: white;
        padding: 8px;
        font-size: 24px;
        padding-bottom: 0;
    }
    .Ubaid_Service_Card_Heading span{
        padding-top: 16px;
    }
    .ubaidServiceCard{
        background: rgba(255, 255, 255, 0.8);
        padding: 20px;
        margin: 16px 24px 23px 24px;
        width: 90%;
        min-height: 155px;
    }
    .ubaidServiceCard h3{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .ubaidServiceCard p{
        line-height: 20px;
        margin-bottom: 0;
    }
    .ubaidServiceCard div{
        padding: 0;
    }
}
@media only screen and (max-width: 426px){
    .ubaidServiceCard{
        width: 99%;
        min-height: auto;
    }
}