.MainNavOlympus {
  font-weight: bold;
  color: #3f4257;
  margin: auto;
}
.MainNavOlympusChild {
  position: fixed;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
  z-index: 1;
}
.OlympusNavMain {
  padding: 5.5px 0px 5.5px 70px;
  display: flex;
  width: 92.6%;
  margin: auto;
  max-width: 1600px;
}
.OlympusNavMain img {
  width: 51px;
}
.OlympusLogo p {
  margin: auto 12px;
  font-size: 14px;
  line-height: normal;
}
.OlympusLogo {
  display: flex;
  text-transform: uppercase;
  margin-right: auto;
}
.OlympusLogo div {
  font-size: 7px;
  font-weight: 500;
  margin-top: 1px;
}
.OlympicsNavLink {
  margin: auto 6px;
  font-size: 13px;
  position: relative;
  padding: 20px 24px;
}
.OlympicsNavLink a {
  color: inherit;
}
.Olympus button, .OlympusNavBtn {
  color: #fff;
  background: #ff5e3a;
  border-radius: 4px;
  padding: 11.4px 0;
  margin: auto 0;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  min-width: 167px;
  border: 2px solid #ff5e3a;
  text-align: center;
}
.dropIconOlympus {
  display: inline-block;
  border-left: 2.7px solid transparent;
  border-right: 2.7px solid transparent;
  border-top: 3.4px solid rgba(63, 66, 87, 0.9);
  margin-left: 4px;
}
.olympusLinkCard {
  opacity: 0;
  display: none;
  top: 200px;
  position: absolute;
  left: 0;
  padding-top: 20px;
}
.OlympicsNavLink:hover .olympusLinkCard {
  display: block;
  animation: forwards 0.3s linear olympusLinkCard;
}
.olympusLinkCardDrop {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
  padding: 0 16px;
  min-width: 220px;
}
.olympusLinkCard a {
  color: #9a9fbf;
  display: block;
  position: relative;
  padding: 10px 0;
  transition: linear 0.3s all;
}
.olympusLinkCard a:hover {
  color: #ff5e3a !important;
  left: 6px;
}
.OlympicsNavLinkInner {
  cursor: pointer;
}
.OlympicsNavLinkInner a {
  color: inherit !important;
}
.OlypusAllLinksNav {
  display: flex;
}
@keyframes olympusLinkCard {
  0% {
    opacity: 0;
    top: 200px;
  }
  100% {
    opacity: 1;
    top: 43px;
  }
}
.OlympusLogo svg {
  display: none;
}
@media only screen and (max-width:1400px){
  .OlympusNavMain{
      width: 85%;
  }
}
@media only screen and (max-width: 1150px) {
  .OlympicsNavLink {
    padding: 20px 13px;
    margin: auto 0;
  }
  .OlympusNavBtn {
    padding: 11px 30px;
  }
  .OlympusLogo p {
    font-size: 12.25px;
  }
}
@media only screen and (max-width: 1024px) {
  .OlympusNavMain {
    width: 90%;
  }
}
@media only screen and (max-width: 1000px) {
  .OlympusNavMain {
    display: block;
  }
  .OlympusLogo svg {
    display: block;
    width: 20px;
    margin: auto 0 auto auto;
    fill: #9a9fbf;
    cursor: pointer;
  }
  .OlympusLogo svg:hover {
    fill: #ff5e3a;
  }
  .OlypusAllLinksNavMain {
    position: absolute;
    top: 68px;
    width: 100%;
    left: 0;
    padding-left: 70px;
    display: none;
  }
  .OlypusAllLinksNav {
    display: block;
    background: #fff;
    box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
    width: 90%;
    margin: auto;
  }
  .OlympicsNavLinkInner {
    display: flex;
    border-bottom: solid 1px #f0f0f0;
    padding: 12px 20px;
  }
  .OlympicsNavLink {
    padding: 0;
  }
  .dropIconOlympus {
    margin: auto 0 auto auto;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(63, 66, 87, 0.9);
  }
  .olympusLinkCard{
      position: static;
      opacity: 1;
      padding: 0;
      display: none !important;
  }
  .OlympicsNavLink:hover .olympusLinkCard{
      animation: none;
  }
  .OlypusAllLinksNav{
      max-height: 475px;
      overflow-y: auto;
  }
  .OlympicsNavLinkInner{
      display: flex;
  }
  .OlympusDropShow{
      display: block !important;
  }
  .olympusLinkCardDrop a{
    border-bottom: 1px solid #f0f0f0;
    padding: 12px 32px;
  }
  .olympusLinkCardDrop{
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .OlympusNavMain {
    padding: 0;
    margin: 50px auto auto auto;
    width: 95%;
  }
  .OlypusAllLinksNav{
    width: 97%;
  }
  .OlympusLogo img {
    width: 42px;
  }
  .MainNavOlympusChild {
    position: static;
  }
  .OlypusAllLinksNavMain{
    padding: 0;
    top: 48px;
    z-index: 1;
  }
  .Olympus button, .OlympusNavBtn{
    min-width: 150px;
    font-size: 13px;
  }
}
@media only screen and (max-width: 600px){
  .OlypusAllLinksNav{
    width: 96%;
  }
}
@media only screen and (max-width: 375px){
  .Olympus button, .OlympusNavBtn{
    min-width: 130px;
    font-size: 12px;
  }
}