.__ubaid__application__opp{
    position: relative;
    @media (min-width: 601px) {
        .opp-card-image-wrapper{
            img{
                height: 140px;
                overflow: hidden;
                width: 140px;
                border-radius: 160px;
                border: 2px solid #fff;
                box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08), 0 3px 14px 2px rgba(0, 0, 0, 0.06), 0 5px 5px -3px rgba(0, 0, 0, 0.06);
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }
}

@media (max-width: 600px){
    .__ubaid__application__opp{
        display: block !important;
        text-align: center;
        .opp-card-image-wrapper{
            overflow: hidden;
            position: relative;
            div{
                padding-top: 54.25%;
            }
            img{
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
            }
        }
        .opp-card-content{
            margin-left: 0 !important;
        }
        h4{
            margin: 0;
            font-size: 18px;
        }
        h4+p{
            margin: 0;
        padding: 0;
        }
        h4+p i{
            padding: 0;
            margin-left: -50px;
        }
    }
}